@media (min-width: 992px) {
    .explorer-menu-bounds {
        height: 125px;
    }
    .segment-header-visual {
        //border-bottom-right-radius: 50px;
        //border-bottom-left-radius: 50px;
    }

    .segment-header-visual {
        @include aspect-ratio(16, 2);
    }

    .podcast-section-title {
        font-size: 2.6rem;
    }
    .explorer-scrollable {
        .explorer-sets-pattern {
            height: 2.2rem;
        }
    }
    .podcast-host {
        width: 25%;
    }
    .segment-header-visual {

    }
}
