@media (min-width: 640px) {
    .segment-header {
        max-width: 576px;
    }
    .segment-content-container {
        max-width: 480px;
    }
    .explorer-sectionals {
        max-width: 576px;
        margin: 0 auto;
    }

    .character-module {
        width: 30%;
    }

    .character-image-column {
        height: 380px;
    }

    .build-creator-content {
        max-width: 576px;
    }
    .build-meta {
        width: 70%;
        flex: 1 1 auto;
        margin-bottom: 10px;
    }

    .build-class-chooser {
        width: 100%;
    }

    .class-icon {
        width: 15%;
    }
    .class-chooser {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 15px !important;
    }
    .skill-icon {
        width: 12.5%;
    }

    .armory-item {
        &.explorer-armor {
            width: 55px;
            margin-bottom: 5px;
            margin-right: 5px;

            &.upper {
                width: 70px;
            }

            &.footgear {
                width: 70px;
            }
        }
    }
    .explorer-figure {
        height: 57vh;
    }
    .explorer-label-default {
        .text-label {
            &.sm {
                &.bonus {
                    width: 40%;
                }
            }
        }
    }
    .field-label {
        &.segment-divider {
            .label-text {
                font-size: 1rem;
            }
        }
    }
}
