@charset "UTF-8";
/**
 * Based on
 *
 *  - reset.css 2.0 by Eric Meyer
      (public domain)
 *    http://meyerweb.com/eric/tools/css/reset/
 *
 *  - normalize.css 8.0.1 by Nicolas Gallagher and Jonathan Neal
 *    (licensed under MIT)
 *    https://github.com/necolas/normalize.css
 *
 *  - Reboot from Bootstrap 4.5.3
 *    (licensed under MIT)
 *    https://github.com/twbs/bootstrap
 */
/**
 * IE10+ doesn't honor `<meta name="viewport">` in some cases
 */
@import '~overlayscrollbars/css/OverlayScrollbars.css';
@import '~mediaelement/build/mediaelementplayer.css';
@import '~@staaky/tipped/dist/css/tipped.css';
@-ms-viewport {
  width: device-width;
}
/**
 * general reset
 */
html, body, div, span, applet, object, iframe,
h1,
.h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small,
.small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, .mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/**
 * HTML5 display-role reset for older browsers
 */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main, summary {
  display: block;
}

/**
 * inherit box model for all elements
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * html root rules
 * 1. set border-box for inheritance
 * 2. avoid 300ms click delay on touch devices that support the `touch-action`
 *    CSS property
 * 3. Prevent adjustments of font size after orientation changes in IE, on
 *    Windows Phone and iOS.
 * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
 *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
 * 5. Change the default tap highlight to be completely transparent in iOS.
 */
html {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  touch-action: manipulation;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 4 */
  -ms-overflow-style: scrollbar;
  /* 5 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/**
 * body rules
 * 1. reset line-height to 1
 * 2. set base font-family to sans-serif
 * 3. Set an explicit initial text-align value so that we can later use the
 *    `inherit` value on things like `<th>` elements.
 */
body {
  /* 1 */
  line-height: 1;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  text-align: left;
}

/**
 * Future-proof rule: in browsers that support :focus-visible, suppress the focus outline
 * on elements that programmatically receive focus but wouldn't normally show a visible
 * focus outline. In general, this would mean that the outline is only applied if the
 * interaction that led to the element receiving programmatic focus was a keyboard interaction,
 * or the browser has somehow determined that the user is primarily a keyboard user and/or
 * wants focus outlines to always be presented.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
 * and https://developer.paciellogroup.com/blog/2018/03/focus-visible-and-backwards-compatibility/
 */
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

/**
 * Lists
 */
ol, ul {
  list-style: none;
}

/**
 * Quotes
 */
blockquote, q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

/**
 * Tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  caption-side: bottom;
}

/**
 * Table Headers
 * 1. Matches default `<td>` alignment by inheriting from the `<body>`, or the
 *    closest parent with a set `text-align`.
 * 2. Fix alignment for Safari
 */
th {
  /* 1 */
  text-align: inherit;
  /* 2 */
  text-align: -webkit-match-parent;
}

/**
 * Horizontal Lines
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  overflow: visible;
}

/**
 * Preformatted Text
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Don't allow content to break outside
 * 3. We have @viewport set which causes scrollbars to overlap content in IE11
 *    and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to
 *    counteract.
 */
pre,
code,
kbd,
samp {
  /* 1 */
  font-family: monospace, monospace;
}

pre {
  /* 2 */
  overflow: auto;
  /* 3 */
  -ms-overflow-style: scrollbar;
}

/**
 * Links
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  /* 1 */
  background-color: transparent;
  /* 2 */
  -webkit-text-decoration-skip: objects;
}

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 * 3. Add explicit cursor to indicate changed behavior.
 * 4. Prevent the text-decoration to be skipped.
 */
abbr[title] {
  /* 1 */
  border-bottom: 0;
  /* 2 */
  text-decoration: underline;
  text-decoration: underline dotted;
  /* 3 */
  cursor: help;
  /* 4 */
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * Add the correct font size in all browsers.
 */
small, .small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/**
 * Prevent `em` being affected from global reset
 */
em {
  font-style: italic;
}

/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/**
 * Hide SVG overflow in IE
 */
svg:not(:root) {
  overflow: hidden;
}

/**
 * Remove the default `border-radius` that macOS Chrome adds.
 * Details at https://github.com/twbs/bootstrap/issues/24093
 */
button {
  border-radius: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 * Credit: https://github.com/suitcss/base/
 */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * form element resets
 * 1. Remove the margin in Firefox and Safari
 * 2. inherit font rules
 */
input,
button,
select,
optgroup,
textarea {
  /* 1 */
  margin: 0;
  /* 2 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=reset],
[type=submit],
[type=button] {
  /* 2 */
  -webkit-appearance: button;
}

/**
 * Remove the default appearance of temporal inputs to avoid a Mobile Safari
 * bug where setting a custom line-height prevents text from being vertically
 * centered within the input.
 * See https://bugs.webkit.org/show_bug.cgi?id=139848
 * and https://github.com/twbs/bootstrap/issues/11266
 */
input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
  overflow: auto;
  resize: vertical;
}

/**
 * Show the overflow in IE.
 */
button,
input {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button,
select {
  text-transform: none;
}

/**
 * Set the cursor for non-`<button>` buttons
 * Details at https://github.com/twbs/bootstrap/pull/30562
 */
[role=button] {
  cursor: pointer;
}

/**
 * Remove the inheritance of word-wrap in Safari.
 * See https://github.com/twbs/bootstrap/issues/24990
 */
select {
  word-wrap: normal;
}

/**
 * Remove inner border and padding from Firefox, but don't restore the outline
 * like Normalize.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * 1. Add the correct box sizing in IE 10-
 * 2. Remove the padding in IE 10-
 */
input[type=radio],
input[type=checkbox] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0;
}

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */
[tabindex="-1"]:focus {
  outline: 0 !important;
}

/**
 * Browsers set a default `min-width: min-content` on fieldsets,
 * unlike e.g. `<div>`s, which have `min-width: 0` by default.
 * So we reset that to ensure fieldsets behave more like a standard block element.
 * See https://github.com/twbs/bootstrap/issues/12359
 * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
 */
fieldset {
  min-width: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Set display to block for all browsers
 */
legend {
  /* 1 */
  max-width: 100%;
  white-space: normal;
  /* 2 */
  color: inherit;
  /* 3 */
  display: block;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * 1. Remove the default vertical scrollbar in IE 10+.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  /* 1 */
  -webkit-appearance: textfield;
  /* 2 */
  outline-offset: -2px;
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
  font: inherit;
}

/**
 * Correct element display for output
 */
output {
  display: inline-block;
}

/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Always hide an element with the `hidden` HTML attribute (from PureCSS).
 * Needed for proper display in IE 10-.
 */
[hidden] {
  display: none;
}

/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 480px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 480px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 480px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 479.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: black;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: black;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}
textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-family: "Outriders Custom", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.5);
  background-color: black;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235d6c7b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #313941;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #313941;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.5);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235d6c7b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235d6c7b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}
.btn-link:hover {
  color: #0a58ca;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 480px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0a58ca;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 480px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 480px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 480px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 479.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 480px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #0d6efd !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 480px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.edd-root,
.edd-root *,
.edd-root *::before,
.edd-root *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.edd-root {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 0;
  min-width: 66px;
  height: 34px;
  user-select: none;
  font-family: "Outriders Custom", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  font-size: 0.95rem;
  color: #333;
}

.edd-root-disabled {
  color: #313941;
  cursor: not-allowed;
}

.edd-root::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #dbaa43;
  transition: transform 150ms ease-out;
  transform: scaleX(0);
}

.edd-root.edd-root-focused::after,
.edd-root.edd-root-invalid::after {
  transform: scaleX(1);
}

.edd-root.edd-root-invalid::after {
  background: #ff6969;
}

.edd-head {
  position: relative;
  overflow: hidden;
  border: 1px solid #313941;
  transition: border-color 200ms;
  background: black;
  height: 34px;
}

.edd-value {
  width: 100%;
  height: inherit;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  padding: 3px 10px 3px 10px;
  line-height: 28px;
  color: white;
  font-weight: 500;
  text-align: left;
}

.edd-arrow {
  position: absolute;
  width: 14px;
  height: 10px;
  top: calc(50% - 5px);
  right: 12px;
  transition: transform 150ms;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.7);
}

.edd-root-disabled .edd-arrow {
  color: #313941;
}

.edd-arrow::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  top: 0;
  right: 2px;
  transform: rotate(45deg);
  transform-origin: 50% 25%;
}

.edd-root-open .edd-arrow {
  transform: rotate(180deg);
}

.edd-value,
.edd-option,
.edd-group-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edd-root:not(.edd-root-disabled) .edd-value,
.edd-option {
  cursor: pointer;
}

.edd-select {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: -100%;
  top: 0;
}

.edd-root-native .edd-select {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.edd-body {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 999;
  background: black;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
  border: 1px solid #313941;
  border-top: 0;
  border-right: 0;
}

.edd-root-open .edd-body {
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
}

.edd-root-open-above .edd-body {
  bottom: 100%;
}

.edd-root-open-below .edd-body {
  top: 100%;
}

.edd-items-list {
  overflow: auto;
  max-height: 0;
  -webkit-overflow-scrolling: touch;
}

.edd-items-list::-webkit-scrollbar {
  width: 6px;
}

.edd-items-list::-webkit-scrollbar-track {
  background: black;
}

.edd-items-list::-webkit-scrollbar-thumb {
  background: #75777b;
  border-radius: 5rem;
}

.edd-group-label {
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 4px 8px 4px 0;
  color: #5d6c7b;
  font-weight: 600;
}

.edd-group-has-label {
  padding-left: 22px;
}

.edd-option {
  position: relative;
  padding: 4px 8px 4px 22px;
  color: rgba(255, 255, 255, 0.7);
}

.edd-option-selected {
  font-weight: 400;
  color: #dbaa43;
}

.edd-option-selected::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 4px;
  border-bottom: 2px solid #dbaa43;
  border-left: 2px solid #dbaa43;
  left: 6px;
  top: calc(50% - 4px);
  transform: rotate(-45deg);
}

.edd-option-focused:not(.edd-option-disabled) {
  color: white;
  background: rgba(49, 57, 65, 0.5);
}

.edd-option-disabled,
.edd-group-disabled .edd-option {
  cursor: default;
  color: #313941;
}

.edd-gradient-top,
.edd-gradient-bottom {
  display: none;
  content: "";
  position: absolute;
  left: 2px;
  right: 12px;
  height: 32px;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 40%, white 60%, rgba(255, 255, 255, 0) 100%);
  background-repeat: repeat-x;
  background-size: 100% 200%;
  pointer-events: none;
  transition: opacity 100ms;
  opacity: 0;
}

.edd-gradient-top {
  background-position: bottom;
  top: 0;
}

.edd-gradient-bottom {
  background-position: top;
  bottom: 0;
}

.edd-body-scrollable .edd-gradient-top,
.edd-body-scrollable .edd-gradient-bottom {
  opacity: 1;
}

.edd-body-scrollable.edd-body-at-top .edd-gradient-top,
.edd-body-scrollable.edd-body-at-bottom .edd-gradient-bottom {
  opacity: 0;
}

.select2-container--default .select2-selection--single {
  background-color: black;
  border: 1px solid #313941;
  border-radius: 0;
  height: 36px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: white;
  line-height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 34px;
  margin-right: 20px;
  padding-right: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 30px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}
.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--group {
  padding: 0;
}
.select2-container--default .select2-results__option--disabled {
  color: #999;
}
.select2-container--default .select2-results__option--selected {
  background-color: #313941;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #dbaa43;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
}

.select2-dropdown {
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid #313941;
  border-radius: 0;
  color: white;
  box-sizing: border-box;
  display: block;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #dbaa43;
  font-weight: 500;
  color: black;
}

.select2-results__options::-webkit-scrollbar-track {
  background-color: #F4F4F4;
}

.select2-results__options::-webkit-scrollbar-thumb {
  background-color: #0F2464;
}

.select2-results__options {
  position: relative;
}

/* Let's get this party started */
.select2-results__options::-webkit-scrollbar {
  width: 6px;
}

.select2-results__options::-webkit-scrollbar-track {
  border-radius: 10px;
  background: black;
}

.select2-results__options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #959698;
}

#mobile-detect {
  display: none;
  content: "mq-mobile";
}

#breakpoint-detect {
  display: none;
  content: "320";
}

.mejs__container {
  width: 100% !important;
}

.explorer-menu-bounds {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 0;
}

.credits-pages {
  position: relative;
  display: flex;
  height: 370px;
  width: 100%;
  overflow: hidden;
}

.help-pages {
  position: relative;
  display: flex;
  height: 370px;
  width: 100%;
  overflow: hidden;
}

.credits-pages-slider {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
}

.help-pages-slider {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
}

.credits-page {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  flex-direction: column;
}
.credits-page .segment-back-btn.std-btn {
  margin-top: auto !important;
}

.help-page {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  flex-direction: column;
}
.help-page .segment-back-btn.std-btn {
  margin-top: auto !important;
}

#credits-tooltip {
  display: flex;
  position: fixed;
  bottom: 45px;
  left: -310px;
  width: 300px;
  transition: left 0.3s ease-in-out;
  z-index: 1007;
}
#credits-tooltip .twitch {
  color: #BE4BDB;
}
#credits-tooltip .youtube {
  color: #FF0402;
}
#credits-tooltip .twitter {
  color: #287CC9;
}
#credits-tooltip .credits-links {
  position: relative;
  display: flex;
  flex-direction: column;
}
#credits-tooltip .credits-social-description {
  font-size: 0.9rem;
  font-weight: 500;
  color: white;
  text-decoration: none;
  color: white;
}
#credits-tooltip .credits-social {
  position: relative;
  display: flex;
  width: 100%;
  padding: 5px;
  line-height: 1;
  align-items: center;
}
#credits-tooltip .credits-social a {
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  text-decoration: none;
}
#credits-tooltip .credits-social a:hover {
  color: white;
}
#credits-tooltip .credits-social.creators {
  font-size: 1rem;
}
#credits-tooltip .paypal-btn {
  position: relative;
  display: flex;
  padding: 5px;
  font-color: white;
  background: #025EA6;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 10px;
}
#credits-tooltip .paypal-btn i {
  font-size: 1rem;
}
#credits-tooltip .paypal-btn:hover {
  background: #0389f2;
}
#credits-tooltip .tooltip-messaging-body {
  max-width: 100%;
  width: 100%;
  padding: 10px 15px 10px 15px;
}
#credits-tooltip .tooltip-messaging-body .credits-subheader {
  font-size: 1rem;
  font-weight: 500;
  color: #dbaa43;
  line-height: 1;
  margin-bottom: 10px;
}
#credits-tooltip .tooltip-messaging-body .credits-subheader i {
  color: red;
}
#credits-tooltip .tooltip-messaging-body .credits-header {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  margin-bottom: 0;
}
#credits-tooltip .tooltip-messaging-body p {
  font-size: 0.9rem;
  line-height: 1.2;
}
#credits-tooltip.show {
  left: 10px;
}

#help-tooltip {
  display: flex;
  position: fixed;
  bottom: 45px;
  right: -310px;
  width: 300px;
  transition: right 0.3s ease-in-out;
  z-index: 1007;
}
#help-tooltip .twitch {
  color: #BE4BDB;
}
#help-tooltip .youtube {
  color: #FF0402;
}
#help-tooltip .twitter {
  color: #287CC9;
}
#help-tooltip .help-links {
  position: relative;
  display: flex;
  flex-direction: column;
}
#help-tooltip .help-description {
  font-size: 0.9rem;
  font-weight: 500;
  color: white;
  text-decoration: none;
  color: white;
}
#help-tooltip .tooltip-messaging-body {
  max-width: 100%;
  width: 100%;
  padding: 10px 15px 10px 15px;
}
#help-tooltip .tooltip-messaging-body .help-subheader {
  font-size: 1rem;
  font-weight: 500;
  color: #dbaa43;
  line-height: 1;
  margin-bottom: 10px;
}
#help-tooltip .tooltip-messaging-body .help-subheader i {
  color: red;
}
#help-tooltip .tooltip-messaging-body .help-header {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  margin-bottom: 0;
}
#help-tooltip .tooltip-messaging-body p {
  font-size: 0.9rem;
  line-height: 1.2;
}
#help-tooltip .tooltip-messaging-body p strong, #help-tooltip .tooltip-messaging-body p em {
  color: white;
  font-weight: 500 !important;
}
#help-tooltip.show {
  right: 10px;
}

#portrait-notice {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex-direction: column;
  display: none;
  pointer-events: none;
  color: white;
  line-height: 1;
  text-align: center;
  padding: 20%;
  font-size: 1.6rem;
  font-weight: 600;
  z-index: 2000;
  align-items: center;
  background: #0F1216;
  justify-content: center;
}
#portrait-notice span i {
  font-size: 3rem;
  margin-bottom: 10px;
}
#portrait-notice.show {
  display: flex;
}

body {
  overflow-x: hidden;
}

.hourglass {
  position: relative;
  display: inline-flex;
  margin-left: 1px;
  margin-right: 1px;
  vertical-align: middle;
  height: inherit;
  padding-bottom: 4px;
}
.hourglass:before {
  content: "⏳";
  color: white;
  font-size: 0.8em;
  vertical-align: middle;
  font-family: "Font Awesome 6 Pro";
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

#globalFilters {
  position: absolute;
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
}

.tooltip-messaging-body {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid #313941;
  padding: 10px 10px 0 10px;
  max-width: 250px;
  background: black;
}
.tooltip-messaging-body img {
  margin-bottom: 10px;
}

#screenshot-upload-tooltip {
  display: none;
}

#info-screenshot-icon {
  color: #dbaa43;
  cursor: pointer;
}

.form-select {
  padding: 0.175rem 2.25rem 0.175rem 0.75rem;
  width: 100%;
  height: 34px;
}
.form-select option:not(:first-of-type) {
  color: white;
}
.form-select option {
  font-size: 1em;
  line-height: 40px !important;
}
.form-select.changed {
  color: white;
}

#screenshot-upload {
  display: none;
}

.screenshot-file-upload {
  border: 1px solid #313941;
  display: inline-block;
  padding: 6px 12px;
  color: rgba(255, 255, 255, 0.5);
  background: black;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.screenshot-file-upload i {
  margin-right: 5px;
  color: #dbaa43;
}

.form-control {
  color: #212529;
  background-color: black;
  border: 1px solid #313941;
  border-radius: 0;
}

.tpd-visible-frame-top .tpd-frames .tpd-frame.tpd-frame-top .tpd-shift-stem .tpd-stem.tpd-stem-reset .tpd-stem-spacer .tpd-stem-notransform .tpd-stem-triangle {
  border-bottom-color: black !important;
}

.tpd-visible-frame-left .tpd-frames .tpd-frame.tpd-frame-left .tpd-shift-stem .tpd-stem.tpd-stem-reset .tpd-stem-spacer .tpd-stem-notransform .tpd-stem-triangle {
  border-right-color: #111314 !important;
}

.ps__thumb-y {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 10px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: rgba(49, 57, 65, 0.7);
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: white;
  width: 6px;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background-color: rgba(49, 57, 65, 0.5);
  opacity: 1;
}

body {
  background-color: #13171b;
  font-family: "Outriders Custom", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100vh;
  height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
  height: 100vh;
  height: -webkit-fill-available;
}

.header-bounds {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 121px;
  z-index: 0;
}

.header-inner-bounds {
  position: absolute;
  width: 100%;
  height: 0;
}

.footer-bounds {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 35px;
  z-index: 0;
}

.header-visual {
  position: relative;
  display: flex;
  width: 100%;
  height: 75px;
  background-image: url("../images/header-visual-mobile.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}

.header-visual-bg {
  position: absolute;
  display: flex;
  width: 100%;
  height: 80px;
  background-image: url("../images/header-visual-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.header-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 50px;
  margin-top: -75px;
}

.header-logo-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 25px;
  margin-bottom: -2px;
  z-index: 2;
}
.header-logo-container.flipped {
  margin-bottom: 0;
  margin-top: -1px;
}
.header-logo-container.flipped .corner {
  background: #14171e;
  width: 23px;
}

.corner {
  position: relative;
  display: flex;
  width: 24px;
  flex: 0 0 auto;
  height: 24px;
}
.corner.left .inner-corner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-left: 1px solid #272A2E;
  transform: skewX(-45deg) translateX(50%);
  background: #0F1216;
}
.corner.left .inner-corner.dark {
  background: #0F1216;
  height: inherit;
  border-top: 1px solid #272A2E;
}
.corner.right .inner-corner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-right: 1px solid #272A2E;
  transform: skewX(45deg) translateX(-50%);
  background: #0F1216;
}
.corner.right .inner-corner.dark {
  background: #0F1216;
  height: inherit;
  border-top: 1px solid #272A2E;
}
.corner.flipped {
  transform: scaleY(-1);
}
.corner.explorer {
  width: 30px;
  height: inherit;
}

.inner-content {
  position: relative;
  width: 100%;
  background: #0F1216;
  border-top: 1px solid #272A2E;
  z-index: 1;
}
.inner-content.flipped {
  border-top: none;
  border-bottom: 1px solid #272A2E;
}

.logo-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.logo-container img {
  width: 55%;
}
.logo-container .slogan {
  position: relative;
  display: flex;
  width: 100%;
  font-size: 0.7rem;
  line-height: 1;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  margin-top: 5px;
}

.header-nav-bar {
  position: relative;
  display: flex;
  width: 100%;
  height: 25px;
  background: #0F1216;
  border-top: 1px solid #272A2E;
  border-bottom: 1px solid #272A2E;
}

.header-nav {
  position: absolute;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  bottom: 0;
  overflow: hidden;
}

.explorer-icons {
  position: absolute;
  width: 110%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 140%;
  bottom: 0;
  overflow: hidden;
}

.explorer-icon {
  position: relative;
  display: flex;
  top: 100%;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.explorer-icon:nth-child(1) .explorer-icon-visual {
  justify-content: flex-start;
}
.explorer-icon:nth-child(2) .explorer-icon-visual {
  justify-content: flex-end;
}

.explorer-icon-visual {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  bottom: 0;
}
.explorer-icon-visual img {
  height: 100%;
  width: auto;
}

.nav-item {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 33.3%;
  flex-direction: column;
  padding: 5px;
  line-height: 1;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.nav-item .headline {
  position: relative;
  display: flex;
  width: 100%;
  font-weight: 500;
  line-height: 1;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-size: 0.6rem;
  transition: all 0.3s ease-in-out;
}
.nav-item .label {
  position: relative;
  line-height: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}
.nav-item:hover {
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}
.nav-item:hover .headline {
  color: rgba(255, 255, 255, 0.85);
}
.nav-item:hover .label {
  color: white;
}
.nav-item.active {
  border-bottom: 2px solid #dbaa43;
}
.nav-item.active .headline {
  color: #dbaa43;
}
.nav-item.active .label {
  color: white;
}

.footer-container {
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;
  top: initial;
  bottom: 0;
}
.footer-container.show {
  top: 164px;
}

.guides-tab-container {
  position: relative;
  display: flex;
  width: 160px;
  height: 35px;
  margin: 0 auto;
  margin-bottom: -1px;
  z-index: 2;
}
.guides-tab-container .corner {
  width: 35px;
  height: 35px;
}

.guides-tab {
  position: relative;
  display: flex;
  width: 100%;
  height: 35px;
}

.guides-btn {
  position: absolute;
  width: inherit;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 10px 0 10px 0;
  line-height: 1;
  border-top: 2px solid transparent;
  cursor: pointer;
}
.guides-btn .headline {
  position: relative;
  display: flex;
  width: 100%;
  font-weight: 500;
  line-height: 1;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-size: 0.7rem;
}
.guides-btn .label {
  position: relative;
  line-height: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  font-size: 1.1rem;
}
.guides-btn:hover {
  border-top: 2px solid rgba(255, 255, 255, 0.3);
}
.guides-btn:hover .headline {
  color: rgba(255, 255, 255, 0.85);
}
.guides-btn:hover .label {
  color: white;
}
.guides-btn.active {
  border-top: 2px solid #dbaa43;
}
.guides-btn.active .headline {
  color: #dbaa43;
}
.guides-btn.active .label {
  color: white;
}

.guides-content {
  position: relative;
  display: flex;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: transparent;
  background-image: none;
  background-size: cover;
  background-position: center top;
  background-repeat: repeat-x;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.guides-content.show {
  background-color: #0F1216;
}
.guides-content.show.outlines {
  border-top: 1px solid #272A2E;
  border-bottom: 1px solid #272A2E;
}

.builderlibrary-tab-container {
  position: relative;
  display: flex;
  width: 200px;
  height: 24px;
  margin: 0 auto;
  margin-bottom: -1px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}

.builder-library {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  top: 99%;
  z-index: 999;
}
.builder-library.show {
  height: calc(100% - 148px);
}
.builder-library.explorer {
  height: 50px;
  top: calc(100% - 60px);
  z-index: 1001;
}

.builderlibrary-content {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100% - 23px);
  overflow: hidden;
  background: #0F1216;
  border-top: 1px solid #272A2E;
  border-bottom: 1px solid #272A2E;
  padding-top: 25px;
}
.builderlibrary-content .segment-content.inner {
  padding-bottom: 0;
}
.footer-nav-bar {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  height: 35px;
  background: #0F1216;
  border-top: 1px solid #272A2E;
  border-bottom: 1px solid #272A2E;
}

.builder-tab {
  position: relative;
  display: flex;
  width: 100%;
}

.builderlibrary-btn {
  position: absolute;
  width: inherit;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 10px;
  line-height: 1;
  border-top: 2px solid transparent;
  cursor: pointer;
}
.builderlibrary-btn .headline {
  position: relative;
  display: flex;
  width: 100%;
  font-weight: 500;
  line-height: 1;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
}
.builderlibrary-btn .label {
  position: relative;
  line-height: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  font-size: 1.4rem;
  transition: all 0.3s ease-in-out;
}
.builderlibrary-btn:hover {
  border-top: 2px solid rgba(255, 255, 255, 0.3);
}
.builderlibrary-btn:hover .headline {
  color: rgba(255, 255, 255, 0.85);
}
.builderlibrary-btn:hover .label {
  color: white;
}
.builderlibrary-btn.active {
  border-top: 2px solid #dbaa43;
}
.builderlibrary-btn.active .headline {
  color: #dbaa43;
}
.builderlibrary-btn.active .label {
  color: white;
}

.explorer-bg-container {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 0;
  background-color: #0F1216;
  background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/explorer-lights.svg");
  background-size: cover;
  background-position: center top;
  background-repeat: repeat-x;
  transition: background-position 0.3s ease-out;
}
.explorer-bg-container.active {
  display: flex;
}

.credits-btn {
  position: fixed;
  display: flex;
  font-weight: 500;
  line-height: 1;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
  align-self: flex-start;
  padding: 10px;
  bottom: 0;
  left: 10px;
  z-index: 1005;
  cursor: pointer;
}

.help-btn {
  position: fixed;
  display: flex;
  font-weight: 500;
  line-height: 1;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
  align-self: flex-start;
  padding: 10px;
  bottom: 0;
  right: 10px;
  z-index: 1005;
  cursor: pointer;
}

.parallax-background {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.parallax-background .visual-container {
  position: relative;
  display: flex;
  width: 300%;
  height: 100%;
  background-image: url("../images/outpost-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
}

.segments-container {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.segments {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
}

.segment {
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex: 0 0 auto;
  overflow: hidden;
  overflow-y: auto;
}
.segment:nth-child(2) {
  left: 100%;
}
.segment:nth-child(3) {
  left: 200%;
}
.segment.builds {
  overflow: hidden;
  overflow-y: hidden;
}

.segment-content {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 0 0 auto;
  margin: 0 auto;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 35px;
}
.segment-content.inner {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}
.segment-content.scroll {
  overflow: hidden;
  overflow-y: auto;
}

.field-label {
  position: relative;
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}
.field-label.results {
  width: 100%;
}
.field-label.armory {
  margin-bottom: 0;
}
.field-label .connector {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}
.field-label .connector .line {
  position: relative;
  display: flex;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.field-label .connector .line.dashed {
  border-style: dashed;
}
.field-label .label-text {
  position: relative;
  display: flex;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.2);
  font-size: 0.7rem;
  line-height: 1;
  align-items: center;
  text-transform: uppercase;
  padding: 5px 10px 5px 10px;
}
.field-label .label-text.highlight-yellow {
  color: #dbaa43;
}
.field-label .label-text.highlight {
  border-radius: 3px;
  color: black;
  margin: 0 10px 0 10px;
}
.field-label .label-text.highlight.unusual {
  background: #88977e;
  color: black;
  font-weight: bold;
}
.field-label .label-text.highlight.rare {
  background: #8497a9;
  color: black;
  font-weight: bold;
}
.field-label .label-text.highlight.epic {
  background: #888297;
  color: black;
  font-weight: bold;
}
.field-label .label-text.highlight.legendary {
  background: #9c7d4d;
  color: black;
  font-weight: bold;
}
.field-label .label-text.highlight.tiertype {
  background: #dbaa43;
  color: black;
  font-weight: bold;
}
.field-label .label-text.large {
  font-size: 1.2rem;
}
.field-label.segment-divider .label-text {
  font-size: 0.7rem;
  color: white;
}
.field-label.segment-divider .label-text.large {
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 500;
}
.field-label.segment-divider .connector .line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.field-label.segment-divider .connector .line.dashed {
  border-style: dashed;
}

.field-group {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
}
.field-group.fixed {
  flex: 0 0 auto;
  width: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
}
.field-group.armory {
  margin-bottom: 0;
}
.field-group.half {
  width: 50%;
}
.field-group.flexed {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.field-group.flexed .field-input {
  height: 34px;
  width: 100%;
}

.field-group-row {
  position: relative;
  display: flex;
  width: 100%;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.field-input {
  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  width: 100%;
  line-height: 34px;
  background-color: black;
  border: 1px solid #313941;
  border-radius: 0;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}
.field-input.left-aligned {
  text-align: left;
}
.field-input.centered {
  text-align: left;
}
.field-input:focus {
  outline: #47535e;
  border-radius: 0;
}

.textarea-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 42px;
  resize: none;
  line-height: 1.22;
  padding-top: 7px;
  background-color: black;
  border: 1px solid #313941;
  border-radius: 0;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}
.textarea-input.left-aligned {
  text-align: left;
}
.textarea-input.centered {
  text-align: left;
}
.textarea-input:focus {
  outline: #47535e;
  border-radius: 0;
}

.common-god-rolls {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.estimates {
  position: relative;
  display: flex;
  width: 100%;
}

.estimate-col {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  padding-left: 5px;
  padding-right: 5px;
  flex-direction: column;
}
.estimate-col .status {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  background: #dbaa43;
  color: black;
  text-transform: uppercase;
  font-size: 0.65rem;
  padding: 5px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}
.estimate-col .status.unusual {
  background: #6F7D65;
  color: white;
}
.estimate-col .status.rare {
  background: #677E93;
  color: white;
}
.estimate-col .status.epic {
  background: #6E687E;
  color: white;
}
.estimate-col .status.legendary {
  background: #7A623C;
  color: white;
}
.estimate-col .init-value {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  margin-bottom: 5px;
  line-height: 1;
}
.estimate-col .max-value {
  position: relative;
  display: flex;
  color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  font-size: 1rem;
  line-height: 1;
}

.action-btn {
  position: relative;
  display: block;
  background: #d2a341;
  border: 1px solid #ffd479;
  padding: 10px 15px 10px 15px;
  font-size: 1.1rem;
  color: black;
  flex: 0 0 auto;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.action-btn.full {
  width: 100%;
}
.action-btn .inner-label {
  display: initial;
  margin-right: 5px;
}
.action-btn .inner-label.responsive {
  display: none;
}
.action-btn.sm {
  display: flex;
  padding: 5px 12px 5px 12px;
  font-size: 0.85rem;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.action-btn.xs {
  display: flex;
  padding: 5px 12px 5px 12px;
  font-size: 0.85rem;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.action-btn.hide {
  display: none;
}
.action-btn:hover {
  background: #eebd58;
  color: black;
}

.std-btn {
  position: relative;
  display: block;
  background: #0F1216;
  border: 1px solid #313941;
  padding: 10px 15px 10px 15px;
  font-size: 0.85rem;
  color: #68798a;
  flex: 0 0 auto;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.std-btn.sm {
  display: flex;
  padding: 5px 12px 5px 12px;
  font-size: 1rem;
  height: 35px;
  align-items: center;
  justify-content: center;
}
.std-btn.xs {
  padding: 5px 8px 5px 8px;
  font-size: 0.7rem;
  height: 25px;
}
.std-btn.compact {
  padding: 5px 12px 5px 12px;
  height: 30px;
  font-size: 0.85rem;
}
.std-btn:hover {
  background: black;
  color: white;
}

#weapon_recommended {
  display: none;
}
#weapon_recommended.show {
  display: flex;
}

#armor_recommended {
  display: none;
}
#armor_recommended.show {
  display: flex;
}

.weapon_tier_marker {
  display: none;
}
.weapon_tier_marker.show {
  display: flex;
}

.armor_tier_marker {
  display: none;
}
.armor_tier_marker.show {
  display: flex;
}

.stats-results {
  position: relative;
  display: none;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.stats-results .field-label {
  margin-bottom: 10px;
}
.stats-results.weapons.show {
  display: flex;
}
.stats-results.armor.show {
  display: flex;
}

.result-stats {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.stats {
  position: relative;
  display: none;
  width: 50%;
  padding: 5px;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
}
.stats.show {
  display: flex;
}

.result-stat {
  position: relative;
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
}

.result-short {
  position: relative;
  display: block;
  text-align: center;
  line-height: 1;
  color: white;
  font-weight: 600;
  font-size: 2.8rem;
}

.result-full {
  position: relative;
  display: block;
  text-align: center;
  line-height: 1;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.2rem;
}

.top-controls {
  position: absolute;
  width: 100%;
  display: flex;
  height: 3px;
  padding-left: 20px;
  padding-right: 20px;
}

.segment-header-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}
.segment-header-container.explorer {
  padding-top: 0;
  flex-direction: column;
}

.segment-header {
  position: relative;
  width: 100%;
  flex-direction: column;
  line-height: 1;
  margin: 0 auto;
  max-width: 1140px;
  margin-bottom: 0;
}

.segment-header-visual {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.segment-header-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 31.25%;
}
.segment-header-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.segment-header-visual .content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.segment-header-visual .content svg {
  height: 70%;
}
.segment-header-visual.voices {
  background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/voices-of-enoch.jpg");
}

.segment-header-headline {
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 1;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}
.segment-header-headline.simple {
  color: white;
  padding: 25px 15px 10px 15px;
}

.segment-header-title {
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  color: white;
}

.segment-header-description {
  position: relative;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  line-height: 1.25;
  font-size: 0.85rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 10px;
  margin-bottom: 10px;
}

.segment-text {
  position: relative;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  line-height: 1.3;
  font-size: 0.78rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 5px;
}

.social-connect-container {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 0;
}

.social-connect {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  width: 25%;
  color: white;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0);
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.social-connect:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.social-connect > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.social-connect .content {
  display: flex;
  padding: 20px;
  font-size: 1.6rem;
  justify-content: center;
  align-items: center;
}
.social-connect:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.social-connect:hover {
  background: #0F1216;
  border: 3px solid #dbaa43;
  color: #dbaa43;
  z-index: 5;
}

.divider-symbol {
  position: relative;
  display: flex;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  color: black;
  background: #dbaa43;
  margin: 0 auto;
}

.subsegment-container {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  max-width: initial;
  margin: 0 auto;
  overflow: hidden;
}

.subsegment-container-carousel {
  position: absolute;
  width: 100%;
  display: flex;
  height: 100%;
}

.segment-content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: flex-start;
  flex: 0 0 auto;
  padding: 0;
  margin: 0 auto;
  max-width: 576px;
  padding-bottom: 20px;
}
.segment-content-container.wide {
  max-width: 100%;
}
.segment-content-container.builder-scroller {
  padding-top: 0;
  padding-bottom: 0;
}

.segment-controls {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
}

.profile-container {
  position: relative;
  display: flex;
  width: auto;
  flex: 0 0 auto;
}

.profile-avatar {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 30px;
  border: 1px solid #313941;
  background-color: black;
  background-image: none;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  margin-right: 10px;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
  position: relative;
}
.profile-avatar:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.profile-avatar > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.profile-nickname {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  flex: 1 1 auto;
}
.profile-nickname .label {
  position: relative;
  display: flex;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 0.7rem;
  margin-bottom: 3px;
}
.profile-nickname .nickname {
  font-size: 0.9rem;
}

.profile-logout {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  align-items: flex-end;
  padding-bottom: 5px;
  margin-left: 8px;
  font-size: 1rem;
  color: #dbaa43;
  cursor: pointer;
}

.build-list {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - 320px);
  padding-right: 15px;
  padding-left: 15px;
  overflow: hidden;
  overflow-y: auto;
}

.build-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  flex: 0 0 auto;
  padding-bottom: 0;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.build-container.wide {
  max-width: 100%;
}
.build-container.armory {
  padding-left: 10px;
  padding-right: 10px;
}

.build-creator-content {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1140px;
  height: auto;
  flex-direction: column;
  padding-bottom: 100px;
  margin: 0 auto;
}

.build-library {
  position: relative;
  display: flex;
  width: 100%;
  align-content: flex-start;
  flex: 0 0 auto;
  flex-wrap: wrap;
  max-width: 1140px;
  margin: 0 auto;
  padding-bottom: 35px;
}

.build-item-content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: #13171B;
  align-items: center;
  padding: 15px 25px 15px 115px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.build-item-content:hover .build-item-visual {
  border: 4px solid #dbaa43;
}
.build-item-content:hover .build-item-visual img {
  filter: none;
  mix-blend-mode: normal;
  opacity: 1;
}

.build-item-visual {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  border: 1px solid #313941;
}
.build-item-visual img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.build-item-info {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  z-index: 5;
}

.build-item-title {
  font-size: 1rem;
  line-height: 1.25;
  color: white;
  margin-bottom: 3px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.build-item-desc {
  font-size: 0.7rem;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in-out;
}

.build-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  justify-content: center;
  width: 100%;
  height: 175px;
  padding: 5px;
}

.notice-group {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  line-height: 1.2;
  font-size: 3rem;
}
.notice-group .title {
  font-size: 2.6rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1;
  text-align: center;
}
.notice-group .description {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
  text-align: center;
}

.class-chooser {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}

.character-module {
  flex: 0 0 auto;
  width: 100%;
  display: none;
}
.character-module.active {
  display: block;
}
.character-module .field-label {
  margin-top: 10px;
}

.build-meta {
  flex: 0 0 auto;
  width: 100%;
}

.build-class-chooser {
  flex: 1 1 auto;
  width: 100%;
}

.character-image-column {
  position: relative;
  width: 100%;
  height: 380px;
}

.character-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: inherit;
}
.character-image img {
  height: 100%;
}
.character-image.active {
  display: flex;
}
.character-image .remove-character {
  position: absolute;
  top: 5px;
  right: 5px;
}

.class-icon {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 25%;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}
.class-icon:last-child {
  margin-right: 0;
}
.class-icon svg {
  width: 100%;
  height: auto;
}
.class-icon svg .icon-gradient {
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.class-icon svg .icon-symbol {
  transition: all 0.3s ease-in-out;
  fill: rgba(255, 255, 255, 0.5);
}
.class-icon:hover svg .icon-symbol {
  transition: all 0.3s ease-in-out;
  fill: white;
}
.class-icon.selected svg .icon-gradient {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.class-icon.selected svg .icon-symbol {
  transition: all 0.3s ease-in-out;
  fill: #dbaa43;
}

.skills-container {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 992px;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5px;
  padding-right: 5px;
}

.skill-chooser {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0 5px 0;
}

.skill-chooser-unavailable {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 1.1;
  font-weight: normal;
  padding-bottom: 50px;
  color: rgba(255, 255, 255, 0.3);
}

.skills-carousel {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
}

.skill-icon {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 25%;
  padding: 5px;
  flex-direction: column;
  cursor: pointer;
}
.skill-icon .skill-visual {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  filter: url("#grayscaleFilter");
  background-image: none;
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  transition: all 0.3s ease-out;
  position: relative;
}
.skill-icon .skill-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.skill-icon .skill-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.skill-icon .skill-visual .content {
  transition: all 0.3s ease-out;
  border: 1px solid #313941;
}
.skill-icon .skill-name {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  font-size: 0.6rem;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 10px;
  transition: all 0.3s ease-out;
}
.skill-icon .skill-type {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  font-size: 0.6rem;
  justify-content: center;
  text-align: center;
}
.skill-icon:hover .skill-visual .content {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.skill-icon:hover .skill-name {
  color: rgba(255, 255, 255, 0.8);
}
.skill-icon.selected .skill-visual {
  filter: none;
}
.skill-icon.selected .skill-visual .content {
  border: 1px solid black;
  box-shadow: inset 0 0 0 3px #dbaa43;
}
.skill-icon.selected .skill-name {
  color: #dbaa43;
}
.skill-icon.selected .skill-type {
  color: rgba(255, 255, 255, 0.8);
}

.class-trees {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5em;
  background-color: #dbaa43;
  z-index: 1001;
}
.class-trees.final {
  border-radius: 0;
}

#class-tree-btn {
  height: 40px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
}
#class-tree-btn.inflate span {
  opacity: 0;
}
#class-tree-btn.unavailable {
  opacity: 0.5;
  cursor: not-allowed;
}

.classtree-container {
  position: relative;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  color: white;
}

.class-trees-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
}
.class-trees-content.active {
  visibility: visible;
}

.classtree-close-btn {
  position: absolute;
  top: 5px;
  right: 15px;
  color: white;
  font-size: 2rem;
  color: #dbaa43;
  cursor: pointer;
  z-index: 1003;
}
.classtree-close-btn:hover {
  color: white;
}

.gear-container {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  flex-direction: row;
}

.gear-icon {
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  align-self: flex-start;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0 0 0 2px #313941;
  color: #dbaa43;
  transition: all 0.3s ease-out;
  cursor: pointer;
  position: relative;
}
.gear-icon:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 66.6666666667%;
}
.gear-icon > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.gear-icon .content {
  display: flex;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  top: 8px;
  left: 8px;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  transition: all 0.3s ease-in-out;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
  filter: url(#dropshadow);
}
.gear-icon .content.sharpen {
  opacity: 0.25;
  filter: url(#Sharpen);
}
.gear-icon:hover {
  color: white;
  box-shadow: inset 0 0 0 4px #dbaa43;
}
.gear-icon.populated {
  background: transparent;
}
.gear-icon.populated .content > * {
  display: none;
  box-shadow: inset 0 0 0 4px white;
}

.gear-icon-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.gear-icon-background.common {
  background: #848485;
  background: linear-gradient(145deg, #6b6c6c 0%, #a5a5a6 50%, #6b6c6c 100%);
}
.gear-icon-background.unusual {
  background: #6F7D65;
  background: linear-gradient(145deg, #415136 0%, #829670 50%, #415136 100%);
}
.gear-icon-background.rare {
  background: #677E93;
  background: linear-gradient(145deg, #3a4e5e 0%, #6d8fa7 50%, #3a4e5e 100%);
}
.gear-icon-background.epic {
  background: #6E687E;
  background: linear-gradient(145deg, #515073 0%, #877699 50%, #515073 100%);
}
.gear-icon-background.legendary {
  background: #7A623C;
  background: linear-gradient(145deg, #5a4117 0%, #9b7b3e 50%, #5a4117 100%);
}

.gear-title {
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  font-size: 1rem;
  font-weight: 500;
  height: 50px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.15;
  padding: 8px 0 5px 0;
  text-align: center;
  justify-content: center;
}

.gear-mods {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: row;
}

.gear-mod {
  position: relative;
  display: flex;
  width: 50%;
  height: 100%;
  flex: 0 0 auto;
  flex-direction: column;
}

.gear-mod-icon {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  padding: 5px;
  cursor: pointer;
}
.gear-mod-icon .visual {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  background: rgba(0, 0, 0, 0.7);
  color: #dbaa43;
  transition: all 0.3s ease-out;
  position: relative;
}
.gear-mod-icon .visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.gear-mod-icon .visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.gear-mod-icon .visual .content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  transition: all 0.3s ease-in-out;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: inset 0 0 0 1px #313941;
}
.gear-mod-icon .visual .mod-tier {
  position: absolute;
  display: none;
  top: -12px;
  font-size: 1em;
  left: 50%;
  font-weight: 600;
  transform: translateX(-50%);
  color: white;
  text-shadow: 1px 1px 3px black;
  z-index: 5;
}
.gear-mod-icon:hover .visual {
  background: black;
  color: white;
}
.gear-mod-icon:hover .visual .content {
  box-shadow: inset 0 0 0 3px #dbaa43;
}
.gear-mod-icon.populated {
  background: transparent;
}
.gear-mod-icon.populated .visual .mod-tier {
  display: initial;
}
.gear-mod-icon.populated .content {
  box-shadow: inset 0 0 0 2px #313941;
}
.gear-mod-icon.populated .content i {
  display: none;
}

.gear-mod-label {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.6rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 5px 5px 5px;
}

.gear-mods-container {
  position: relative;
  display: flex;
  width: 50%;
  flex-direction: column;
}

.gear-attributes-chooser {
  position: relative;
  display: flex;
  flex-direction: column;
}
.gear-attributes-chooser .edd-root {
  margin-bottom: 3px;
}

.gear-attributes {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 5px;
  margin-bottom: 5px;
}
.gear-attributes .select2 {
  margin-bottom: 5px;
}
.gear-attributes .field-label {
  display: none;
}
.gear-attributes .field-label.forceshow {
  display: flex;
}

.gear-setbonus {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gear-setbonus-info {
  position: relative;
  display: flex;
  line-height: 1;
  font-weight: normal;
  font-size: 0.9rem;
  width: 100%;
  padding: 0 10px 0 10px;
}

.gear-rarity-checkboxes {
  position: relative;
  display: flex;
  width: 100%;
}

.gear-rarity {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.gear-rarity-group {
  width: 100%;
  height: auto;
}
.gear-rarity-group > * {
  border-radius: 0;
}
.gear-rarity-group .btn-check:checked + .btn-outline-primary:focus, .gear-rarity-group .btn-check:active + .btn-outline-primary:focus, .gear-rarity-group .btn-outline-primary:active:focus, .gear-rarity-group .btn-outline-primary.active:focus, .gear-rarity-group .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: none;
}
.gear-rarity-group .btn-check:focus + .btn-outline-primary, .gear-rarity-group .btn-outline-primary:focus {
  box-shadow: none;
}
.gear-rarity-group .btn-outline-primary.gear-rarity-btn.common:hover {
  background-color: #848485;
  border: none;
  box-shadow: inset 0 0 0 0 #9e9e9e;
  z-index: 3;
}
.gear-rarity-group .btn-outline-primary.gear-rarity-btn.unusual:hover {
  background-color: #6F7D65;
  border: none;
  box-shadow: inset 0 0 0 0 #88977e;
  z-index: 3;
}
.gear-rarity-group .btn-outline-primary.gear-rarity-btn.rare:hover {
  background-color: #677E93;
  border: none;
  box-shadow: inset 0 0 0 0 #8497a9;
  z-index: 3;
}
.gear-rarity-group .btn-outline-primary.gear-rarity-btn.epic:hover {
  background-color: #6E687E;
  border: none;
  box-shadow: inset 0 0 0 0 #888297;
  z-index: 3;
}
.gear-rarity-group .btn-outline-primary.gear-rarity-btn.legendary:hover {
  background-color: #7A623C;
  border: none;
  box-shadow: inset 0 0 0 0 #9c7d4d;
  z-index: 3;
}
.gear-rarity-group .btn-check:checked + .btn-outline-primary.gear-rarity-btn, .gear-rarity-group .btn-check:active + .btn-outline-primary.gear-rarity-btn, .gear-rarity-group .btn-outline-primary:active.gear-rarity-btn, .gear-rarity-group .btn-outline-primary.active.gear-rarity-btn, .gear-rarity-group .btn-outline-primary.dropdown-toggle.show.gear-rarity-btn {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);
}
.gear-rarity-group .btn-check:checked + .btn-outline-primary.gear-rarity-btn > *, .gear-rarity-group .btn-check:active + .btn-outline-primary.gear-rarity-btn > *, .gear-rarity-group .btn-outline-primary:active.gear-rarity-btn > *, .gear-rarity-group .btn-outline-primary.active.gear-rarity-btn > *, .gear-rarity-group .btn-outline-primary.dropdown-toggle.show.gear-rarity-btn > * {
  visibility: visible;
}
.gear-rarity-group .btn-check:checked + .btn-outline-primary.gear-rarity-btn.common, .gear-rarity-group .btn-check:active + .btn-outline-primary.gear-rarity-btn.common, .gear-rarity-group .btn-outline-primary:active.gear-rarity-btn.common, .gear-rarity-group .btn-outline-primary.active.gear-rarity-btn.common, .gear-rarity-group .btn-outline-primary.dropdown-toggle.show.gear-rarity-btn.common {
  background-color: #848485;
  border: none;
}
.gear-rarity-group .btn-check:checked + .btn-outline-primary.gear-rarity-btn.unusual, .gear-rarity-group .btn-check:active + .btn-outline-primary.gear-rarity-btn.unusual, .gear-rarity-group .btn-outline-primary:active.gear-rarity-btn.unusual, .gear-rarity-group .btn-outline-primary.active.gear-rarity-btn.unusual, .gear-rarity-group .btn-outline-primary.dropdown-toggle.show.gear-rarity-btn.unusual {
  background-color: #6F7D65;
  border: none;
}
.gear-rarity-group .btn-check:checked + .btn-outline-primary.gear-rarity-btn.rare, .gear-rarity-group .btn-check:active + .btn-outline-primary.gear-rarity-btn.rare, .gear-rarity-group .btn-outline-primary:active.gear-rarity-btn.rare, .gear-rarity-group .btn-outline-primary.active.gear-rarity-btn.rare, .gear-rarity-group .btn-outline-primary.dropdown-toggle.show.gear-rarity-btn.rare {
  background-color: #677E93;
  border: none;
}
.gear-rarity-group .btn-check:checked + .btn-outline-primary.gear-rarity-btn.epic, .gear-rarity-group .btn-check:active + .btn-outline-primary.gear-rarity-btn.epic, .gear-rarity-group .btn-outline-primary:active.gear-rarity-btn.epic, .gear-rarity-group .btn-outline-primary.active.gear-rarity-btn.epic, .gear-rarity-group .btn-outline-primary.dropdown-toggle.show.gear-rarity-btn.epic {
  background-color: #6E687E;
  border-color: #6E687E;
  border: none;
}
.gear-rarity-group .btn-check:checked + .btn-outline-primary.gear-rarity-btn.legendary, .gear-rarity-group .btn-check:active + .btn-outline-primary.gear-rarity-btn.legendary, .gear-rarity-group .btn-outline-primary:active.gear-rarity-btn.legendary, .gear-rarity-group .btn-outline-primary.active.gear-rarity-btn.legendary, .gear-rarity-group .btn-outline-primary.dropdown-toggle.show.gear-rarity-btn.legendary {
  background-color: #7A623C;
  border: none;
}

.gear-rarity-btn {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  color: white;
}
.gear-rarity-btn > * {
  visibility: hidden;
}
.gear-rarity-btn.common {
  background: #848485;
}
.gear-rarity-btn.unusual {
  background: #6F7D65;
}
.gear-rarity-btn.rare {
  background: #677E93;
}
.gear-rarity-btn.epic {
  background: #6E687E;
}
.gear-rarity-btn.legendary {
  background: #7A623C;
}

.armor-item {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
  flex-direction: column;
}
.armor-item:last-child {
  border-right: none;
}

.weapon-item {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  flex-direction: column;
  margin-bottom: 30px;
}

.builder-main-gear-label {
  width: 50%;
  padding-right: 0;
  padding-left: 0;
}
.builder-main-gear-label .label-text {
  color: white;
}

.builder-attributes-label {
  width: 50%;
  padding-right: 0;
  padding-left: 0;
}

.skill-tree-container {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  align-items: initial;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 10px;
  background-image: url("../images/tree-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  position: relative;
}
.skill-tree-container:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 46.2068965517%;
}
.skill-tree-container > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.skill-tree-container svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(0);
  height: 100%;
  padding: 60px 30px 30px 30px;
  width: auto;
  max-width: 2100px;
}
.skill-tree-container #classtree {
  width: 100% !important;
}
.skill-tree-container #classtree defs#image3, .skill-tree-container #classtree defs#image0, .skill-tree-container #classtree defs#image1, .skill-tree-container #classtree defs#image2 {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}
.skill-tree-container #classtree #backgrounds .class-visual {
  filter: url("#grayscaleFilter");
  opacity: 0.5;
}
.skill-tree-container #classtree #backgrounds .class-visual.active {
  opacity: 1;
  filter: none;
}
.skill-tree-container #classtree #skill-tree #connectors > * {
  stroke: rgba(255, 255, 255, 0.3);
  stroke-width: 2;
  opacity: 1;
}
.skill-tree-container #classtree #skill-tree #connectors > *.available {
  stroke: white;
}
.skill-tree-container #classtree #skill-tree #connectors > *.active {
  stroke: #dbaa43;
}
.skill-tree-container #classtree #skill-tree #nodes > *:not(g) {
  stroke: #5E6062;
  stroke-width: 2;
  filter: url("#grayscaleFilter");
  fill-opacity: 0.5;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 1;
}
.skill-tree-container #classtree #skill-tree #nodes > *:not(g).available {
  stroke: white;
  stroke-width: 2;
  filter: url("#grayscaleFilter");
  fill-opacity: 1;
}
.skill-tree-container #classtree #skill-tree #nodes > *:not(g).active {
  stroke: #dbaa43;
  stroke-width: 3;
  filter: none;
  fill-opacity: 1;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root) {
  cursor: pointer;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root) *[id*=icon] {
  fill: #5E6062;
  stroke: none;
  stroke-width: 0;
  filter: none;
  fill-opacity: 1;
  transition: all 0.3s ease-out;
  opacity: 1;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root) *[id*=icon] > * {
  fill: #5E6062;
  transition: all 0.3s ease-out;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root) *[id*=bg] {
  stroke: #5E6062;
  stroke-width: 2;
  fill-opacity: 1;
  transition: all 0.3s ease-out;
  opacity: 1;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root).available *[id*=icon] {
  fill: white;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root).available *[id*=icon] > * {
  fill: white;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root).available *[id*=bg] {
  stroke: white;
  stroke-width: 3;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root).active *[id*=icon] {
  fill: #dbaa43;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root).active *[id*=icon] > * {
  fill: #dbaa43;
}
.skill-tree-container #classtree #skill-tree #nodes > g:not(.active-root).active *[id*=bg] {
  stroke: #dbaa43;
}
.skill-tree-container #classtree #skill-tree #nodes .active-root {
  stroke: none;
  fill: none;
  stroke-width: 0;
  filter: none;
  fill-opacity: 1;
}
.skill-tree-container #classtree #skill-tree #nodes .active-root.active {
  stroke: none;
  fill: none;
  stroke-width: 0;
  filter: none;
  fill-opacity: 1;
}
.skill-tree-container #classtree #class-labels > * {
  fill: rgba(255, 255, 255, 0.5);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.skill-tree-container #classtree #class-labels > *.active {
  fill: white;
}
.skill-tree-container .svg-class-title {
  font-family: "Outriders Custom", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 2rem;
  text-align: right;
  font-weight: normal;
  fill: white;
}

#resetTreeBtn {
  position: relative;
  margin-bottom: 0;
  margin-left: 5px;
}

.skill-tree-points-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 5px;
  left: 0;
  width: 100%;
  z-index: 1002;
  padding: 5px 10px 5px 10px;
}

.skilltree-label {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  flex: 0 0 auto;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 5px;
  margin-right: 60px;
}

.skilltree-points {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  flex: 0 0 auto;
  width: auto;
}
.skilltree-points span {
  font-family: "Outriders Custom", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1;
  color: #dbaa43;
}
.skilltree-points img {
  height: 1.6rem;
  margin-right: 5px;
}

.node-tooltip-title {
  position: relative;
  display: flex;
  width: 100%;
  padding: 5px 10px 5px 10px;
  color: white;
  font-size: 1.2rem;
  background: black;
  line-height: 1;
  font-weight: 600;
}
.node-tooltip-title.active {
  color: #dbaa43;
}
.node-tooltip-title.root-class {
  color: #dbaa43 !important;
}

.node-tooltip-subtitle {
  position: relative;
  display: flex;
  width: 100%;
  padding: 6px 10px 6px 10px;
  color: white;
  font-size: 0.8rem;
  text-transform: uppercase;
  background: #555A5D;
  overflow: hidden;
  line-height: 1;
  font-weight: bold;
}
.node-tooltip-subtitle.root-class {
  font-weight: 500;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
  text-transform: initial;
  background: #393d41;
}

.node-tooltip-visual {
  position: relative;
  display: flex;
  width: 100%;
  position: relative;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
}
.node-tooltip-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.node-tooltip-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.node-tooltip-skillgroup {
  position: relative;
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
}

.node-tooltip-dark-headline {
  position: relative;
  width: 100%;
  color: white;
  font-size: 0.85rem;
  line-height: 1.25;
  font-weight: 600;
}

.node-tooltip-dark-text {
  position: relative;
  width: 100%;
  color: white;
  font-size: 0.85rem;
  line-height: 1.25;
  font-weight: 500;
}

.node-tooltip-text-dark {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 10px 15px 10px;
  color: black;
  font-size: 1rem;
  background: #393d41;
  background: radial-gradient(circle, #393d41 0%, #111314 100%);
  line-height: 1.25;
  font-weight: 500;
}
.node-tooltip-text-dark ul {
  padding: 0 15px 0 0;
  list-style: none;
  margin: 0;
}
.node-tooltip-text-dark ul li {
  text-indent: -12px;
  margin-left: 15px;
  margin-bottom: 5px;
}
.node-tooltip-text-dark ul li:before {
  content: "•";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: bold;
  vertical-align: text-bottom;
  margin-right: 5px;
}

.node-tooltip-text {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 10px 15px 10px;
  color: black;
  font-size: 1rem;
  background: white;
  line-height: 1.25;
  font-weight: 500;
}
.node-tooltip-text ul {
  padding: 0 15px 0 0;
  list-style: none;
  margin: 0;
}
.node-tooltip-text ul li {
  text-indent: -12px;
  margin-left: 15px;
  margin-bottom: 5px;
}
.node-tooltip-text ul li:before {
  content: "•";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: bold;
  vertical-align: text-bottom;
  margin-right: 5px;
}
.node-tooltip-text.root-class {
  font-size: 0.8rem;
  line-height: 1.1;
}

.node-tooltip {
  position: relative;
  display: none;
  width: 100%;
  padding: 0;
  max-width: 300px;
}

.node-tooltip-skills {
  position: relative;
  display: block;
  width: 100%;
  background: #d7d9da;
  color: white;
}

.node-tooltip-skills-title {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 10px 6px 10px;
  color: black;
  font-size: 0.8rem;
  text-transform: uppercase;
  overflow: hidden;
  line-height: 1;
  font-weight: 500;
}

.node-tooltip-skills-icons {
  position: relative;
  display: inline-block;
  width: 100%;
  flex: 0 0 auto;
  padding: 0 10px 6px 10px;
}

.node-tooltip-skill-icon {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-right: 3px;
  border: 2px solid white;
  background-image: none;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming)*/
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.armory-content {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: 75px;
}

.armory-header {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
}
.armory-header:first-child {
  padding-top: 0;
}

.armory-header-title {
  position: relative;
  display: flex;
  width: auto;
  flex: 0 0 auto;
  font-size: 1.3rem;
  font-weight: normal;
  color: white;
  align-items: center;
  padding: 0 5px 0 5px;
}

.armor-header-description {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  font-size: 0.75rem;
  line-height: 1.22;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  padding: 0 5px 0 5px;
}

.slanted-lines {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  height: inherit;
  margin-left: 10px;
  margin-right: 5px;
  padding-top: 1px;
  justify-content: flex-start;
  padding-left: 5px;
  padding-bottom: 1px;
  overflow: hidden;
}
.slanted-lines .slanted-line {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  background: white;
  width: 5px;
  margin-right: 5px;
  height: 100%;
  transform: skewX(-20deg);
}

.armory-item {
  position: relative;
  display: flex;
  width: 50%;
  margin-bottom: 10px;
  flex: 0 0 auto;
  padding: 5px;
  flex-direction: column;
}
.armory-item.explorer-armor {
  width: 30px;
  flex: 0 0 auto;
  padding: 0;
}
.armory-item.explorer-armor .item-visual {
  position: relative;
  cursor: default;
}
.armory-item.explorer-armor .item-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.armory-item.explorer-armor .item-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.armory-item.explorer-armor .item-visual .content {
  border: none;
  box-shadow: none;
  padding: 0;
}
.armory-item.explorer-armor .item-visual .content .visual {
  width: 100%;
  height: 100%;
}
.armory-item.explorer-armor .item-visual .item-visual-bg {
  background: transparent;
}
.armory-item.explorer-armor .item-visual:hover .content {
  box-shadow: none;
}
.armory-item.explorer-armor .item-visual:hover .content .visual {
  filter: none;
}
.armory-item.explorer-armor .item-visual:hover .item-visual-bg.hovershadow {
  display: none;
}
.armory-item.explorer-armor.upper {
  width: 35px;
}
.armory-item.explorer-armor.upper .item-visual {
  position: relative;
}
.armory-item.explorer-armor.upper .item-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 75%;
}
.armory-item.explorer-armor.upper .item-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.armory-item.explorer-armor.footgear {
  width: 35px;
}
.armory-item.explorer-armor.footgear .item-visual {
  position: relative;
}
.armory-item.explorer-armor.footgear .item-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 75%;
}
.armory-item.explorer-armor.footgear .item-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.armory-item .item-visual {
  position: relative;
  width: 100%;
  cursor: pointer;
  position: relative;
}
.armory-item .item-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 66.6666666667%;
}
.armory-item .item-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.armory-item .item-visual .item-visual-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s ease-out;
}
.armory-item .item-visual .item-visual-bg.common {
  background: #848485;
  background: linear-gradient(145deg, #6b6c6c 0%, #a5a5a6 50%, #6b6c6c 100%);
}
.armory-item .item-visual .item-visual-bg.unusual {
  background: #6F7D65;
  background: linear-gradient(145deg, #415136 0%, #829670 50%, #415136 100%);
}
.armory-item .item-visual .item-visual-bg.rare {
  background: #677E93;
  background: linear-gradient(145deg, #3a4e5e 0%, #6d8fa7 50%, #3a4e5e 100%);
}
.armory-item .item-visual .item-visual-bg.epic {
  background: #6E687E;
  background: linear-gradient(145deg, #515073 0%, #877699 50%, #515073 100%);
}
.armory-item .item-visual .item-visual-bg.legendary {
  background: #7A623C;
  background: linear-gradient(145deg, #5a4117 0%, #9b7b3e 50%, #5a4117 100%);
}
.armory-item .item-visual .item-visual-bg.hovershadow {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.armory-item .item-visual .content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 3;
  transition: all 0.3s ease-out;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
}
.armory-item .item-visual .content .visual {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.armory-item .item-visual .content .visual.sharpen {
  opacity: 0.25;
  filter: url(#Sharpen);
}
.armory-item .item-visual .content .visual.breathe {
  width: calc(100% - 45px);
  height: calc(100% - 45px);
}
.armory-item .item-visual:hover .content {
  box-shadow: inset 0 0 0 4px white;
}
.armory-item .item-visual:hover .content .visual {
  filter: url(#dropshadow);
}
.armory-item .item-visual:hover .content .visual.sharpen {
  opacity: 0.25;
  filter: url(#Sharpen);
}
.armory-item .item-visual:hover .item-visual-bg.hovershadow {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.armory-item .item-title {
  position: relative;
  width: 100%;
  color: white;
  line-height: 1;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 5px;
}

.mod-item {
  position: relative;
  display: flex;
  width: 33.3%;
  flex: 0 0 auto;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-direction: column;
}
.mod-item .item-visual {
  position: relative;
  width: 100%;
  cursor: pointer;
  position: relative;
}
.mod-item .item-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.mod-item .item-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mod-item .item-visual .content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.mod-item .item-visual .content .tier-icon {
  position: absolute;
  width: 100%;
  text-align: center;
  top: -13px;
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}
.mod-item .item-visual .content .highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  box-shadow: inset 0 0 0 1px #313941;
}
.mod-item .item-visual .content .filter {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
  top: 0;
  left: 0;
  opacity: 0.25;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  filter: url(#Sharpen);
}
.mod-item .item-visual:hover .content .highlight {
  box-shadow: inset 0 0 0 3px #525f6d;
}
.mod-item .item-visual:hover .content .filter {
  opacity: 0.25;
  filter: url(#Sharpen);
}
.mod-item .item-title {
  position: relative;
  width: 100%;
  color: white;
  line-height: 1.1;
  text-align: center;
  font-size: 0.85rem;
  margin-top: 5px;
}

.armory-search-input {
  width: 100%;
  padding-right: 34px;
}

.armory-inputsearch-container {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  flex-direction: row;
}

.armory-filter-mods-container {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 85px;
  height: 100%;
  margin-left: 5px;
}
.armory-filter-mods-container.armor {
  width: 125px;
}
.armory-filter-mods-container select {
  height: 34px;
}

.armory-filter-modtypes-container {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  margin-left: 5px;
}
.armory-filter-modtypes-container select {
  height: 34px;
}

.search-clear-btn {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  height: 34px;
  width: 34px;
  font-size: 1rem;
  color: white;
  cursor: pointer;
}
.search-clear-btn.show {
  display: flex;
}
.search-clear-btn.explorer {
  height: 30px;
}

.mod-item-tooltip {
  position: relative;
  display: none;
  width: 100%;
  padding: 0;
  max-width: 340px;
}

.mod-item-tooltip-header {
  position: relative;
  width: 100%;
  background: black;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  padding: 10px;
}

.mod-item-tooltip-body {
  position: relative;
  width: 100%;
  background: white;
  color: black;
  font-weight: normal;
  font-size: 1rem;
  padding: 10px;
}
.mod-item-tooltip-body .cooldown {
  font-size: 0.8em;
  font-family: "Font Awesome 6 Pro";
}

#builds-login-section {
  display: flex;
}
#builds-login-section.inactive {
  display: none;
}

#builds-library-section {
  display: flex;
}
#builds-library-section.inactive {
  display: none;
}

.builder-right-controls {
  position: relative;
  display: flex;
  margin-left: auto;
}

#viewbuild-btn {
  display: flex;
}
#viewbuild-btn.inactive {
  display: none;
}

.build-render-container {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 2560px;
  height: 1440px;
  background: black;
  overflow: hidden;
  align-self: center;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}
.build-render-container strong {
  color: white;
}
.build-render-container em {
  color: white;
}

.character-render {
  position: absolute;
  top: 20px;
  left: 490px;
  width: 420px;
  height: 850px;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: 100;
}

.class-trees.render {
  z-index: 99;
  position: absolute;
  top: 10px;
  left: initial;
  right: 15px;
  width: 1790px;
  height: 845px;
}

.class-icon-render {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 150px;
  height: 150px;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}

.render-build-info {
  position: absolute;
  left: 200px;
  top: 40px;
  width: 340px;
  color: white;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.render-build-title {
  width: 100%;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #dbaa43;
  margin-bottom: 10px;
}

.render-build-description {
  width: 100%;
  font-size: 1.1rem;
  padding-right: 10px;
  line-height: 1.22;
  font-weight: 500;
  color: white;
}

.skills-render {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 40px;
  top: 235px;
  width: 660px;
  min-height: 300px;
}

.skill-item-rendered {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.skill-item-rendered:last-child {
  border-bottom: none;
}

.skill-image-render {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 110px;
  height: 110px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px;
  background: black;
}
.skill-image-render .visual-render {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}

.skill-info-render {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 360px;
  padding: 0 0 0 15px;
}

.skill-info-title-render {
  position: relative;
  width: 100%;
  font-weight: 500;
  line-height: 1;
  color: white;
  font-size: 1.4rem;
  margin-bottom: 5px;
}

.skill-info-description-render {
  position: relative;
  width: 100%;
  color: #b3b3b3;
  line-height: 1.25;
  font-size: 0.9rem;
}

.social-render {
  position: absolute;
  display: inline;
  left: 0;
  padding-left: 40px;
  top: 725px;
  width: 640px;
  min-height: 20px;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.social-item-render {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  color: white;
  font-size: 1.3rem;
  padding-top: 15px;
  padding-bottom: 15px;
}
.social-item-render.twitch i {
  color: #9147FF;
}
.social-item-render.youtube i {
  color: #FF0000;
}
.social-item-render.discord i {
  color: #5865F2;
}

.gear-render {
  position: absolute;
  width: 100%;
  display: flex;
  top: 913px;
  height: 527px;
  background: black;
}
.gear-render.pyromancer {
  background: #0e0101;
  background: linear-gradient(180deg, #160202 0%, #2b0808 75%, #160202 25%);
}
.gear-render.technomancer {
  background: #030e01;
  background: linear-gradient(180deg, #040e01 0%, #0a2106 75%, #040e01 25%);
}
.gear-render.devastator {
  background: #0e0101;
  background: linear-gradient(180deg, #0e0101 0%, #39250c 75%, #0e0101 100%);
}
.gear-render.trickster {
  background: #010b0e;
  background: linear-gradient(180deg, #01090e 0%, #0c2b39 75%, #01090e 100%);
}

.gear-render-group {
  position: absolute;
  width: 100%;
  display: flex;
  top: -40px;
  left: 0;
  height: 100%;
}

.gear-item-render {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 12.5%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.7);
  margin-left: 5px;
  border-top: 3px solid transparent;
}
.gear-item-render:last-child {
  margin-right: 5px;
}
.gear-item-render .gear-item-render-title {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 100%;
  padding: 10px 15px 10px 15px;
  font-size: 1.6rem;
  line-height: 1;
  height: 70px;
  color: white;
  font-weight: 600;
  background: black;
}
.gear-item-render .gear-item-render-type {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 15px 5px 15px;
  font-size: 0.85rem;
  text-transform: uppercase;
  line-height: 1;
  height: auto;
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
  background: #172024;
}
.gear-item-render .gear-item-render-attribute {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 15px 5px 15px;
  font-size: 1.1rem;
  line-height: 1;
  height: auto;
  color: black;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.gear-item-render .gear-item-render-visual {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: 145px;
  background: black;
}
.gear-item-render .gear-item-render-visual-inside {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  top: 6px;
  left: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.gear-item-render .gear-mods-render {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 205px;
  flex: 0 0 auto;
  background: #c4c4c4;
  background: radial-gradient(circle, #c4c4c4 0%, #929292 100%);
  padding: 10px 5px 10px 15px;
}
.gear-item-render .gear-mods-render strong, .gear-item-render .gear-mods-render em {
  color: black;
}
.gear-item-render .gear-mod-render {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  padding-top: 5px;
  height: 50%;
}
.gear-item-render .gear-mod-icon-render {
  position: relative;
  display: flex;
  width: 65px;
  height: 65px;
  flex: 0 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
}
.gear-item-render .gear-mod-visual-render {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}
.gear-item-render .gear-mod-tier-render {
  position: absolute;
  width: 100%;
  color: white;
  font-weight: bold;
  top: -11px;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
  text-align: center;
  z-index: 3;
}
.gear-item-render .gear-mod-info-render {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.gear-item-render .gear-mod-info-render .cooldown {
  font-size: 0.8em;
  font-family: "Font Awesome 6 Pro";
}
.gear-item-render .gear-mod-title-render {
  color: black;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 2px;
}
.gear-item-render .gear-mod-description-render {
  color: black;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.15;
}
.gear-item-render.common {
  border-top: 3px solid #9e9e9e;
}
.gear-item-render.common .gear-item-render-visual {
  background: #848485;
  background: linear-gradient(145deg, #6b6c6c 0%, #a5a5a6 50%, #6b6c6c 100%);
}
.gear-item-render.unusual {
  border-top: 3px solid #88977e;
}
.gear-item-render.unusual .gear-item-render-visual {
  background: #6F7D65;
  background: linear-gradient(145deg, #415136 0%, #829670 50%, #415136 100%);
}
.gear-item-render.rare {
  border-top: 3px solid #8497a9;
}
.gear-item-render.rare .gear-item-render-visual {
  background: #677E93;
  background: linear-gradient(145deg, #3a4e5e 0%, #6d8fa7 50%, #3a4e5e 100%);
}
.gear-item-render.epic {
  border-top: 3px solid #888297;
}
.gear-item-render.epic .gear-item-render-visual {
  background: #6E687E;
  background: linear-gradient(145deg, #515073 0%, #877699 50%, #515073 100%);
}
.gear-item-render.legendary {
  border-top: 3px solid #9c7d4d;
}
.gear-item-render.legendary .gear-item-render-visual {
  background: #7A623C;
  background: linear-gradient(145deg, #5a4117 0%, #9b7b3e 50%, #5a4117 100%);
}

.footer-render {
  position: absolute;
  width: 100%;
  min-height: 10px;
  bottom: 5px;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 15px 5px 15px;
  background: black;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.build-controls {
  position: relative;
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding: 5px;
  background: #13171B;
  border: 1px solid #313941;
  z-index: 5;
  margin-top: -1px;
}

.build-control-delete {
  position: relative;
  display: flex;
  margin-right: 5px;
  font-size: 1.2rem;
  padding: 5px;
  margin-left: auto;
  color: #cc0000;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.build-control-delete:hover {
  color: red;
}

.build-control-duplicate {
  position: relative;
  display: flex;
  margin-right: 5px;
  font-size: 1.2rem;
  padding: 5px;
  transition: all 0.3s ease-out;
  color: #dbaa43;
  cursor: pointer;
}
.build-control-duplicate:hover {
  color: white;
}

.explorer-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.explorer-container.active {
  height: 100%;
  opacity: 1;
}

.explorer-content {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1140px;
  height: 100%;
  margin: 0 auto;
  flex-direction: column;
  overflow: hidden;
}

.explorer-right-controls {
  position: relative;
  display: flex;
  margin-left: auto;
}

.explorer-controls {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
}

.explorer-headline {
  position: absolute;
  width: calc(100% - 10px);
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex: 1 1 auto;
  margin-right: 5px;
  margin-left: 5px;
}
.explorer-headline.relative {
  display: flex;
  width: 100%;
  position: relative;
  height: auto;
  flex: 0 0 auto;
}

.explorer-carousel {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  height: 100%;
}

.explorer-sectionals {
  position: relative;
  display: none;
  width: 100%;
  flex: 1 1 auto;
  height: 100%;
  padding-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}
.explorer-sectionals.active {
  display: flex;
}
.explorer-sectionals .os-host {
  width: 100%;
  height: 100%;
}

.explorer-navigator {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 130px;
  bottom: 0;
  left: 0;
  margin: 0 auto;
}

.explorer-menu-tab {
  position: relative;
  display: flex;
  width: 200px;
  height: inherit;
  margin: 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
  background: linear-gradient(0deg, #0f1216 26%, rgba(15, 18, 22, 0) 100%);
  z-index: 3;
}

.explorer-menu-tab-info {
  position: absolute;
  width: inherit;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 1;
  text-align: center;
}
.explorer-menu-tab-info .headline {
  position: relative;
  display: flex;
  width: 100%;
  font-weight: 500;
  line-height: 1;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
}
.explorer-menu-tab-info .label {
  position: relative;
  line-height: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.65);
  text-transform: uppercase;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
}

.inner-content-explorer-tab {
  position: relative;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #272A2E;
  width: 100%;
  margin-top: -10px;
  padding-bottom: 6px;
  z-index: 1;
}
.inner-content-explorer-tab:hover .explorer-menu-tab-info .label {
  color: white;
}

.explorer-menu-header {
  position: relative;
  display: flex;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.explorer-menu-header-left {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  height: inherit;
  justify-content: flex-end;
  background: #0F1216;
  width: calc(50% - 150px);
  border-top: 1px solid #272A2E;
}

.explorer-menu-header-right {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: inherit;
  justify-content: flex-start;
  background: #0F1216;
  width: calc(50% - 150px);
  border-top: 1px solid #272A2E;
}

.explorer-menu-content {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  height: auto;
  padding-bottom: 10px;
  background: #0F1216;
}

.bottom-explorer-btn {
  position: relative;
  display: flex;
  color: #dbaa43;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 35px;
  height: 35px;
  padding-top: 5px;
}
.bottom-explorer-btn.left {
  margin-left: auto;
  margin-right: 10px;
}
.bottom-explorer-btn.right {
  margin-right: auto;
  margin-left: 10px;
}

.explore-menu-carousel {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding-top: 5px;
  max-width: 1140px;
}

.set-container-menu {
  position: relative;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-bottom: 0;
  align-items: center;
  justify-content: center;
  color: white;
}
.set-container-menu .explorer-sectional-item {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  background: black;
  margin-bottom: 5px;
  flex: 0 0 auto;
}
.set-container-menu .explorer-sectional-item .inside-visual {
  position: relative;
  display: flex;
  width: 100%;
  position: relative;
}
.set-container-menu .explorer-sectional-item .inside-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.set-container-menu .explorer-sectional-item .inside-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.set-container-menu .explorer-sectional-item .inside-visual .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  box-shadow: inset 0 0 0 2px #47535e;
}
.set-container-menu .explorer-sectional-item .inside-visual .content.sharpen {
  opacity: 0.5;
  filter: url(#Sharpen);
}
.set-container-menu .explorer-item-label {
  text-align: center;
  font-size: 0.65rem;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  transition: all 0.3s ease-out;
}
.set-container-menu:hover .explorer-item-label {
  color: white;
}
.set-container-menu:hover .explorer-sectional-item .inside-visual {
  position: relative;
  display: flex;
  flex: 1 1 auto;
}
.set-container-menu:hover .explorer-sectional-item .inside-visual .content {
  box-shadow: inset 0 0 0 2px white;
}
.set-container-menu.selected .explorer-sectional-item .explorer-item-label {
  color: #dbaa43;
}
.set-container-menu.selected .explorer-sectional-item .inside-visual .content {
  box-shadow: inset 0 0 0 3px #dbaa43;
}

.slick-slider {
  width: 100%;
  height: 100%;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
}

.slick-list,
.slick-track {
  height: 100%;
}

.slick-slide > div:first-child {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.explorer-sections {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.explorer-section-carousel {
  position: relative;
  display: flex;
  width: auto;
  height: 100%;
}

.explorer-section {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.explorer-label-headline {
  position: relative;
  display: flex;
  font-size: 0.65rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
  flex: 0 0 auto;
  margin-bottom: 5px;
}
.explorer-label-headline .text-label {
  position: relative;
  display: flex;
  align-self: flex-start;
  width: auto;
  flex: 0 0 auto;
  line-height: 1;
}

.explorer-label-title {
  position: relative;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  flex: 0 0 auto;
  max-width: 50%;
}
.explorer-label-title .text-label {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  line-height: 1;
}

.armory-pieces {
  position: relative;
  display: flex;
  width: 45%;
  flex: 0 0 auto;
  max-width: 100%;
  flex-wrap: wrap;
  margin-top: 5px;
}

.explorer-label-default {
  position: relative;
  display: flex;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: capitalize;
  color: white;
  flex: 0 0 auto;
  text-transform: capitalize;
  max-width: 100%;
}
.explorer-label-default .text-label {
  position: relative;
  width: 100%;
  line-height: 1;
}
.explorer-label-default .text-label strong {
  color: white;
}
.explorer-label-default .text-label em {
  color: white;
}
.explorer-label-default .text-label.sm {
  font-size: 0.7rem;
  text-transform: none;
  font-weight: 500;
  margin-top: 5px;
  line-height: 1.2;
}
.explorer-label-default .text-label.sm.bonus {
  width: 48%;
}
.explorer-label-default .text-label.xs {
  display: block;
  font-size: 0.65rem;
  width: 100%;
  max-width: 40%;
  text-transform: none;
  font-weight: 500;
  flex-wrap: wrap;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.2;
}
.explorer-label-default .text-label ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.explorer-label-default .text-label ul li {
  padding: 0;
  margin: 0;
}
.explorer-label-default .text-label.unusual {
  color: #6F7D65;
}
.explorer-label-default .text-label.rare {
  color: #677E93;
}
.explorer-label-default .text-label.epic {
  color: #6E687E;
}
.explorer-label-default .text-label.legendary {
  color: #7A623C;
}

.connector-line {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  height: inherit;
  align-items: center;
  padding-left: 10px;
}
.connector-line .line {
  position: relative;
  display: flex;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.connector-line .line.dashed {
  border-style: dashed;
}

.weapon-lines {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  height: auto;
  align-items: center;
}
.weapon-lines .line {
  position: relative;
  display: flex;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.weapon-lines .line.dashed {
  border-style: dashed;
}

.explorer-sets-header {
  position: relative;
  display: flex;
  color: #313941;
  width: 50%;
  align-items: center;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 0.8;
  flex: 0 0 auto;
  letter-spacing: -0.05rem;
}

.explorer-sets-pattern {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: auto;
  height: inherit;
  justify-content: flex-start;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  overflow: hidden;
}
.explorer-sets-pattern .slant {
  position: relative;
  margin-right: 10px;
  display: flex;
  flex: 0 0 auto;
  width: 10px;
  height: 100%;
  transform: skewX(-20deg);
  background: rgba(255, 255, 255, 0.1);
}
.explorer-sets-pattern.left {
  justify-content: flex-end;
}

.os-content {
  position: relative;
}

.set-container {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: white;
}
.set-container.full {
  height: 100%;
  align-items: center;
}
.set-container.scroll-section {
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  flex-direction: row;
  padding-top: 10px;
  height: 100%;
}
.set-container.scroll-section .os-host {
  width: 100%;
  height: 100%;
}
.set-container.weapons {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
}

.gradient-background {
  position: absolute;
  right: 0;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, rgba(103, 126, 147, 0.2) 0%, rgba(103, 126, 147, 0) 55%);
  background-position: center center;
  background-repeat: no-repeat;
}

.explorer-stats {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
  min-height: 100%;
}
.explorer-stats .spacer {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: 10px;
}

.explorer-stats-controls {
  position: relative;
  display: flex;
  width: 30px;
  flex: 0 0 auto;
  align-self: flex-start;
  align-items: flex-start;
  height: 100%;
  margin-top: 5px;
}

.explorer-stats-controls-side {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto;
  justify-content: center;
}
.explorer-stats-controls-side .stat-action {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-out;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.explorer-stats-controls-side .stat-action:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.explorer-stats-controls-side .stat-action > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.explorer-stats-controls-side .stat-action .content {
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.explorer-stats-controls-side .stat-action .content img {
  opacity: 0.5;
  height: 70%;
  width: auto;
  transition: opacity 0.3s ease-out;
}
.explorer-stats-controls-side .stat-action:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.explorer-stats-controls-side .stat-action:hover {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.explorer-stats-controls-side .stat-action:hover .content img {
  opacity: 1;
}

.explorer-stats-details {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: flex-start;
  padding-left: 10px;
  flex: 1 1 auto;
  overflow-y: hidden;
}

.explorer-details-header {
  display: none;
}

.explorer-figure {
  position: absolute;
  display: flex;
  right: 35px;
  flex: 0 0 auto;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  width: calc(100% - 50%);
  top: 0%;
  height: calc(100% - 5px);
  padding-left: 0;
  padding-right: 0;
  align-items: center;
  max-height: 800px;
}
.explorer-figure.expanded {
  height: 100%;
}

.character-visual {
  position: relative;
  display: flex;
  height: 100%;
  flex: 0 0 auto;
  width: 100%;
  max-height: 730px;
  margin-left: 0;
}
.character-visual .content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: none;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.character-visual .content.sharpen {
  opacity: 0.3;
  filter: url(#Sharpen);
}

.explorer-rarity-container {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 111px;
  height: 100%;
  margin-left: 5px;
  margin-right: 35px;
}
.explorer-rarity-container select {
  height: 30px;
}
.explorer-rarity-container .edd-root {
  height: 30px;
}
.explorer-rarity-container .edd-root .edd-head {
  height: 30px;
}
.explorer-rarity-container .edd-root .edd-head .edd-value {
  line-height: 24px;
}
.explorer-rarity-container.weapons {
  width: 30%;
}

.explorer-search-container {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  height: 30px;
  align-items: center;
  flex-direction: row;
  margin-left: 35px;
}

.explorer-search-input {
  width: 100%;
  height: 30px;
  padding-right: 34px;
}

.weapon-stats {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 720px;
  height: 100%;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0;
}

.weapon-firepower {
  position: absolute;
  left: 10px;
  top: -25px;
  width: auto;
  border-radius: 2px;
  font-weight: 600;
  padding: 3px;
  background: #dbaa43;
  color: black;
  font-size: 0.85rem;
}

.weapon-visual {
  position: relative;
  display: flex;
  height: inherit;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 20px 0 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}
.weapon-visual .content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: none;
  background-size: 80%;
  background-position: center center;
  background-repeat: no-repeat;
}
.weapon-visual .content.sharpen {
  opacity: 0.8;
  filter: url(#Sharpen);
}
.weapon-visual-with-stats {
  position: relative;
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
}

.weapon-info {
  position: relative;
  display: flex;
  height: 100%;
  flex: 1 1 auto;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  width: 100%;
  flex-direction: column;
}

.weapon-details {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
}

.weapon-basic {
  position: relative;
  display: flex;
  width: 55%;
  flex: 0 0 auto;
  padding-right: 5px;
  padding-left: 10px;
  flex-direction: column;
}

.weapon-attributes {
  position: relative;
  display: flex;
  width: 45%;
  flex: 0 0 auto;
  text-transform: uppercase;
  padding-right: 0;
  font-size: 0.65rem;
  font-weight: 600;
  margin-top: 3px;
}
.weapon-attributes ul {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
.weapon-attributes ul li {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  line-height: 1.4;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  color: white;
  border-bottom: none;
}
.weapon-attributes ul li:before {
  display: inline-block;
  content: "";
  color: white;
  font-size: 0.9em;
  vertical-align: middle;
  font-family: "Font Awesome Kit";
  width: 1em;
  margin-right: 0.3em;
  margin-left: -1em;
}

.variant-stats-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 0 0 auto;
}
.variant-stats-container.horizontal {
  display: none;
}

.weapon-variant-stat {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: auto;
  flex-direction: column;
  padding: 5px;
  background: rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}
.weapon-variant-stat:last-child {
  border-right: none;
}

.weapon-variant-value {
  position: relative;
  display: block;
  font-size: 1rem;
  color: white;
  line-height: 1;
}

.weapon-variant-label {
  position: relative;
  display: block;
  font-size: 0.65rem;
  line-height: 1;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}

.weapon-variant-stats {
  position: relative;
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin-top: 10px;
}
.weapon-variant-stats.accuracy {
  margin-top: -1px;
}
.weapon-variant-stats.accuracy .weapon-variant-value {
  font-size: 0.8rem;
  text-transform: uppercase;
}
.weapon-variant-stats.accuracy .weapon-variant-label {
  font-size: 0.6rem;
}

.weapon-title {
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
  color: white;
}

.weapon-type {
  width: 100%;
  text-align: left;
  font-size: 0.7rem;
  text-transform: uppercase;
  line-height: 1;
  color: rgba(255, 255, 255, 0.7);
}

.weapon-variant {
  width: 100%;
  text-align: left;
  font-size: 0.7rem;
  text-transform: uppercase;
  line-height: 1;
  color: rgba(255, 255, 255, 0.7);
}

.weapon-stats-info {
  position: relative;
  display: flex;
  width: 100%;
  font-size: 0.8rem;
  line-height: 1;
  margin-top: 15px;
  padding-left: 10px;
  flex-direction: column;
}
.weapon-stats-info .weapon-mods {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  flex-direction: row;
}

.weapon-mod-item {
  position: relative;
  display: flex;
  width: 50%;
  padding-right: 5px;
}
.weapon-mod-item:first-child {
  margin-bottom: 0;
}

.weapon-mod-icon {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  height: auto;
  align-self: flex-start;
  width: 45px;
  position: relative;
}
.weapon-mod-icon:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.weapon-mod-icon > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.weapon-mod-icon .content {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.weapon-mod-icon .content .mod-tier {
  position: absolute;
  display: inline-block;
  top: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
  z-index: 3;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black;
}
.weapon-mod-icon .content .visual {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  background: black;
  box-shadow: inset 0 0 0 1px #313941;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}
.weapon-mod-icon .content .visual.sharpen {
  filter: url("#Sharpen");
  opacity: 0.7;
}

.weapon-mod-information {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  padding-left: 10px;
  width: auto;
  justify-content: center;
  flex-direction: column;
}

.weapon-mod-title {
  position: relative;
  width: 100%;
  line-height: 1.1;
  font-size: 0.85rem;
  font-weight: 600;
  color: white;
  margin-bottom: 3px;
  margin-top: -1px;
}

.weapon-mod-description {
  position: relative;
  display: none;
  line-height: 1.1;
  width: 100%;
  font-size: 0.65rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}
.weapon-mod-description em, .weapon-mod-description strong {
  color: white;
}
.weapon-mod-description .cooldown {
  font-size: 0.9em;
  font-family: "Font Awesome 6 Pro";
}

.weapon-header {
  position: relative;
  display: none;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 15px;
}

.weapon-info-headline {
  position: relative;
  display: flex;
  width: 100%;
}

.weapon-header-section-title {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  font-size: 1.3rem;
  font-weight: bold;
  color: #313941;
  text-transform: uppercase;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;
  line-height: 0.8;
  letter-spacing: -0.05rem;
}

.podcast-headline {
  position: relative;
  display: flex;
}

.podcast-section-title {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  font-size: 2.3rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  margin-right: 10px;
  margin-left: -3px;
  align-items: center;
  line-height: 0.8;
  letter-spacing: -0.05rem;
}

.podcast-hosts {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 10px;
}

.podcast-episodes-description {
  position: relative;
  display: block;
  width: 100%;
  font-size: 0.85rem;
  padding: 10px 20px 0 0;
  color: rgba(255, 255, 255, 0.5);
  flex-wrap: wrap;
}

.podcast-host {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  padding: 8px;
  flex-direction: column;
  width: 33%;
}

.podcast-host-visual {
  position: relative;
  display: flex;
  width: 100%;
  background: black;
  box-shadow: inset 0 0 0 0 white;
  border-radius: 10px;
  flex: 0 0 auto;
  position: relative;
  transition: all 0.3s ease-out;
  cursor: pointer;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.podcast-host-visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.podcast-host-visual > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.podcast-host-visual:hover {
  box-shadow: inset 0 0 0 4px white;
}

.podcast-host-text {
  position: relative;
  display: block;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.podcast-host-description {
  position: relative;
  display: none;
  font-size: 0.75rem;
  text-transform: initial;
  font-weight: 500;
  text-align: center;
}
.podcast-host-description i.twitch {
  color: #BE4BDB;
}
.podcast-host-description i.youtube {
  color: #FF0402;
}
.podcast-host-description i.twitter {
  color: #287CC9;
}
.podcast-host-description.twitch {
  color: rgba(255, 255, 255, 0.7);
}
.podcast-host-description.twitch a {
  text-decoration: none;
  color: inherit;
}
.podcast-host-description.twitch a:hover {
  color: white;
}
.podcast-host-description.youtube {
  color: rgba(255, 255, 255, 0.7);
}
.podcast-host-description.youtube a {
  text-decoration: none;
  color: inherit;
}
.podcast-host-description.youtube a:hover {
  color: white;
}
.podcast-host-description.twitter {
  color: rgba(255, 255, 255, 0.7);
}
.podcast-host-description.twitter a {
  text-decoration: none;
  color: inherit;
}
.podcast-host-description.twitter a:hover {
  color: white;
}

.explorer-scrollable {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 20px;
  font-size: 1rem;
  line-height: 1.25;
  color: white;
  font-weight: 500;
}
.explorer-scrollable p {
  font-size: 0.85rem;
  font-weight: normal;
}
.explorer-scrollable .explorer-sets-pattern {
  height: 1.8rem;
}

.podcast-episode {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 15px;
  padding-bottom: 15px;
}
.podcast-episode:first-child {
  padding-top: 0;
}

.podcast-episode-title {
  position: relative;
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #dbaa43;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
}

.podcast-episode-description {
  position: relative;
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.2;
  margin-bottom: 10px;
}

.podcast-audio {
  position: relative;
  display: block;
  width: 100%;
}
.podcast-audio audio {
  width: 100%;
}

@media (min-height: 600px) {
  .explorer-figure {
    top: 0;
    transform: translateY(0);
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 40%;
  }

  .armory-item.explorer-armor {
    width: 35px;
  }
  .armory-item.explorer-armor.upper {
    width: 50px;
  }
  .armory-item.explorer-armor.footgear {
    width: 40px;
  }

  .weapon-mod-icon {
    width: 55px;
  }

  .weapon-visual {
    height: 100%;
  }

  .weapon-title {
    font-size: 1.6rem;
  }
}
@media (min-height: 730px) {
  .explorer-stats {
    padding-bottom: 0;
    align-items: flex-start;
  }

  .explorer-stats-details {
    align-self: flex-start;
  }

  .explorer-label-title {
    font-size: 1.2rem;
  }

  .armory-item.explorer-armor {
    width: 40px;
  }
  .armory-item.explorer-armor.upper {
    width: 55px;
  }
  .armory-item.explorer-armor.footgear {
    width: 40px;
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 39%;
  }

  .character-visual .content {
    height: 100%;
  }

  .armory-pieces {
    width: 40%;
  }

  .weapon-mod-information {
    justify-content: flex-start;
  }

  .weapon-mod-description {
    display: block;
  }
}
@media (min-height: 845px) {
  .explorer-navigator {
    position: relative;
  }
}
@media (min-width: 375px) {
  #mobile-detect {
    content: "mq-mobile";
  }

  #breakpoint-detect {
    content: "375";
  }

  .podcast-host {
    width: 25%;
  }

  .segment-header-visual {
    position: relative;
  }
  .segment-header-visual:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 18.75%;
  }
  .segment-header-visual > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .explorer-scrollable {
    padding-left: 20px;
  }

  .explorer-menu-bounds {
    height: 100px;
  }

  .nav-item .headline {
    font-size: 0.7rem;
  }
  .nav-item .label {
    font-size: 1.2rem;
  }

  .explorer-stats-details {
    align-self: flex-start;
  }

  .armory-pieces {
    width: 39%;
  }

  .explorer-label-title {
    font-size: 1.4rem;
  }

  .armory-item {
    width: 33.3%;
  }
  .armory-item.explorer-armor {
    width: 35px;
    margin-bottom: 5px;
  }
  .armory-item.explorer-armor.upper {
    width: 45px;
  }
  .armory-item.explorer-armor.footgear {
    width: 40px;
  }

  .mod-item {
    width: 25%;
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 40%;
  }

  .explorer-stats {
    padding-left: 15px;
    padding-top: 0;
  }
  .explorer-stats .spacer {
    height: 7px;
  }

  .explorer-stats-controls {
    width: 35px;
  }

  .explorer-figure {
    right: 40px;
    top: 0;
    transform: translateX(0);
  }

  .explore-menu-carousel {
    margin-bottom: 10px;
  }

  .explorer-navigator {
    height: 145px;
  }
}
@media (min-width: 375px) and (min-height: 667px) {
  .weapon-mod-information {
    justify-content: flex-start;
  }

  .weapon-mod-description {
    display: block;
  }
}
@media (min-width: 375px) and (min-height: 800px) {
  .armory-item.explorer-armor {
    width: 45px;
  }
  .armory-item.explorer-armor.upper {
    width: 60px;
  }
  .armory-item.explorer-armor.footgear {
    width: 45px;
  }

  .explorer-stats-details {
    padding-top: 15px;
  }

  .explorer-navigator {
    position: relative;
  }

  .character-visual .content {
    height: 100%;
  }

  .armory-pieces {
    width: 35%;
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 38%;
  }
}
@media (min-width: 375px) {
  .weapon-stats {
    flex-direction: column;
    justify-content: center;
  }
}
@media (min-width: 375px) {
  .weapon-attributes {
    font-size: 0.7rem;
  }
}
@media (min-width: 375px) {
  .weapon-variant-value {
    font-size: 1rem;
  }
}
@media (min-width: 375px) {
  .weapon-variant-label {
    font-size: 0.65rem;
  }
}
@media (min-width: 375px) {
  .weapon-variant-stats.accuracy .weapon-variant-value {
    font-size: 0.8rem;
  }
  .weapon-variant-stats.accuracy .weapon-variant-label {
    font-size: 0.6rem;
  }
}
@media (min-width: 375px) {
  .weapon-title {
    font-size: 1.4rem;
    line-height: 1;
  }
}
@media (min-width: 375px) {
  .weapon-type {
    font-size: 0.7rem;
  }
}
@media (min-width: 375px) {
  .weapon-variant {
    font-size: 0.7rem;
  }
}
@media (min-width: 375px) {
  .weapon-mod-icon {
    width: 45px;
  }
  .weapon-mod-icon .content .mod-tier {
    font-size: 0.75rem;
  }
}
@media (min-width: 375px) {
  .weapon-mod-title {
    font-size: 0.85rem;
  }
}
@media (min-width: 375px) {
  .weapon-mod-description {
    font-size: 0.65rem;
  }
}
@media (min-width: 375px) {
  .weapon-info {
    height: auto;
  }
}
@media (min-width: 375px) {
  .weapon-stats-info {
    font-size: 0.8rem;
  }
}
@media (min-width: 411px) {
  .explorer-figure {
    top: 0;
    transform: translateY(0);
  }

  .explorer-menu-bounds {
    height: 90px;
  }

  .armory-pieces {
    width: 40%;
    margin-top: 5px;
  }

  .explorer-stats {
    align-items: center;
    padding-top: 10px;
  }
  .explorer-stats .spacer {
    height: 15px;
  }

  .explorer-stats {
    padding-top: 5px;
  }

  .explorer-stats-details {
    align-self: flex-start;
  }

  .explorer-label-default {
    font-size: 1.1rem;
  }
  .explorer-label-default .text-label.sm {
    font-size: 0.75rem;
  }
  .explorer-label-default .text-label.sm.bonus {
    width: 40%;
    line-height: 1.2;
  }

  .armory-item.explorer-armor {
    padding: 0;
  }
}
@media (min-width: 411px) and (min-height: 730px) {
  .explorer-menu-bounds {
    height: 91px;
  }
}
@media (min-width: 411px) and (min-height: 411px) and (min-height: 736px) {
  .explorer-menu-bounds {
    height: 91px;
  }

  .explorer-navigator {
    position: relative;
  }
}
@media (min-width: 411px) and (min-height: 800px) {
  .explorer-figure {
    top: 0;
    transform: translateY(0);
  }

  .explorer-navigator {
    position: relative;
  }
}
@media (min-width: 411px) and (min-height: 850px) {
  .explorer-navigator {
    position: relative;
  }

  .explorer-figure {
    top: 0;
    width: calc(100% - 48%);
    transform: translateY(0);
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 38%;
  }
}
@media (min-width: 480px) {
  .header-bounds {
    height: 177px;
  }

  .header-inner-bounds {
    height: 28px;
  }

  .header-visual {
    height: 100px;
    background-image: url("../../images/header-visual.png");
  }

  .header-visual-bg {
    height: 150px;
  }

  .header-container {
    margin-top: -79px;
  }

  .corner {
    width: 34px;
    height: 100%;
  }

  .header-logo-container {
    width: 100%;
    height: 30px;
    margin-bottom: -1px;
  }
  .header-logo-container.flipped .corner {
    width: 34px;
  }

  .logo-container {
    bottom: 11px;
    width: 50%;
    text-align: initial;
  }
  .logo-container img {
    width: 100%;
  }
  .logo-container .slogan {
    font-size: 0.75rem;
  }

  .header-nav-bar {
    height: 20px;
  }

  .header-nav {
    width: 100%;
    left: initial;
    transform: translateX(0);
  }

  .nav-item .headline {
    font-size: 0.8rem;
  }
  .nav-item .label {
    font-size: 1.4rem;
  }

  .guides-tab-container {
    width: 200px;
  }

  .guides-btn .headline {
    font-size: 0.8rem;
  }
  .guides-btn .label {
    font-size: 1.4rem;
  }

  .segment-header-container.explorer {
    padding-top: 5px;
  }

  .explorer-icons {
    height: 210%;
  }

  .explorer-figure {
    width: calc(100% - 45%);
  }

  .explorer-label-title {
    font-size: 1.5rem;
  }

  .explorer-label-headline {
    font-size: 0.75rem;
  }

  .explorer-label-default {
    font-size: 1rem;
  }
}
@media (min-width: 576px) {
  .header-bounds {
    height: 178px;
  }

  .header-inner-bounds {
    height: 28px;
  }

  .podcast-host {
    width: 20%;
  }

  .podcast-hosts {
    justify-content: center;
  }

  .segment-header-visual {
    position: relative;
  }
  .segment-header-visual:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 18.75%;
  }
  .segment-header-visual > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .header-visual {
    height: 100px;
    background-image: url("../../images/header-visual.png");
  }

  .header-visual-bg {
    height: 150px;
  }

  .header-container {
    margin-top: -79px;
  }

  .corner {
    width: 34px;
    height: 100%;
  }

  .header-logo-container {
    width: 500px;
    height: 30px;
    margin-bottom: -1px;
  }
  .header-logo-container.flipped .corner {
    width: 34px;
  }

  .logo-container {
    bottom: -5px;
    width: 60%;
    text-align: initial;
  }
  .logo-container img {
    width: 100%;
  }
  .logo-container .slogan {
    font-size: 0.7rem;
  }

  .header-nav-bar {
    height: 50px;
  }

  .header-nav {
    width: 100%;
    left: initial;
    transform: translateX(0);
  }

  .segment-header {
    max-width: 520px;
  }

  .segment-content-container {
    max-width: 480px;
  }

  .armory-item {
    width: 33.3%;
  }

  .mod-item {
    width: 25%;
  }

  .explorer-stats-controls {
    width: 50px;
  }

  .nav-item {
    padding: 10px;
    width: initial;
  }
  .nav-item .headline {
    font-size: 0.9rem;
  }
  .nav-item .label {
    font-size: 1.5rem;
  }

  .build-creator-content {
    max-width: 520px;
  }

  .build-class-chooser {
    padding-top: 10px;
  }

  .armor-item, .weapon-item {
    width: 50%;
  }

  .class-chooser {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 15px !important;
  }

  .explorer-icons {
    height: 185%;
    bottom: 29px;
  }

  .explorer-figure {
    width: calc(100% - 45%);
    right: 55px;
  }

  .explorer-label-title {
    font-size: 1.8rem;
  }

  .explorer-label-headline {
    font-size: 0.85rem;
  }

  .explorer-label-default {
    font-size: 1.2rem;
  }
  .explorer-label-default .text-label.sm {
    font-size: 0.95rem;
  }
  .explorer-label-default .text-label.sm.bonus {
    width: 42%;
  }

  .explorer-navigator {
    position: absolute;
  }
}
@media (min-width: 576px) and (min-height: 760px) {
  .explorer-navigator {
    position: relative;
  }
}
@media (min-width: 600px) {
  .segment-header {
    max-width: 556px;
  }

  .segment-content-container {
    max-width: 480px;
  }

  .explorer-sectionals {
    max-width: 576px;
    margin: 0 auto;
  }

  .armory-item.explorer-armor {
    width: 55px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .armory-item.explorer-armor.upper {
    width: 70px;
  }
  .armory-item.explorer-armor.footgear {
    width: 70px;
  }

  .character-module {
    width: 30%;
  }

  .character-image-column {
    height: 380px;
  }

  .build-creator-content {
    max-width: 556px;
  }

  .build-meta {
    width: 70%;
    flex: 1 1 auto;
    margin-bottom: 10px;
  }

  .build-class-chooser {
    width: 100%;
  }

  .class-icon {
    width: 15%;
  }

  .class-chooser {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px !important;
  }

  .skill-icon {
    width: 12.5%;
  }

  .explorer-figure {
    height: 57vh;
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 40%;
  }
}
@media (min-width: 600px) and (min-height: 960px) {
  .weapon-title {
    font-size: 2.2rem;
  }

  .weapon-attributes {
    font-size: 1rem;
  }

  .weapon-variant-stat {
    padding: 10px;
  }

  .weapon-variant-value {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .variant-stats-container {
    margin-top: 10px;
  }

  .weapon-variant-label {
    font-size: 0.8rem;
  }

  .weapon-variant-stats.accuracy .weapon-variant-value {
    font-size: 1rem;
    font-weight: 600;
  }
  .weapon-variant-stats.accuracy .weapon-variant-label {
    font-size: 0.8rem;
  }

  .weapon-mod-icon {
    width: 75px;
  }
  .weapon-mod-icon .mod-tier {
    font-size: 0.85rem;
  }

  .weapon-type {
    font-size: 1rem;
  }

  .weapon-variant {
    font-size: 1rem;
  }

  .weapon-stats-info {
    margin-top: 30px;
  }

  .weapon-mod-information {
    justify-content: flex-start;
    padding-right: 10px;
  }

  .weapon-mod-title {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .weapon-mod-description {
    display: block;
    font-size: 0.85rem;
    line-height: 1.2;
  }
}
@media (min-width: 640px) {
  .segment-header {
    max-width: 576px;
  }

  .segment-content-container {
    max-width: 480px;
  }

  .explorer-sectionals {
    max-width: 576px;
    margin: 0 auto;
  }

  .character-module {
    width: 30%;
  }

  .character-image-column {
    height: 380px;
  }

  .build-creator-content {
    max-width: 576px;
  }

  .build-meta {
    width: 70%;
    flex: 1 1 auto;
    margin-bottom: 10px;
  }

  .build-class-chooser {
    width: 100%;
  }

  .class-icon {
    width: 15%;
  }

  .class-chooser {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px !important;
  }

  .skill-icon {
    width: 12.5%;
  }

  .armory-item.explorer-armor {
    width: 55px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .armory-item.explorer-armor.upper {
    width: 70px;
  }
  .armory-item.explorer-armor.footgear {
    width: 70px;
  }

  .explorer-figure {
    height: 57vh;
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 40%;
  }

  .field-label.segment-divider .label-text {
    font-size: 1rem;
  }
}
@media (min-width: 720px) {
  .segment-header {
    max-width: 685px;
  }

  .segment-content-container {
    max-width: 576px;
  }

  .explorer-sectionals {
    max-width: 685px;
    margin: 0 auto;
  }

  .build-creator-content {
    max-width: 1140px;
  }

  .podcast-section-title {
    font-size: 2.8rem;
  }

  .explorer-scrollable .explorer-sets-pattern {
    height: 2rem;
  }

  .explorer-scrollable p {
    font-size: 1rem;
  }

  .armory-item.explorer-armor {
    width: 55px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .armory-item.explorer-armor.upper {
    width: 80px;
    margin-top: -5px;
  }
  .armory-item.explorer-armor.footgear {
    width: 70px;
  }

  .explorer-menu-tab {
    width: 240px;
  }

  .explorer-navigator {
    height: 180px;
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 40%;
  }

  .explorer-details-header {
    display: flex;
    margin-bottom: 20px !important;
  }

  .explorer-sets-header {
    font-size: 2.4rem;
  }

  .build-item {
    width: 33.3%;
    height: 200px;
  }

  .set-container-menu .explorer-item-label {
    font-size: 0.75rem;
  }

  .character-module {
    width: 30%;
  }
  .character-module .field-label {
    margin-top: initial;
  }

  .character-image-column {
    height: 380px;
  }

  .build-meta {
    width: 70%;
    flex: 1 1 auto;
    margin-bottom: 10px;
  }

  .build-class-chooser {
    width: 100%;
  }

  .class-icon {
    width: 15%;
    margin-right: 20px;
  }

  .class-chooser {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px !important;
  }

  .skill-icon {
    width: 12.5%;
    padding: 10px;
  }
  .skill-icon .skill-name {
    font-size: 0.7rem;
  }
  .skill-icon .skill-type {
    font-size: 0.65rem;
  }
}
@media (min-width: 720px) and (min-height: 1000px) {
  .explorer-stats-details {
    margin-top: -10%;
    align-self: center;
  }

  .explorer-stats-controls {
    align-self: flex-start;
    margin-top: 10%;
  }

  .weapon-title {
    font-size: 2.2rem;
  }

  .weapon-attributes {
    font-size: 1rem;
  }

  .weapon-variant-stat {
    padding: 10px;
  }

  .weapon-variant-value {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .variant-stats-container {
    margin-top: 10px;
  }

  .weapon-variant-label {
    font-size: 0.8rem;
  }

  .weapon-variant-stats.accuracy .weapon-variant-value {
    font-size: 1rem;
    font-weight: 600;
  }
  .weapon-variant-stats.accuracy .weapon-variant-label {
    font-size: 0.8rem;
  }

  .weapon-mod-icon {
    width: 75px;
  }
  .weapon-mod-icon .mod-tier {
    font-size: 0.85rem;
  }

  .weapon-type {
    font-size: 1rem;
  }

  .weapon-variant {
    font-size: 1rem;
  }

  .weapon-stats-info {
    margin-top: 30px;
  }

  .weapon-mod-information {
    justify-content: flex-start;
    padding-right: 10px;
  }

  .weapon-mod-title {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .weapon-mod-description {
    display: block;
    font-size: 0.85rem;
    line-height: 1.2;
  }
}
@media (min-width: 768px) {
  .segment-header {
    max-width: 720px;
  }

  .podcast-hosts {
    justify-content: flex-start;
  }

  .podcast-host {
    width: 33.3%;
  }

  .explorer-scrollable {
    padding-top: 30px;
  }

  .segment-content-container {
    max-width: 576px;
  }

  .explorer-sectionals {
    max-width: 720px;
    margin: 0 auto;
  }

  .build-creator-content {
    max-width: 720px;
  }

  .armory-item.explorer-armor {
    width: 55px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .armory-item.explorer-armor.upper {
    width: 80px;
    margin-top: -5px;
  }
  .armory-item.explorer-armor.footgear {
    width: 70px;
  }

  .explorer-figure {
    height: 57vh;
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 40%;
  }

  .explorer-details-header {
    display: flex;
    margin-bottom: 20px !important;
  }

  .explorer-sets-header {
    position: relative;
    display: flex;
    color: #313941;
    width: 50%;
    align-items: center;
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 0.8;
    font-weight: bold;
    flex: 0 0 auto;
    letter-spacing: -0.05rem;
  }

  .logo-container {
    bottom: 12px;
    width: 50%;
  }

  .header-nav-bar {
    height: 30px;
  }

  .header-visual-bg {
    height: 130px;
  }

  .header-bounds {
    height: 158px;
  }

  .explorer-icons {
    height: 150%;
    width: 100%;
  }

  .explorer-label-title {
    max-width: 45%;
  }
}
@media (min-width: 800px) {
  .segment-header {
    max-width: 720px;
  }

  .segment-content-container {
    max-width: 576px;
  }

  .explorer-sectionals {
    max-width: 720px;
    margin: 0 auto;
  }

  .build-creator-content {
    max-width: 720px;
  }

  .armory-item.explorer-armor {
    width: 55px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .armory-item.explorer-armor.upper {
    width: 80px;
    margin-top: -5px;
  }
  .armory-item.explorer-armor.footgear {
    width: 70px;
  }

  .explorer-figure {
    height: 57vh;
  }

  .explorer-label-default .text-label.sm.bonus {
    width: 40%;
  }

  .explorer-details-header {
    display: flex;
    margin-bottom: 20px !important;
  }

  .explorer-sets-header {
    position: relative;
    display: flex;
    color: #313941;
    width: 50%;
    align-items: center;
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 0.8;
    font-weight: bold;
    flex: 0 0 auto;
    letter-spacing: -0.05rem;
  }
}
@media (min-width: 800px) and (min-height: 1000px) {
  .weapon-title {
    font-size: 2.2rem;
  }

  .weapon-attributes {
    font-size: 1rem;
  }

  .weapon-variant-stat {
    padding: 10px;
  }

  .weapon-variant-value {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .variant-stats-container {
    margin-top: 10px;
  }

  .weapon-variant-label {
    font-size: 0.8rem;
  }

  .weapon-variant-stats.accuracy .weapon-variant-value {
    font-size: 1rem;
    font-weight: 600;
  }
  .weapon-variant-stats.accuracy .weapon-variant-label {
    font-size: 0.8rem;
  }

  .weapon-mod-icon {
    width: 75px;
  }
  .weapon-mod-icon .mod-tier {
    font-size: 0.85rem;
  }

  .weapon-type {
    font-size: 1rem;
  }

  .weapon-variant {
    font-size: 1rem;
  }

  .weapon-stats-info {
    margin-top: 30px;
  }

  .weapon-mod-information {
    justify-content: flex-start;
    padding-right: 10px;
  }

  .weapon-mod-title {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .weapon-mod-description {
    display: block;
    font-size: 0.85rem;
    line-height: 1.2;
  }

  .weapon-visual .content.sharpen {
    opacity: 0.8;
    filter: url(#Sharpen);
  }
}
@media (min-width: 960px) {
  #mobile-detect {
    content: "mq-desktop";
  }

  #breakpoint-detect {
    content: "960";
  }

  .explorer-menu-bounds {
    height: 120px;
  }

  .explorer-navigator {
    height: 140px;
  }

  .podcast-episodes-description {
    font-size: 1rem;
  }

  .podcast-host {
    width: 25%;
  }

  .segment-header {
    max-width: 720px;
  }

  .segment-controls {
    margin-bottom: 0;
  }

  .segment-content-container {
    max-width: 576px;
  }

  .field-label.segment-divider .label-text {
    font-size: 0.85rem;
  }

  .explorer-stats-controls {
    margin-top: 6px;
  }

  .explorer-sectionals {
    max-width: 720px;
    margin: 0 auto;
  }

  .build-creator-content {
    max-width: 720px;
  }

  .armory-pieces {
    width: 50%;
  }

  .armory-item.explorer-armor {
    width: 45px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .armory-item.explorer-armor.upper {
    width: 60px;
    margin-top: -5px;
  }
  .armory-item.explorer-armor.footgear {
    width: 60px;
  }

  .explorer-label-default {
    font-size: 1.1rem;
  }
  .explorer-label-default .text-label.sm {
    font-size: 0.85rem;
  }
  .explorer-label-default .text-label.sm.bonus {
    width: 45%;
  }

  .explorer-details-header {
    display: none;
    margin-bottom: 20px !important;
  }

  .explorer-sets-header {
    position: relative;
    display: flex;
    color: #313941;
    width: 50%;
    align-items: center;
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 0.8;
    font-weight: bold;
    flex: 0 0 auto;
    letter-spacing: -0.05rem;
  }

  .skill-tree-container svg {
    height: auto;
    width: 100%;
  }

  .explorer-label-title {
    font-size: 1.5rem;
  }

  .armory-item {
    width: 25%;
  }

  .mod-item {
    width: 12.5%;
  }

  .guides-tab-container {
    position: relative;
    display: flex;
    width: 160px;
    height: 35px;
    margin: 0 auto;
    margin-bottom: -1px;
    z-index: 2;
    transition: all 0.3s ease-in-out;
  }
  .guides-tab-container .corner {
    width: 35px;
    height: 35px;
  }

  .guides-tab {
    position: relative;
    display: flex;
    width: 100%;
    height: 35px;
  }

  .guides-btn {
    position: absolute;
    width: inherit;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 10px 0 10px 0;
    line-height: 1;
    border-top: 2px solid transparent;
    cursor: pointer;
  }
  .guides-btn .headline {
    position: relative;
    display: flex;
    width: 100%;
    font-weight: 500;
    line-height: 1;
    justify-content: center;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    font-size: 0.7rem;
    transition: all 0.3s ease-in-out;
  }
  .guides-btn .label {
    position: relative;
    line-height: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
    text-transform: uppercase;
    font-size: 1.1rem;
    transition: all 0.3s ease-in-out;
  }
  .guides-btn:hover {
    border-top: 2px solid rgba(255, 255, 255, 0.3);
  }
  .guides-btn:hover .headline {
    color: rgba(255, 255, 255, 0.85);
  }
  .guides-btn:hover .label {
    color: white;
  }
  .guides-btn.active {
    border-top: 2px solid #dbaa43;
  }
  .guides-btn.active .headline {
    color: #dbaa43;
  }
  .guides-btn.active .label {
    color: white;
  }

  .guides-content {
    position: relative;
    display: flex;
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: transparent;
    background-image: none;
    background-size: cover;
    background-position: center top;
    background-repeat: repeat-x;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  .guides-content.show {
    background-color: #0F1216;
  }
  .guides-content.show.outlines {
    border-top: 1px solid #272A2E;
    border-bottom: 1px solid #272A2E;
  }

  .explorer-sectionals {
    max-width: 870px;
  }

  .segment-header {
    max-width: 1140px;
  }

  .weapon-stats {
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    max-width: 870px;
  }

  .weapon-visual-with-stats {
    width: 60%;
    justify-content: flex-start;
    height: 100%;
  }

  .weapon-visual {
    width: 100%;
    height: 65%;
  }
  .weapon-visual .content {
    background-size: 100%;
  }
  .weapon-info {
    width: 40%;
    justify-content: center;
  }

  .weapon-details {
    flex-direction: column;
    padding-top: 25px;
  }

  .weapon-title {
    font-size: 2.3rem;
  }

  .weapon-attributes {
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .weapon-attributes ul li {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .weapon-stats-info {
    padding-right: 20px;
  }
  .weapon-stats-info .weapon-mods {
    flex-direction: column;
  }
  .weapon-stats-info .weapon-mods .weapon-mod-item {
    margin-bottom: 20px;
    width: 100%;
  }
  .weapon-stats-info .weapon-mods .weapon-mod-item .weapon-mod-information {
    width: 100%;
    justify-content: flex-start;
  }
  .weapon-stats-info .weapon-mods .weapon-mod-item .weapon-mod-icon {
    width: 60px;
  }

  .weapon-mod-description {
    display: initial;
    width: 100%;
    font-size: 0.8rem;
    line-height: 1.15;
  }
  .weapon-mod-description .cooldown {
    font-size: 0.8em;
    font-family: "Font Awesome 6 Pro";
  }

  .weapon-basic {
    width: 100%;
  }

  .weapon-type {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .weapon-variant {
    font-size: 0.8rem;
  }

  .set-container.weapons {
    padding-bottom: 0;
  }

  .variant-stats-container {
    display: none;
  }
  .variant-stats-container.horizontal {
    display: flex;
  }

  .weapon-info-headline {
    align-items: flex-end;
  }

  .weapon-header {
    position: absolute;
    display: none;
    top: -75px;
    left: 0;
  }
  .weapon-header .weapon-header-section-title {
    flex-direction: column;
    line-height: 1;
    color: rgba(255, 255, 255, 0.05);
    font-size: 3.6rem;
    width: 50%;
  }
  .weapon-header .weapon-header-section-title span {
    padding: 0;
    margin: 0;
    line-height: 1;
    position: relative;
    display: flex;
    width: 100%;
    align-items: flex-start;
    text-align: left;
    margin-top: -5px;
  }
  .weapon-header .weapon-header-section-title span:first-child {
    font-size: 1.2rem;
    margin-top: 0;
  }

  .explorer-sets-pattern {
    height: 2.6rem;
    margin-bottom: 0.6rem;
  }
  .explorer-sets-pattern .slant {
    background: rgba(255, 255, 255, 0.05);
  }

  .explorer-details-header {
    margin-top: 10px;
    display: flex;
  }

  .segment-header-visual {
    position: relative;
  }
  .segment-header-visual:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 18.75%;
  }
  .segment-header-visual > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 992px) {
  .explorer-menu-bounds {
    height: 125px;
  }

  .segment-header-visual {
    position: relative;
  }
  .segment-header-visual:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 12.5%;
  }
  .segment-header-visual > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .podcast-section-title {
    font-size: 2.6rem;
  }

  .explorer-scrollable .explorer-sets-pattern {
    height: 2.2rem;
  }

  .podcast-host {
    width: 25%;
  }
}
@media (min-width: 1024px) {
  #mobile-detect {
    content: "mq-desktop";
  }

  .explorer-scrollable {
    padding-left: 40px;
    padding-right: 40px;
  }

  .segment-header-visual {
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  #breakpoint-detect {
    content: "1024";
  }

  .explorer-menu-bounds {
    height: 120px;
  }

  .form-select {
    padding: 0.275rem 2.25rem 0.275rem 0.75rem;
  }
  .form-select option {
    line-height: initial !important;
  }

  .header-bounds {
    height: 158px;
  }

  .header-inner-bounds {
    height: 28px;
  }

  .header-visual {
    height: 100px;
    background-image: url("../../images/header-visual.png");
  }

  .header-visual-bg {
    height: 130px;
  }

  .header-container {
    margin-top: -79px;
  }

  .corner {
    width: 34px;
    height: 100%;
  }

  .header-logo-container {
    width: 500px;
    height: 30px;
    margin-bottom: -1px;
  }
  .header-logo-container.flipped .corner {
    width: 34px;
  }

  .logo-container {
    bottom: 12px;
    width: 45%;
    text-align: initial;
  }
  .logo-container img {
    width: 100%;
  }
  .logo-container .slogan {
    font-size: 0.7rem;
  }

  .header-nav-bar {
    height: 30px;
  }

  .header-nav {
    width: 100%;
    left: initial;
    transform: translateX(0);
  }

  .nav-item {
    padding: 10px;
    width: initial;
  }
  .nav-item .headline {
    font-size: 0.8rem;
  }
  .nav-item .label {
    font-size: 1.4rem;
  }

  .footer-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    top: 100%;
    bottom: initial;
  }
  .footer-container.show {
    top: 164px;
  }

  .footer-nav-bar {
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0;
    height: 35px;
    background: #0F1216;
    border-top: 1px solid #272A2E;
    border-bottom: 1px solid #272A2E;
    transition: all 0.3s ease-in-out;
  }

  .builder-tab {
    position: relative;
    display: flex;
    width: 100%;
  }

  .builderlibrary-tab-container {
    height: 35px;
  }

  .guides-btn .headline {
    font-weight: 500;
    font-size: 0.8rem;
  }
  .guides-btn .label {
    font-weight: 600;
    font-size: 1.4rem;
  }

  .field-label .label-text {
    font-weight: 600;
    font-size: 0.85rem;
  }
  .field-label.armory {
    margin-bottom: 5px;
  }
  .field-label.segment-divider .label-text {
    font-size: 1rem;
  }

  .field-group.flexed {
    flex: 0 0 auto;
    margin-bottom: initial;
  }
  .field-group.flexed .field-input {
    width: 100%;
  }

  .field-input {
    height: 34px;
    width: 100%;
  }

  .common-god-rolls {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .estimates {
    position: relative;
    display: flex;
    width: 100%;
  }

  .estimate-col {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
  }
  .estimate-col .status {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background: #dbaa43;
    color: black;
    text-transform: uppercase;
    font-size: 0.65rem;
    padding: 5px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 10px;
  }
  .estimate-col .status.unusual {
    background: #6F7D65;
    color: white;
  }
  .estimate-col .status.rare {
    background: #677E93;
    color: white;
  }
  .estimate-col .status.epic {
    background: #6E687E;
    color: white;
  }
  .estimate-col .status.legendary {
    background: #7A623C;
    color: white;
  }
  .estimate-col .init-value {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    margin-bottom: 5px;
    line-height: 1;
  }
  .estimate-col .max-value {
    position: relative;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    justify-content: center;
    font-size: 1rem;
    line-height: 1;
  }

  .action-btn {
    font-size: 1.2rem;
  }
  .action-btn .inner-label.responsive {
    display: initial;
  }
  .action-btn.sm {
    font-size: 1rem;
    height: 35px;
  }
  .action-btn.hide {
    display: none;
  }

  .std-btn {
    font-size: 1.2rem;
  }
  .std-btn.sm {
    height: 34px;
    font-size: 0.85rem;
  }

  .result-stats {
    flex-direction: initial;
    flex-wrap: initial;
  }

  .stats {
    width: auto;
    padding: initial;
  }

  .result-short {
    font-size: 3.6rem;
  }

  .result-full {
    font-size: 1.4rem;
  }

  .segment-header-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .segment-header {
    margin-bottom: initial;
    max-width: 1140px;
  }

  .segment-header-headline {
    font-size: 1.2rem;
  }

  .segment-header-title {
    font-size: 2rem;
  }

  .segment-header-description {
    padding-left: 10%;
    padding-right: 10%;
  }

  .segment-text {
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.25;
    font-size: 0.85rem;
    margin-top: 10px;
  }

  .social-connect-container {
    margin-top: 10px;
  }

  .social-connect .content {
    font-size: 2rem;
  }
  .social-connect:hover {
    border: 6px solid #dbaa43;
  }

  .segment-controls {
    align-items: center;
  }

  .profile-avatar {
    width: 40px;
  }

  .profile-nickname {
    flex: 0 0 auto;
  }
  .profile-nickname .label {
    font-size: 0.75rem;
    margin-bottom: initial;
  }
  .profile-nickname .nickname {
    font-size: 1.4rem;
  }

  .build-item-content {
    padding: 15px 25px 15px 145px;
  }

  .build-item-title {
    font-size: 1.1rem;
    line-height: 1;
    margin-bottom: 4px;
  }

  .build-item-desc {
    font-size: 0.8rem;
  }

  .build-item {
    width: 33.3%;
    height: 200px;
  }

  .character-module {
    width: 15%;
  }
  .character-module .field-label {
    margin-top: initial;
  }

  .build-creator-content {
    max-width: 1140px;
  }

  .build-meta {
    width: 30%;
  }

  .build-class-chooser {
    width: 55%;
    padding-top: 0;
  }

  .class-icon {
    width: 15%;
    margin-right: 20px;
  }

  .skill-chooser-unavailable {
    line-height: initial;
  }

  .skill-icon {
    width: 12.5%;
    padding: 10px;
  }
  .skill-icon .skill-name {
    font-size: 0.7rem;
  }
  .skill-icon .skill-type {
    font-size: 0.65rem;
  }

  .gear-title {
    font-size: 1.1rem;
    height: 60px;
    padding: 10px 10px 10px 10px;
  }

  .gear-attributes .field-label {
    display: none;
  }

  .armor-item {
    width: 33.3%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 30px;
  }

  .weapon-item {
    width: 33.3%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 30px;
  }

  .skill-tree-container {
    position: absolute;
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    padding: initial;
  }
  .skill-tree-container svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: auto;
    padding: 60px 30px 30px 30px;
    width: 100%;
    max-width: 2100px;
  }

  .armory-item {
    width: 16.6%;
    margin-bottom: initial;
  }
  .armory-item.explorer-armor {
    width: 50px;
  }
  .armory-item.explorer-armor.upper {
    width: 80px;
  }
  .armory-item.explorer-armor.footgear {
    width: 65px;
  }
  .armory-item .item-title {
    font-size: 0.95rem;
  }

  .mod-item {
    width: 10%;
  }
  .mod-item .item-title {
    font-size: 0.9rem;
  }

  .explorer-controls {
    margin-top: 25px;
    margin-bottom: 0;
    padding-left: initial;
    padding-right: initial;
  }

  .explorer-sectionals {
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1140px;
  }

  .explorer-menu-tab {
    width: 300px;
    height: inherit;
  }

  .explorer-menu-tab-info {
    text-align: initial;
    padding-left: 10px;
    padding-right: 10px;
  }
  .explorer-menu-tab-info .label {
    font-size: 1rem;
  }

  .inner-content-explorer-tab {
    margin-top: -10px;
    padding-bottom: 10px;
  }

  .explorer-menu-header {
    height: 30px;
  }

  .explorer-menu-header-left {
    height: initial;
  }

  .explorer-menu-header-right {
    height: initial;
  }

  .explorer-menu-content {
    padding-bottom: 7px;
  }

  .explore-menu-carousel {
    padding-top: 10px;
  }

  .set-container-menu {
    padding-bottom: 5px;
  }
  .set-container-menu .explorer-sectional-item {
    margin-bottom: 10px;
  }
  .set-container-menu .explorer-item-label {
    font-size: 0.7rem;
    font-weight: 600;
  }
  .set-container-menu.selected .explorer-sectional-item .inside-visual .content {
    box-shadow: inset 0 0 0 4px #dbaa43;
  }

  .explorer-label-headline {
    font-size: 0.9rem;
  }

  .explorer-label-title {
    font-size: 1.8rem;
    max-width: 40%;
  }

  .armory-pieces {
    width: 40%;
  }

  .explorer-label-default {
    font-size: 1.4rem;
    max-width: 45%;
  }
  .explorer-label-default .text-label.sm {
    font-size: 1rem;
  }
  .explorer-label-default .text-label.sm.bonus {
    width: 100%;
  }
  .explorer-label-default .text-label.xs {
    font-size: 0.85rem;
    width: initial;
    max-width: initial;
  }

  .gradient-background {
    right: 150px;
    height: 100%;
    width: 70%;
    background-position: 110px center;
  }

  .explorer-stats {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
  }
  .explorer-stats .spacer {
    height: 20px;
  }

  .explorer-stats-controls {
    width: 75px;
    align-self: flex-start;
    align-items: flex-start;
    height: 100%;
    margin-top: 7px;
  }

  .explorer-stats-controls-side .stat-action .content img {
    opacity: 0.1;
  }

  .explorer-stats-details {
    align-self: flex-start;
  }

  .explorer-figure {
    right: 125px;
    width: 50%;
    padding-left: 0;
  }

  .explorer-rarity-container select {
    height: 30px;
  }
  .explorer-rarity-container .edd-root {
    height: 34px;
  }
  .explorer-rarity-container .edd-root .edd-head {
    height: 34px;
  }
  .explorer-rarity-container .edd-root .edd-head .edd-value {
    line-height: 28px;
  }

  .explorer-search-container {
    height: 34px;
  }

  .explorer-search-input {
    height: 34px;
  }

  .explorer-icons {
    width: 110%;
    height: 150%;
    bottom: 29px;
  }

  .explorer-navigator {
    position: absolute;
    height: 150px;
  }

  .explorer-details-header {
    display: none;
  }

  .weapon-details {
    padding-top: 0;
  }

  .weapon-info {
    align-self: center;
  }
}
@media (min-width: 1024px) and (min-height: 700px) {
  .explorer-details-header {
    display: flex;
  }
}
@media (min-width: 1024px) and (min-height: 900px) {
  .explorer-navigator {
    position: relative;
  }

  .explorer-figure {
    height: 65vh;
  }

  .explorer-stats-controls {
    align-self: flex-start;
    align-items: flex-start;
  }

  .explorer-stats-details {
    align-self: flex-start;
  }

  .armory-item.explorer-armor {
    width: 50px;
  }
  .armory-item.explorer-armor.upper {
    width: 80px;
  }
  .armory-item.explorer-armor.footgear {
    width: 65px;
  }
}
@media (min-width: 1024px) and (min-height: 1024px) {
  .explorer-details-header {
    display: flex;
  }

  .armory-pieces {
    width: 35%;
  }

  .explorer-sets-header {
    font-size: 3.2rem;
  }

  .explorer-navigator {
    position: relative;
  }

  .explorer-stats-controls {
    align-self: center;
    align-items: center;
  }

  .explorer-stats-details {
    padding-top: 0;
    align-self: center;
    margin: 0;
  }

  .character-visual {
    max-height: 920px;
  }

  .armory-item.explorer-armor {
    width: 50px;
  }
  .armory-item.explorer-armor.upper {
    width: 80px;
  }
  .armory-item.explorer-armor.footgear {
    width: 65px;
  }

  .weapon-stats {
    max-width: 960px;
  }
}
@media (min-width: 1024px) and (max-width: 1024px) and (min-height: 1300px) {
  .explorer-figure {
    top: 50%;
    transform: translateY(-50%);
  }

  .weapon-stats {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 720px;
    height: 100%;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
  }

  .weapon-firepower {
    position: absolute;
    left: 10px;
    top: -25px;
    width: auto;
    border-radius: 2px;
    font-weight: 600;
    padding: 3px;
    background: #dbaa43;
    color: black;
    font-size: 0.85rem;
  }

  .weapon-visual {
    position: relative;
    display: flex;
    height: inherit;
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 0 20px 0 20px;
    border-right: 1px solid rgba(255, 255, 255, 0.05);
    border-left: 1px solid rgba(255, 255, 255, 0.05);
  }
  .weapon-visual .content {
    position: absolute;
    height: 100%;
    background-image: none;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .weapon-visual-with-stats {
    position: relative;
    width: 100%;
    height: 35%;
    display: flex;
    flex-direction: column;
  }

  .weapon-info {
    position: relative;
    display: flex;
    height: 50%;
    flex: 1 1 auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }

  .weapon-details {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .weapon-basic {
    position: relative;
    display: flex;
    width: 55%;
    flex: 0 0 auto;
    padding-right: 5px;
    padding-left: 10px;
    flex-direction: column;
  }

  .weapon-attributes {
    position: relative;
    display: flex;
    width: 45%;
    flex: 0 0 auto;
    text-transform: uppercase;
    padding-right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    margin-top: 3px;
  }
  .weapon-attributes ul {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
  }
  .weapon-attributes ul li {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    line-height: 1.4;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
    color: white;
    border-bottom: none;
  }
  .weapon-attributes ul li:before {
    display: inline-block;
    content: "";
    color: white;
    font-size: 0.9em;
    vertical-align: middle;
    font-family: "Font Awesome Kit";
    width: 1em;
    margin-right: 0.3em;
    margin-left: -1em;
  }

  .variant-stats-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 0 0 auto;
  }
  .variant-stats-container.horizontal {
    display: none;
  }

  .weapon-variant-stat {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: auto;
    flex-direction: column;
    padding: 5px;
    background: rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
  .weapon-variant-stat:last-child {
    border-right: none;
  }

  .weapon-variant-value {
    position: relative;
    display: block;
    font-size: 1rem;
    color: white;
    line-height: 1;
  }

  .weapon-variant-label {
    position: relative;
    display: block;
    font-size: 0.65rem;
    line-height: 1;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
  }

  .weapon-variant-stats {
    position: relative;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.05);
    margin-top: 10px;
  }
  .weapon-variant-stats.accuracy {
    margin-top: -1px;
  }
  .weapon-variant-stats.accuracy .weapon-variant-value {
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  .weapon-variant-stats.accuracy .weapon-variant-label {
    font-size: 0.6rem;
  }

  .weapon-title {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
    color: white;
  }

  .weapon-type {
    width: 100%;
    text-align: left;
    font-size: 0.7rem;
    text-transform: uppercase;
    line-height: 1;
    color: rgba(255, 255, 255, 0.7);
  }

  .weapon-variant {
    width: 100%;
    text-align: left;
    font-size: 0.7rem;
    text-transform: uppercase;
    line-height: 1;
    color: rgba(255, 255, 255, 0.7);
  }

  .weapon-stats-info {
    position: relative;
    display: flex;
    width: 100%;
    font-size: 0.8rem;
    line-height: 1;
    margin-top: 15px;
    padding-left: 10px;
    flex-direction: column;
  }
  .weapon-stats-info .weapon-mods {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    flex-direction: row;
  }

  .weapon-mod-item {
    position: relative;
    display: flex;
    width: 50%;
    padding-right: 5px;
  }
  .weapon-mod-item:first-child {
    margin-bottom: 0;
  }

  .weapon-mod-icon {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    height: auto;
    align-self: flex-start;
    width: 45px;
    position: relative;
  }
  .weapon-mod-icon:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  .weapon-mod-icon > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .weapon-mod-icon .content {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .weapon-mod-icon .content .mod-tier {
    position: absolute;
    display: inline-block;
    top: 0;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    z-index: 3;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black;
  }
  .weapon-mod-icon .content .visual {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    background: black;
    box-shadow: inset 0 0 0 1px #313941;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
  }
  .weapon-mod-icon .content .visual.sharpen {
    filter: url("#Sharpen");
    opacity: 0.7;
  }

  .weapon-mod-information {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    padding-left: 10px;
    width: auto;
    justify-content: center;
    flex-direction: column;
  }

  .weapon-mod-title {
    position: relative;
    width: 100%;
    line-height: 1.1;
    font-size: 0.85rem;
    font-weight: 600;
    color: white;
    margin-bottom: 3px;
    margin-top: -1px;
  }

  .weapon-mod-description {
    position: relative;
    display: none;
    line-height: 1.1;
    width: 100%;
    font-size: 0.65rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
  }
  .weapon-mod-description em, .weapon-mod-description strong {
    color: white;
  }
  .weapon-mod-description .cooldown {
    font-size: 0.9em;
    font-family: "Font Awesome 6 Pro";
  }

  .weapon-header {
    position: relative;
    display: none;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 15px;
  }

  .weapon-info-headline {
    position: relative;
    display: flex;
    width: 100%;
  }

  .weapon-header-section-title {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    font-size: 1.3rem;
    font-weight: bold;
    color: #313941;
    text-transform: uppercase;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
    line-height: 0.8;
    letter-spacing: -0.05rem;
  }

  .weapon-stats {
    max-width: 960px;
  }

  .weapon-visual-with-stats {
    height: 35%;
    margin-top: 70px;
  }

  .weapon-title {
    font-size: 2.2rem;
  }

  .weapon-attributes {
    font-size: 1rem;
  }

  .weapon-variant-stat {
    padding: 10px;
  }

  .weapon-variant-value {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .variant-stats-container {
    margin-top: 10px;
  }

  .weapon-variant-label {
    font-size: 0.8rem;
  }

  .weapon-variant-stats.accuracy .weapon-variant-value {
    font-size: 1rem;
    font-weight: 600;
  }
  .weapon-variant-stats.accuracy .weapon-variant-label {
    font-size: 0.8rem;
  }

  .weapon-mod-icon {
    width: 75px;
  }
  .weapon-mod-icon .mod-tier {
    font-size: 0.85rem;
  }

  .weapon-type {
    font-size: 1rem;
  }

  .weapon-variant {
    font-size: 1rem;
  }

  .weapon-stats-info {
    margin-top: 30px;
  }

  .weapon-mod-information {
    justify-content: flex-start;
    padding-right: 10px;
  }

  .weapon-mod-title {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .weapon-mod-description {
    display: block;
    font-size: 0.85rem;
    line-height: 1.2;
  }

  .weapon-visual .weapon-header {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .explorer-stats-details {
    align-self: flex-start;
  }

  .explorer-menu-bounds {
    height: 128px;
  }

  .explorer-navigator {
    position: absolute;
  }
}
@media (min-width: 1200px) and (min-height: 900px) {
  .explorer-navigator {
    position: relative;
  }

  .explorer-menu-bounds {
    height: 128px;
  }

  .explorer-stats-controls {
    align-self: flex-start;
    align-items: flex-start;
  }

  .explorer-stats-details {
    align-self: flex-start;
  }

  .armory-item.explorer-armor {
    width: 60px;
  }
  .armory-item.explorer-armor.upper {
    width: 75px;
  }
  .armory-item.explorer-armor.footgear {
    width: 70px;
  }
}
@media (min-width: 1200px) and (min-height: 1024px) {
  .armory-pieces {
    width: 50%;
  }

  .explorer-navigator {
    position: relative;
  }

  .explorer-stats-details {
    margin-top: 0;
  }

  .explorer-figure {
    height: 60vh;
  }

  .explorer-stats-controls {
    align-self: center;
    align-items: center;
  }

  .explorer-stats-details {
    align-self: center;
    margin-top: -10%;
  }

  .armory-item.explorer-armor {
    width: 60px;
  }
  .armory-item.explorer-armor.upper {
    width: 90px;
  }
  .armory-item.explorer-armor.footgear {
    width: 70px;
  }
}
@media (min-width: 1400px) {
  #mobile-detect {
    content: "mq-desktop";
  }

  #breakpoint-detect {
    content: "1400";
  }

  .explorer-menu-bounds {
    height: 128px;
  }

  .form-select {
    padding: 0.275rem 2.25rem 0.275rem 0.75rem;
  }
  .form-select option {
    line-height: initial !important;
  }

  .header-bounds {
    height: 178px;
  }

  .header-inner-bounds {
    height: 28px;
  }

  .header-visual {
    height: 100px;
    background-image: url("../../images/header-visual.png");
  }

  .header-visual-bg {
    height: 150px;
  }

  .header-container {
    margin-top: -79px;
  }

  .corner {
    width: 34px;
    height: 100%;
  }

  .header-logo-container {
    width: 500px;
    height: 30px;
    margin-bottom: -1px;
  }
  .header-logo-container.flipped .corner {
    width: 34px;
  }

  .logo-container {
    bottom: -5px;
    width: 60%;
    text-align: initial;
  }
  .logo-container img {
    width: 100%;
  }
  .logo-container .slogan {
    font-size: 0.7rem;
  }

  .header-nav-bar {
    height: 50px;
  }

  .header-nav {
    width: 100%;
    left: initial;
    transform: translateX(0);
  }

  .nav-item {
    padding: 10px;
    width: initial;
  }
  .nav-item .headline {
    font-size: 0.8rem;
  }
  .nav-item .label {
    font-size: 1.4rem;
  }

  .footer-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    top: 100%;
    bottom: initial;
  }
  .footer-container.show {
    top: 164px;
  }

  .footer-nav-bar {
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0;
    height: 35px;
    background: #0F1216;
    border-top: 1px solid #272A2E;
    border-bottom: 1px solid #272A2E;
    transition: all 0.3s ease-in-out;
  }

  .builder-tab {
    position: relative;
    display: flex;
    width: 100%;
  }

  .builderlibrary-tab-container {
    height: 35px;
  }

  .guides-btn .headline {
    font-weight: 500;
    font-size: 0.8rem;
  }
  .guides-btn .label {
    font-weight: 600;
    font-size: 1.4rem;
  }

  .field-label .label-text {
    font-weight: 600;
    font-size: 0.85rem;
  }
  .field-label.armory {
    margin-bottom: 5px;
  }
  .field-label.segment-divider .label-text {
    font-size: 1rem;
  }

  .field-group.flexed {
    flex: 0 0 auto;
    margin-bottom: initial;
  }
  .field-group.flexed .field-input {
    width: 100%;
  }

  .field-input {
    height: 34px;
    width: 100%;
  }

  .common-god-rolls {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .estimates {
    position: relative;
    display: flex;
    width: 100%;
  }

  .estimate-col {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
  }
  .estimate-col .status {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background: #dbaa43;
    color: black;
    text-transform: uppercase;
    font-size: 0.65rem;
    padding: 5px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 10px;
  }
  .estimate-col .status.unusual {
    background: #6F7D65;
    color: white;
  }
  .estimate-col .status.rare {
    background: #677E93;
    color: white;
  }
  .estimate-col .status.epic {
    background: #6E687E;
    color: white;
  }
  .estimate-col .status.legendary {
    background: #7A623C;
    color: white;
  }
  .estimate-col .init-value {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    margin-bottom: 5px;
    line-height: 1;
  }
  .estimate-col .max-value {
    position: relative;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    justify-content: center;
    font-size: 1rem;
    line-height: 1;
  }

  .action-btn {
    font-size: 1.2rem;
  }
  .action-btn .inner-label.responsive {
    display: initial;
  }
  .action-btn.sm {
    font-size: 1rem;
    height: 35px;
  }
  .action-btn.hide {
    display: none;
  }

  .std-btn {
    font-size: 1.2rem;
  }
  .std-btn.sm {
    height: 34px;
    font-size: 0.85rem;
  }

  .result-stats {
    flex-direction: initial;
    flex-wrap: initial;
  }

  .stats {
    width: auto;
    padding: initial;
  }

  .result-short {
    font-size: 3.6rem;
  }

  .result-full {
    font-size: 1.4rem;
  }

  .segment-header-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .segment-header {
    margin-bottom: initial;
  }

  .segment-header-headline {
    font-size: 1.2rem;
  }

  .segment-header-title {
    font-size: 2rem;
  }

  .segment-header-description {
    padding-left: 10%;
    padding-right: 10%;
  }

  .segment-text {
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.25;
    font-size: 0.85rem;
    margin-top: 10px;
  }

  .social-connect-container {
    margin-top: 10px;
  }

  .social-connect .content {
    font-size: 2rem;
  }
  .social-connect:hover {
    border: 6px solid #dbaa43;
  }

  .segment-controls {
    align-items: center;
  }

  .profile-avatar {
    width: 40px;
  }

  .profile-nickname {
    flex: 0 0 auto;
  }
  .profile-nickname .label {
    font-size: 0.75rem;
    margin-bottom: initial;
  }
  .profile-nickname .nickname {
    font-size: 1.4rem;
  }

  .build-item-content {
    padding: 15px 25px 15px 145px;
  }

  .build-item-title {
    font-size: 1.1rem;
    line-height: 1;
    margin-bottom: 4px;
  }

  .build-item-desc {
    font-size: 0.8rem;
  }

  .build-item {
    width: 33.3%;
    height: 200px;
  }

  .character-module {
    width: 15%;
  }
  .character-module .field-label {
    margin-top: initial;
  }

  .build-meta {
    width: 30%;
  }

  .build-class-chooser {
    width: 55%;
  }

  .class-icon {
    width: 15%;
    margin-right: 20px;
  }

  .skill-chooser-unavailable {
    line-height: initial;
  }

  .skill-icon {
    width: 12.5%;
    padding: 10px;
  }
  .skill-icon .skill-name {
    font-size: 0.7rem;
  }
  .skill-icon .skill-type {
    font-size: 0.65rem;
  }

  .gear-title {
    font-size: 1.1rem;
    height: 60px;
    padding: 10px 10px 10px 10px;
  }

  .gear-attributes .field-label {
    display: none;
  }

  .armor-item {
    width: 33.3%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 30px;
  }

  .weapon-header {
    display: none;
  }

  .weapon-item {
    width: 33.3%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 30px;
  }

  .skill-tree-container {
    position: absolute;
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    padding: initial;
  }
  .skill-tree-container svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: auto;
    padding: 60px 30px 30px 30px;
    width: 100%;
    max-width: 2100px;
  }

  .armory-item {
    width: 16.6%;
    margin-bottom: initial;
  }
  .armory-item.explorer-armor {
    width: 75px;
    padding: 5px;
  }
  .armory-item.explorer-armor.upper {
    width: 100px;
  }
  .armory-item.explorer-armor.footgear {
    width: 95px;
  }
  .armory-item .item-title {
    font-size: 0.95rem;
  }

  .mod-item {
    width: 10%;
  }
  .mod-item .item-title {
    font-size: 0.9rem;
  }

  .explorer-controls {
    margin-top: 25px;
    margin-bottom: 0;
    padding-left: initial;
    padding-right: initial;
  }

  .explorer-sectionals {
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .explorer-menu-tab {
    width: 300px;
    height: inherit;
  }

  .explorer-menu-tab-info {
    text-align: initial;
    padding-left: 10px;
    padding-right: 10px;
  }
  .explorer-menu-tab-info .label {
    font-size: 1rem;
  }

  .inner-content-explorer-tab {
    margin-top: -10px;
    padding-bottom: 10px;
  }

  .explorer-menu-header {
    height: 30px;
  }

  .explorer-menu-header-left {
    height: initial;
  }

  .explorer-menu-header-right {
    height: initial;
  }

  .explorer-menu-content {
    padding-bottom: 7px;
  }

  .explore-menu-carousel {
    padding-top: 10px;
  }

  .set-container-menu {
    padding-bottom: 5px;
  }
  .set-container-menu .explorer-sectional-item {
    margin-bottom: 10px;
  }
  .set-container-menu .explorer-item-label {
    font-size: 0.7rem;
    font-weight: 600;
  }
  .set-container-menu.selected .explorer-sectional-item .inside-visual .content {
    box-shadow: inset 0 0 0 4px #dbaa43;
  }

  .explorer-label-headline {
    font-size: 0.9rem;
  }

  .explorer-label-title {
    font-size: 1.8rem;
    max-width: 40%;
  }

  .armory-pieces {
    width: initial;
  }

  .explorer-label-default {
    font-size: 1.4rem;
    max-width: 50%;
  }
  .explorer-label-default .text-label.sm {
    font-size: 1rem;
  }
  .explorer-label-default .text-label.sm.bonus {
    width: 100%;
  }
  .explorer-label-default .text-label.xs {
    font-size: 0.85rem;
    width: initial;
    max-width: initial;
  }

  .gradient-background {
    right: 150px;
    height: 100%;
    width: 70%;
    background-position: 110px center;
  }

  .explorer-stats {
    padding-left: 50px;
    padding-right: 50px;
  }
  .explorer-stats .spacer {
    height: 20px;
  }

  .explorer-stats-controls {
    width: 100px;
    align-self: center;
    align-items: center;
    height: 100%;
    margin-top: 7px;
  }

  .explorer-stats-controls-side .stat-action .content img {
    opacity: 0.1;
  }

  .explorer-stats-details {
    align-self: center;
  }

  .explorer-figure {
    right: 150px;
    width: 50%;
    height: 65vh;
    padding-left: 0;
  }

  .explorer-rarity-container select {
    height: 30px;
  }
  .explorer-rarity-container .edd-root {
    height: 34px;
  }
  .explorer-rarity-container .edd-root .edd-head {
    height: 34px;
  }
  .explorer-rarity-container .edd-root .edd-head .edd-value {
    line-height: 28px;
  }

  .explorer-search-container {
    height: 34px;
  }

  .explorer-search-input {
    height: 34px;
  }

  .explorer-icons {
    width: 110%;
    height: 150%;
    bottom: 29px;
  }

  .explorer-navigator {
    position: absolute;
  }

  .weapon-stats {
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (min-width: 1400px) and (max-height: 800px) {
  .explorer-stats-details {
    align-self: flex-start;
  }

  .explorer-details-header {
    margin-bottom: 10px !important;
  }
}
@media (min-width: 1400px) and (min-height: 900px) {
  .weapon-header {
    display: flex;
  }

  .explorer-navigator {
    position: relative;
  }

  .weapon-visual-with-stats {
    justify-content: center;
  }

  .weapon-visual {
    height: 50%;
  }

  .weapon-stats {
    align-items: center;
  }

  .explorer-details-header {
    display: flex;
    margin-bottom: 10px !important;
  }

  .explorer-figure {
    top: 50%;
    transform: translateY(-50%);
  }

  .explorer-stats-details {
    align-self: center;
    margin-top: 0;
  }
}
@media (min-width: 1400px) and (min-height: 1050px) {
  .explorer-details-header {
    display: flex;
  }
}