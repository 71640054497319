@media (min-width: 411px) {
    .explorer-figure {
        //height: 58vh;
        top: 0;
        transform: translateY(0);
    }
    .explorer-menu-bounds {
        height: 90px;
    }

    .armory-pieces {
        width: 40%;
        margin-top: 5px;
    }
    .explorer-stats {
        align-items: center;
        padding-top: 10px;

        .spacer {
            height: 15px;
        }
    }
    .explorer-stats {
        padding-top: 5px;
    }
    .explorer-stats-details {
        align-self: flex-start;
    }
    .explorer-label-default {
        font-size: 1.1rem;

        .text-label {
            &.sm {
                font-size: 0.75rem;

                &.bonus {
                    width: 40%;
                    line-height: 1.2;
                }
            }
        }
    }
    .armory-item {
        &.explorer-armor {
            padding: 0;
        }
    }
    @media (min-height: 730px) {
        .explorer-menu-bounds {
            height: 91px;
        }
    }
    @media (min-height: 411px) and (min-height: 736px) {
        .explorer-menu-bounds {
            height: 91px;
        }
        .explorer-navigator {
            position: relative;
        }
    }
    @media (min-height: 800px) {
        .explorer-figure {
            //height: 58vh;
            top: 0;
            transform: translateY(0);
        }
        .explorer-navigator {
            position: relative;
        }
    }
    @media (min-height: 850px) {
        .explorer-navigator {
            position: relative;
        }
        .explorer-figure {
            //height: 60vh;
            top: 0;
            width: calc(100% - 48%);
            transform: translateY(0);
        }
        .explorer-label-default {
            .text-label {
                &.sm {
                    &.bonus {
                        width: 38%;
                    }
                }
            }
        }
    }
}
