@media (min-width: 960px) {
    #mobile-detect {
        content: 'mq-desktop';
    }

    #breakpoint-detect {
        content: '960';
    }

    .explorer-menu-bounds {
        height: 120px;
    }

    .explorer-navigator {
        height: 140px;
    }

    .podcast-episodes-description {
        font-size: 1rem;
    }
    .podcast-host {
        width: 25%;
    }
    //.header-bounds {
    //    height: 136px;
    //}
    //
    //.footer-bounds {
    //    height: 35px;
    //}
    //
    //.header-visual {
    //    height: 75px;
    //    //background-image: url('../images/header-visual-mobile.png');
    //}
    //
    //.header-visual-bg {
    //    height: 80px;
    //}
    //
    //.header-container {
    //    padding-top: 50px;
    //    margin-top: -75px;
    //}
    //
    //.header-logo-container {
    //    height: 25px;
    //    width: 410px;
    //    margin-bottom: -2px;
    //
    //    &.flipped {
    //        .corner {
    //            width: 23px;
    //        }
    //    }
    //}
    //
    //.nav-item {
    //    .headline {
    //        font-size: 0.7rem;
    //    }
    //
    //    .label {
    //        font-size: 1.2rem;
    //    }
    //}
    //
    //.corner {
    //    width: 24px;
    //    height: 24px;
    //}
    //
    //.logo-container {
    //    text-align: center;
    //    bottom: 8px;
    //
    //    img {
    //        width: 55%;
    //    }
    //
    //    .slogan {
    //        font-size: 0.7rem;
    //    }
    //}
    //
    //.header-nav-bar {
    //    height: 35px;
    //}
    //
    //.header-nav {
    //    width: 95%;
    //}


    .segment-header {
        max-width: 720px;
    }

    .segment-controls {
        margin-bottom: 0;
    }
    .segment-content-container {
        max-width: 576px;
    }

    .field-label {
        &.segment-divider {
            .label-text {
                font-size: 0.85rem;
            }
        }
    }

    .explorer-stats-controls {
        margin-top: 6px;
    }
    .explorer-sectionals {
        max-width: 720px;
        margin: 0 auto;
    }
    .build-creator-content {
        max-width: 720px;
    }
    .armory-pieces {
        width: 50%;
    }
    .armory-item {
        &.explorer-armor {
            width: 45px;
            margin-bottom: 5px;
            margin-right: 5px;

            &.upper {
                width: 60px;
                margin-top: -5px;
            }

            &.footgear {
                width: 60px;
            }
        }
    }
    .explorer-figure {
        //height: 60vh;
    }
    .explorer-label-default {
        font-size: 1.1rem;

        .text-label {
            &.sm {
                font-size: 0.85rem;

                &.bonus {
                    width: 45%;
                }
            }
        }
    }
    .explorer-details-header {
        display: none;
        margin-bottom: 20px !important;
    }
    .explorer-sets-header {
        position: relative;
        display: flex;
        color: #313941;
        width: 50%;
        align-items: center;
        text-transform: uppercase;
        font-size: 2.4rem;
        line-height: 0.8;
        font-weight: bold;
        flex: 0 0 auto;
        letter-spacing: -0.05rem;
    }

    .skill-tree-container {
        svg {
            height: auto;
            width: 100%;
        }
    }

    .explorer-label-title {
        font-size: 1.5rem;
    }
    .armory-item {
        width: 25%;
    }

    .mod-item {
        width: 12.5%;
    }

    .guides-tab-container {
        position: relative;
        display: flex;
        width: 160px;
        height: 35px;
        margin: 0 auto;
        margin-bottom: -1px;
        z-index: 2;
        transition: all 0.3s ease-in-out;
        //position: relative;
        //display: flex;
        //width: 150px;
        //height: 35px;
        //margin: 0 auto;
        //margin-bottom: -1px;
        //transform: translateX(-5px);
        //z-index: 2;
        //transition: all 0.3s ease-in-out;

        .corner {
            width: 35px;
            height: 35px;
        }
    }

    .guides-tab {
        position: relative;
        display: flex;
        width: 100%;
        height: 35px;
    }

    .guides-btn {
        position: absolute;
        width: inherit;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 10px 0 10px 0;
        line-height: 1;
        border-top: 2px solid transparent;
        cursor: pointer;

        .headline {
            position: relative;
            display: flex;
            width: 100%;
            font-weight: 500;
            line-height: 1;
            justify-content: center;
            color: rgba(white, 0.6);
            text-transform: uppercase;
            font-size: 0.7rem;
            transition: all 0.3s ease-in-out;
        }

        .label {
            position: relative;
            line-height: 1;
            display: flex;
            width: 100%;
            justify-content: center;
            font-weight: 600;
            color: rgba(white, 0.85);
            text-transform: uppercase;
            font-size: 1.1rem;
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            border-top: 2px solid rgba(white, 0.3);

            .headline {
                color: rgba(white, 0.85);
            }

            .label {
                color: white;
            }
        }

        &.active {
            .headline {
                color: $outridersYellow;
            }

            .label {
                color: white;
            }

            border-top: 2px solid $outridersYellow;
        }
    }

    .guides-content {
        position: relative;
        display: flex;
        width: 100%;
        height: 0;
        overflow: hidden;
        background-color: transparent;
        background-image: none;
        background-size: cover;
        background-position: center top;
        background-repeat: repeat-x;
        //background: red;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        //transition: border 0s ease-in-out 0.5s;

        &.show {
            background-color: $outridersDark;

            &.outlines {
                border-top: 1px solid $outridersNormal;
                border-bottom: 1px solid $outridersNormal;
            }
        }
    }
    .explorer-sectionals {
        max-width: 870px;
    }
    .segment-header {
        max-width: 1140px;
    }
    .weapon-stats {
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
        max-width: 870px;
    }

    .weapon-visual-with-stats {
        width: 60%;
        justify-content: flex-start;
        height: 100%;
    }
    .weapon-visual {
        width: 100%;
        height: 65%;

        .content {
            background-size: 100%;
            //left: 20px;
            //top: 0;
            //width: calc(100% - 40px);

            &.assault-rifle {
                //top: 0;
                //width: calc(100% - 80px);
                //left: 40px;
            }

            &.rifle, &.automatic-sniper-rifle, &.bolt-action-rifle {
                //top: 0;
                //width: calc(100% - 80px);
                //left: 40px;
            }

            &.light-machinegun {
                //top: -5px;
                //height: calc(100% + 10px);
            }

            &.pistol {
                //top: 20px;
                //height: calc(100% - 40px);
                //width: calc(100% - 100px);
                //left: 50px;
            }

            &.double-gun {
                //top: 0;
                //height: calc(100%);
                //width: calc(100% - 100px);
                //left: 50px;
            }

            &.revolver {
                //top: 5px;
                //height: calc(100% - 10px);
                //width: calc(100% - 200px);
                //left: 100px;
            }

            &.automatic-shotgun {
                //top: 10px;
                //height: calc(100% - 20px);
            }

            &.pump-action-shotgun {
                //top: 0;
                //height: calc(100%);
                //width: calc(100% - 150px);
                //left: 75px;
            }

            &.submachine-gun {
                //top: 5px;
                //height: calc(100% + 10px);
                //width: calc(100% - 200px);
                //left: 100px;
            }
        }
    }
    .weapon-info {
        width: 40%;
        justify-content: center;
    }
    .weapon-details {
        flex-direction: column;
        padding-top: 25px;
    }

    .weapon-title {
        font-size: 2.3rem;
    }
    .weapon-attributes {
        padding-left: 10px;
        font-size: 0.8rem;
        font-weight: 600;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;

        ul {
            li {
                padding-top: 5px;
                padding-bottom: 5px;
                border-bottom: 1px solid $explorerLines;
            }
        }
    }
    .weapon-stats-info {
        padding-right: 20px;

        .weapon-mods {
            flex-direction: column;

            .weapon-mod-item {
                margin-bottom: 20px;
                width: 100%;

                .weapon-mod-information {
                    width: 100%;
                    justify-content: flex-start;
                }

                .weapon-mod-icon {
                    width: 60px;
                }
            }
        }
    }
    .weapon-mod-description {
        display: initial;
        width: 100%;
        font-size: 0.8rem;
        line-height: 1.15;

        .cooldown {
            font-size: 0.8em;
            font-family: "Font Awesome 6 Pro";
        }
    }

    .weapon-basic {
        width: 100%;
    }
    .weapon-type {
        font-size: 0.8rem;
        margin-bottom: 5px;
    }
    .weapon-variant {
        font-size: 0.8rem;
    }

    //.weapon-variant-stats {
    //    display: none;
    //
    //    &.accuracy {
    //        display: none;
    //    }
    //}
    .set-container {
        &.weapons {
            padding-bottom: 0;
        }
    }

    .variant-stats-container {
        display: none;

        &.horizontal {
            display: flex;
            //margin-bottom: -15%;
        }
    }
    .weapon-info-headline {
        align-items: flex-end;
    }
    .weapon-header {
        position: absolute;
        display: none;
        top: -75px;
        left: 0;

        .weapon-header-section-title {
            flex-direction: column;
            line-height: 1;
            //color: $outline-color;
            color: rgba(white, 0.05);
            font-size: 3.6rem;
            width: 50%;

            span {
                padding: 0;
                margin: 0;
                line-height: 1;
                position: relative;
                display: flex;
                width: 100%;
                align-items: flex-start;
                text-align: left;
                margin-top: -5px;

                &:first-child {
                    font-size: 1.2rem;
                    margin-top: 0;
                }
            }
        }
    }
    .explorer-sets-pattern {
        height: 2.6rem;
        margin-bottom: 0.6rem;

        .slant {
            background: rgba(white, 0.05);
        }
    }
    .explorer-details-header {
        margin-top: 10px;
        display: flex;
    }

    .segment-header-visual {
        @include aspect-ratio(16, 3);

        .content {
            svg {
            }
        }

        &.voices {
            //background-image: url('https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/voices-of-enoch.jpg');
        }
    }
}
