.select2-container--default {
    @import "single";
    @import "multiple";

    &.select2-container--open.select2-container--above {
        .select2-selection--single, .select2-selection--multiple {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &.select2-container--open.select2-container--below {
        .select2-selection--single, .select2-selection--multiple {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid #aaa;
        }
    }

    .select2-search--inline {
        .select2-search__field {
            background: transparent;
            border: none;
            outline: 0;
            box-shadow: none;
            -webkit-appearance: textfield;
        }
    }

    .select2-results > .select2-results__options {
        max-height: 200px;
        overflow-y: auto;
    }

    .select2-results__option {
        .select2-results__option {
            padding-left: 1em;

            .select2-results__group {
                padding-left: 0;
            }

            .select2-results__option {
                margin-left: -1em;
                padding-left: 2em;

                .select2-results__option {
                    margin-left: -2em;
                    padding-left: 3em;

                    .select2-results__option {
                        margin-left: -3em;
                        padding-left: 4em;

                        .select2-results__option {
                            margin-left: -4em;
                            padding-left: 5em;

                            .select2-results__option {
                                margin-left: -5em;
                                padding-left: 6em;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-results__option--group {
        padding: 0;
    }

    .select2-results__option--disabled {
        color: #999;
    }

    .select2-results__option--selected {
        background-color: $outline-color;
    }

    .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: #dbaa43;
        color: white;
    }

    .select2-results__group {
        cursor: default;
        display: block;
        padding: 6px;
    }
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 30px;
}

.select2-dropdown {
    background-color: rgba(black, 0.9);
    border: 1px solid #313941;
    border-radius: 0;
    color: white;
    box-sizing: border-box;
    display: block;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #dbaa43;
    font-weight: 500;
    color: black;
}

.select2-results__options::-webkit-scrollbar {
    //width: 16px;
    //background-clip: padding-box;
}

.select2-results__options::-webkit-scrollbar-track {
    background-color: #F4F4F4;
    //height: 8px;
    //background-clip: padding-box;
    //border-right: 10px solid rgba(0, 0, 0, 0);
    //border-top: 10px solid rgba(0, 0, 0, 0);
    //border-bottom: 10px solid rgba(0, 0, 0, 0);
}

.select2-results__options::-webkit-scrollbar-thumb {
    //background-clip: padding-box;
    background-color: #0F2464;
    //border-right: 10px solid rgba(0, 0, 0, 0);
    //border-top: 10px solid rgba(0, 0, 0, 0);
    //border-bottom: 10px solid rgba(0, 0, 0, 0);
}

.select2-results__options::-webkit-scrollbar-button {
    //display: none;
}

.select2-results__options {
    position: relative;
}

/* Let's get this party started */
.select2-results__options::-webkit-scrollbar {
    width: 6px;
}

.select2-results__options::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //-webkit-border-radius: 10px;
    border-radius: 10px;
    background: black;
}

//
.select2-results__options::-webkit-scrollbar-thumb {
    //-webkit-border-radius: 10px;
    border-radius: 10px;
    background: #959698;
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
