@media (min-width: 1024px) {
    #mobile-detect {
        content: 'mq-desktop';
    }

    .explorer-scrollable {
        padding-left: 40px;
        padding-right: 40px;
    }
    .segment-header-visual {
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px;
    }
    #breakpoint-detect {
        content: '1024';
    }

    .explorer-menu-bounds {
        height: 120px;
    }

    // select modifications for attributes on gear builder
    .form-select {
        padding: 0.275rem 2.25rem 0.275rem 0.75rem;

        option {
            line-height: initial !important;
        }
    }

    .header-bounds {
        height: 158px;
    }

    .header-inner-bounds {
        height: 28px;
    }

    .header-visual {
        height: 100px;
        background-image: url('../../images/header-visual.png');
    }

    .header-visual-bg {
        height: 130px;
    }

    .header-container {
        margin-top: -79px;
    }

    .corner {
        width: 34px;
        height: 100%;
    }

    .header-logo-container {
        width: 500px;
        height: 30px;
        margin-bottom: -1px;

        &.flipped {
            .corner {
                width: 34px;
            }
        }
    }

    .logo-container {
        bottom: 12px;
        width: 45%;
        text-align: initial;

        img {
            width: 100%;
        }

        .slogan {
            font-size: 0.7rem;
        }
    }

    .header-nav-bar {
        height: 30px;
    }

    .header-nav {
        width: 100%;
        left: initial;
        transform: translateX(0);
    }

    .nav-item {
        padding: 10px;
        width: initial;


        .headline {
            font-size: 0.8rem;
        }

        .label {
            font-size: 1.4rem;
        }
    }

    .footer-container {
        position: fixed;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        z-index: 1000;
        top: 100%;
        bottom: initial;

        &.show {
            top: 164px;
        }
    }

    .footer-nav-bar {
        position: fixed;
        display: flex;
        width: 100%;
        bottom: 0;
        height: 35px;
        background: $outridersDark;
        border-top: 1px solid $outridersNormal;
        border-bottom: 1px solid $outridersNormal;
        transition: all 0.3s ease-in-out;
    }

    .builder-tab {
        position: relative;
        display: flex;
        width: 100%;
    }

    .builderlibrary-tab-container {
        height: 35px;
    }

    .guides-btn {
        .headline {
            font-weight: 500;
            font-size: 0.8rem;
        }

        .label {
            font-weight: 600;
            font-size: 1.4rem;
        }
    }

    .field-label {

        .label-text {
            font-weight: 600;
            font-size: 0.85rem;
        }

        &.armory {
            margin-bottom: 5px;
        }

        &.segment-divider {
            .label-text {
                font-size: 1rem;
            }
        }
    }

    .field-group {
        &.flexed {
            flex: 0 0 auto;
            margin-bottom: initial;

            .field-input {
                width: 100%;
            }
        }
    }

    .field-input {
        height: 34px;
        width: 100%;
    }

    .common-god-rolls {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .estimates {
        position: relative;
        display: flex;
        width: 100%;
    }

    .estimate-col {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        padding-left: 5px;
        padding-right: 5px;
        flex-direction: column;

        .status {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            background: $outridersYellow;
            color: black;
            text-transform: uppercase;
            font-size: 0.65rem;
            padding: 5px;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 10px;

            &.unusual {
                background: $unusualColor;
                color: white;
            }

            &.rare {
                background: $rareColor;
                //background: $blue;
                color: white;
            }

            &.epic {
                background: $epicColor;
                //background: $green;
                color: white;
            }

            &.legendary {
                background: $legendaryColor;
                //background: #d2a341;
                color: white;
            }
        }

        .init-value {
            position: relative;
            display: flex;
            justify-content: center;
            font-size: 1.2rem;
            margin-bottom: 5px;
            line-height: 1;
        }

        .max-value {
            position: relative;
            display: flex;
            color: rgba(white, 0.5);
            justify-content: center;
            font-size: 1rem;
            line-height: 1;
        }
    }

    .action-btn {
        font-size: 1.2rem;

        .inner-label {
            &.responsive {
                display: initial;
            }
        }

        &.sm {
            font-size: 1rem;
            height: 35px;
        }

        &.xs {
            //
        }

        &.hide {
            display: none;
        }
    }

    .std-btn {
        font-size: 1.2rem;

        &.sm {
            height: 34px;
            font-size: 0.85rem;
        }
    }

    .result-stats {
        flex-direction: initial;
        flex-wrap: initial;
    }

    .stats {
        width: auto;
        padding: initial;
    }

    .result-short {
        font-size: 3.6rem;
    }

    .result-full {
        font-size: 1.4rem;
    }

    .segment-header-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .segment-header {
        margin-bottom: initial;
        max-width: 1140px;
    }

    .segment-header-headline {
        font-size: 1.2rem;
    }

    .segment-header-title {
        font-size: 2rem;
    }

    .segment-header-description {
        padding-left: 10%;
        padding-right: 10%;
    }

    .segment-text {
        padding-left: 10%;
        padding-right: 10%;
        line-height: 1.25;
        font-size: 0.85rem;
        margin-top: 10px;
    }

    .social-connect-container {
        margin-top: 10px;
    }

    .social-connect {
        .content {
            font-size: 2rem;
        }

        &:hover {
            border: 6px solid $outridersYellow;
        }
    }

    .segment-controls {
        align-items: center;
    }

    .profile-avatar {
        width: 40px;
    }

    .profile-nickname {
        flex: 0 0 auto;

        .label {
            font-size: 0.75rem;
            margin-bottom: initial;
        }

        .nickname {
            font-size: 1.4rem;
        }
    }

    .build-item-content {
        padding: 15px 25px 15px 145px;
    }

    .build-item-title {
        font-size: 1.1rem;
        line-height: 1;
        margin-bottom: 4px;
    }

    .build-item-desc {
        font-size: .8rem;
    }

    .build-item {
        width: 33.3%;
        height: 200px;
    }

    .character-module {
        width: 15%;

        .field-label {
            margin-top: initial;
        }
    }

    .build-creator-content {
        max-width: 1140px;
    }
    .build-meta {
        width: 30%;
    }

    .build-class-chooser {
        width: 55%;
        padding-top: 0;
    }

    .class-icon {
        width: 15%;
        margin-right: 20px;
    }

    .skill-chooser-unavailable {
        line-height: initial;
    }

    .skill-icon {
        width: 12.5%;
        padding: 10px;

        .skill-name {
            font-size: 0.7rem;
        }

        .skill-type {
            font-size: 0.65rem;
        }
    }

    .gear-title {
        font-size: 1.1rem;
        height: 60px;
        padding: 10px 10px 10px 10px;
    }

    .gear-attributes {
        .field-label {
            display: none;
        }
    }

    .armor-item {
        width: 33.3%;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 30px;
    }

    .weapon-item {
        width: 33.3%;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 30px;
    }

    .skill-tree-container {
        position: absolute;
        display: block;
        overflow-y: hidden;
        overflow-x: hidden;
        align-items: center;
        justify-content: center;
        padding: initial;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            //height: 100vh;
            height: auto;
            padding: 60px 30px 30px 30px;
            width: 100%;
            max-width: 2100px;

        }
    }
    .armory-item {
        width: 16.6%;
        margin-bottom: initial;

        &.explorer-armor {
            width: 50px;

            &.upper {
                width: 80px;
            }

            &.footgear {
                width: 65px;
            }
        }

        .item-title {
            font-size: 0.95rem;
        }
    }

    .mod-item {
        width: 10%;

        .item-title {
            font-size: 0.9rem;
        }
    }

    .explorer-controls {
        margin-top: 25px;
        margin-bottom: 0;
        padding-left: initial;
        padding-right: initial;
    }

    .explorer-sectionals {
        padding-top: 0;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 1140px;
    }

    .explorer-menu-tab {
        width: 300px;
        height: inherit;
    }

    .explorer-menu-tab-info {
        text-align: initial;
        padding-left: 10px;
        padding-right: 10px;

        .label {
            font-size: 1rem;
        }
    }

    .inner-content-explorer-tab {
        margin-top: -10px;
        padding-bottom: 10px;
    }

    .explorer-menu-header {
        height: 30px;
    }

    .explorer-menu-header-left {
        height: initial;
    }

    .explorer-menu-header-right {
        height: initial;
    }

    .explorer-menu-content {
        padding-bottom: 7px;
    }

    .explore-menu-carousel {
        padding-top: 10px;
    }

    .set-container-menu {
        padding-bottom: 5px;

        .explorer-sectional-item {
            margin-bottom: 10px;
        }

        .explorer-item-label {
            font-size: 0.7rem;
            font-weight: 600;
        }

        &.selected {
            .explorer-sectional-item {
                .inside-visual {
                    .content {
                        box-shadow: inset 0 0 0 4px $outridersYellow;
                    }
                }
            }
        }
    }

    .explorer-label-headline {
        font-size: 0.9rem;
    }

    .explorer-label-title {
        font-size: 1.8rem;
        max-width: 40%;
    }

    .armory-pieces {
        width: 40%;
    }

    .explorer-label-default {
        font-size: 1.4rem;
        max-width: 45%;

        .text-label {
            &.sm {
                font-size: 1rem;

                &.bonus {
                    width: 100%;
                }
            }

            &.xs {
                font-size: 0.85rem;
                width: initial;
                max-width: initial;
            }
        }
    }

    .gradient-background {
        right: 150px;
        height: 100%;
        width: 70%;
        background-position: 110px center;
    }

    .explorer-stats {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 20px;

        .spacer {
            height: 20px;
        }
    }

    .explorer-stats-controls {
        width: 75px;
        align-self: flex-start;
        align-items: flex-start;
        height: 100%;
        margin-top: 7px;
    }

    .explorer-stats-controls-side {
        .stat-action {
            .content {
                img {
                    opacity: 0.1;
                }
            }
        }
    }

    .explorer-stats-details {
        align-self: flex-start
    }

    .explorer-figure {
        right: 125px;
        width: 50%;
        //height: 67vh;
        padding-left: 0;
    }

    //.character-visual {
    //    .content {
    //        height: calc(100% - 30px);
    //    }
    //}

    .explorer-rarity-container {
        select {
            height: 30px;
        }

        .edd-root {
            height: 34px;

            .edd-head {
                height: 34px;

                .edd-value {
                    line-height: 28px;
                }
            }
        }
    }

    .explorer-search-container {
        height: 34px;
    }

    .explorer-search-input {
        height: 34px;
    }

    .explorer-icons {
        width: 110%;
        //height: calc(50% + 10px);
        height: 150%;
        bottom: 29px;
    }
    .explorer-navigator {
        position: absolute;
        height: 150px;
    }
    .explorer-details-header {
        display: none;
    }
    .weapon-details {
        padding-top: 0;
    }
    .weapon-info {
        align-self: center;
    }
    @media (min-height: 700px) {
        .explorer-details-header {
            display: flex;
        }
    }
    @media (min-height: 900px) {
        .explorer-navigator {
            position: relative;
        }
        .explorer-figure {
            height: 65vh;
        }
        .explorer-stats-controls {
            align-self: flex-start;
            align-items: flex-start;
        }
        .explorer-stats-details {
            align-self: flex-start;
        }
        .armory-item {
            &.explorer-armor {
                width: 50px;

                &.upper {
                    width: 80px;
                }

                &.footgear {
                    width: 65px;
                }
            }
        }
    }
    @media (min-height: 1024px) {
        .explorer-details-header {
            display: flex;
        }
        .armory-pieces {
            width: 35%;
        }
        .explorer-sets-header {
            font-size: 3.2rem;
        }
        .explorer-navigator {
            position: relative;
        }
        .explorer-figure {
            //height: 70vh;
            //top: 50%;
            //transform: translateY(-52%);
        }
        .explorer-stats-controls {
            align-self: center;
            align-items: center;
        }
        .explorer-stats-details {
            padding-top: 0;
            align-self: center;
            margin: 0;
        }
        .character-visual {
            max-height: 920px;
        }
        .armory-item {
            &.explorer-armor {
                width: 50px;

                &.upper {
                    width: 80px;
                }

                &.footgear {
                    width: 65px;
                }
            }
        }
        .weapon-stats {
            max-width: 960px;
        }
    }
    @media (max-width: 1024px) and (min-height: 1300px) {
        .explorer-figure {
            top: 50%;
            transform: translateY(-50%);
        }
        .weapon-stats {
            position: relative;
            display: flex;
            width: 100%;
            flex-direction: column;
            max-width: 720px;
            height: 100%;
            margin: 0 auto;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 10px;
        }
        .weapon-firepower {
            position: absolute;
            left: 10px;
            top: -25px;
            width: auto;
            border-radius: 2px;
            font-weight: 600;
            padding: 3px;
            background: $outridersYellow;
            color: black;
            font-size: 0.85rem;
        }
        .weapon-visual {
            position: relative;
            display: flex;
            height: inherit;
            flex: 0 0 auto;
            width: 100%;
            height: 100%;
            flex-direction: column;
            padding: 0 20px 0 20px;
            border-right: 1px solid $explorerLines;
            border-left: 1px solid $explorerLines;

            .content {
                position: absolute;
                //left: 20px;
                //top: 0;
                //width: calc(100% - 40px);
                height: 100%;
                background-image: none;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;

                &.assault-rifle {
                    //top: 0;
                    //width: calc(100% - 80px);
                    //left: 40px;
                }

                &.rifle, &.automatic-sniper-rifle, &.bolt-action-rifle {
                    //top: 0;
                    //width: calc(100% - 80px);
                    //left: 40px;
                }

                &.light-machinegun {
                    //top: -5px;
                    //height: calc(100% + 10px);
                }

                &.pistol {
                    //top: 5px;
                    //height: calc(100% - 10px);
                }

                &.double-gun {
                    //top: 0;
                    //height: calc(100%);
                }

                &.revolver {
                    //top: 5px;
                    //height: calc(100% - 10px);
                }

                &.automatic-shotgun {
                    //top: -10px;
                    //height: calc(100% + 20px);
                }

                &.pump-action-shotgun {
                    //top: 0;
                    //height: calc(100%);
                }

                &.submachine-gun {
                    //top: -5px;
                    //height: calc(100% + 10px);
                }
            }
        }
        .weapon-visual-with-stats {
            position: relative;
            width: 100%;
            height: 35%;
            display: flex;
            flex-direction: column;
        }
        .weapon-info {
            position: relative;
            display: flex;
            height: 50%;
            flex: 1 1 auto;
            padding-left: 5px;
            padding-right: 5px;
            padding-top: 10px;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
        }
        .weapon-details {
            position: relative;
            display: flex;
            width: 100%;
            flex-direction: row;
        }
        .weapon-basic {
            position: relative;
            display: flex;
            width: 55%;
            flex: 0 0 auto;
            padding-right: 5px;
            padding-left: 10px;
            flex-direction: column;
        }
        .weapon-attributes {
            position: relative;
            display: flex;
            width: 45%;
            flex: 0 0 auto;
            text-transform: uppercase;
            padding-right: 0;
            font-size: 0.65rem;
            font-weight: 600;
            margin-top: 3px;

            ul {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 0;
                margin: 0;
                width: 100%;
                list-style: none;
                margin-left: 0;
                padding-left: 1em;

                li {
                    position: relative;
                    display: flex;
                    flex: 0 0 auto;
                    line-height: 1.4;
                    width: 100%;
                    padding-top: 0;
                    padding-bottom: 0;
                    text-align: left;
                    //justify-content: center;
                    color: white;
                    //border-bottom: 1px solid $explorerLines;
                    border-bottom: none;

                    &:before {
                        display: inline-block;
                        content: "\e000";
                        color: white;
                        font-size: 0.9em;
                        vertical-align: middle;
                        font-family: 'Font Awesome Kit';
                        width: 1em;
                        margin-right: 0.3em;
                        margin-left: -1em;
                    }
                }
            }
        }
        .variant-stats-container {
            position: relative;
            display: flex;
            width: 100%;
            flex-direction: column;
            flex: 0 0 auto;

            &.horizontal {
                display: none;
            }
        }
        .weapon-variant-stat {
            position: relative;
            display: flex;
            flex: 1 1 auto;
            width: auto;
            flex-direction: column;
            padding: 5px;
            background: rgba(black, 0.3);
            border-right: 1px solid $explorerLines;

            &:last-child {
                border-right: none;
            }
        }
        .weapon-variant-value {
            position: relative;
            display: block;
            font-size: 1rem;
            color: white;
            line-height: 1;
        }
        .weapon-variant-label {
            position: relative;
            display: block;
            font-size: 0.65rem;
            line-height: 1;
            text-transform: uppercase;
            color: rgba(white, 0.5);
        }
        .weapon-variant-stats {
            position: relative;
            display: flex;
            width: 100%;
            //padding: 5px;
            text-align: center;
            //background: rgba(black, 0.5);
            justify-content: center;
            border: 1px solid $explorerLines;
            margin-top: 10px;

            &.accuracy {
                margin-top: -1px;

                .weapon-variant-value {
                    font-size: 0.8rem;
                    text-transform: uppercase;
                }

                .weapon-variant-label {
                    font-size: 0.6rem;
                }
            }
        }
        .weapon-title {
            width: 100%;
            text-align: left;
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 5px;
            color: white;
        }
        .weapon-type {
            width: 100%;
            text-align: left;
            font-size: 0.7rem;
            text-transform: uppercase;
            line-height: 1;
            color: rgba(white, 0.7);
        }
        .weapon-variant {
            width: 100%;
            text-align: left;
            font-size: 0.7rem;
            text-transform: uppercase;
            line-height: 1;
            color: rgba(white, 0.7);
        }
        .weapon-stats-info {
            position: relative;
            display: flex;
            width: 100%;
            font-size: 0.8rem;
            line-height: 1;
            margin-top: 15px;
            padding-left: 10px;
            flex-direction: column;

            .weapon-mods {
                position: relative;
                display: flex;
                flex: 0 0 auto;
                width: 100%;
                flex-direction: row;
            }
        }
        .weapon-mod-item {
            position: relative;
            display: flex;
            width: 50%;
            padding-right: 5px;

            &:first-child {
                margin-bottom: 0;
            }
        }
        .weapon-mod-icon {
            position: relative;
            display: flex;
            flex: 0 0 auto;
            height: auto;
            align-self: flex-start;
            width: 45px;
            @include aspect-ratio(1, 1);

            .content {
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;

                .mod-tier {
                    position: absolute;
                    display: inline-block;
                    top: 0;
                    width: 100%;
                    transform: translateY(-50%);
                    text-align: center;
                    color: white;
                    font-size: 0.75rem;
                    font-weight: bold;
                    z-index: 3;
                    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black;
                }

                .visual {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex: 0 0 auto;
                    width: 100%;
                    height: 100%;
                    background: black;
                    box-shadow: inset 0 0 0 1px $outline-color;
                    background-size: cover;
                    background-position: center top;
                    background-repeat: no-repeat;

                    &.sharpen {
                        filter: url('#Sharpen');
                        opacity: 0.7;
                    }
                }
            }
        }
        .weapon-mod-information {
            position: relative;
            display: flex;
            flex: 1 1 auto;
            padding-left: 10px;
            width: auto;
            justify-content: center;
            flex-direction: column;
        }
        .weapon-mod-title {
            position: relative;
            //display: flex;
            width: 100%;
            line-height: 1.1;
            font-size: 0.85rem;
            font-weight: 600;
            color: white;
            margin-bottom: 3px;
            margin-top: -1px;
        }
        .weapon-mod-description {
            position: relative;
            display: none;
            line-height: 1.1;
            width: 100%;
            font-size: 0.65rem;
            font-weight: 600;
            color: rgba(white, 0.7);

            em, strong {
                color: white;
            }

            .cooldown {
                font-size: 0.9em;
                font-family: "Font Awesome 6 Pro";
            }
        }
        .weapon-header {
            position: relative;
            display: none;
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 15px;
        }
        .weapon-info-headline {
            position: relative;
            display: flex;
            width: 100%;
        }
        .weapon-header-section-title {
            position: relative;
            display: flex;
            flex: 0 0 auto;
            font-size: 1.3rem;
            font-weight: bold;
            color: $outline-color;
            text-transform: uppercase;
            margin-right: 10px;
            margin-left: 10px;
            align-items: center;
            line-height: 0.8;
            letter-spacing: -0.05rem;
        }
        //
        .weapon-stats {
            max-width: 960px;
        }
        .weapon-visual-with-stats {
            height: 35%;
            margin-top: 70px;
        }
        .weapon-title {
            font-size: 2.2rem;
        }
        .weapon-attributes {
            font-size: 1rem;
        }
        .weapon-variant-stat {
            padding: 10px;
        }
        .weapon-variant-value {
            font-size: 1.2rem;
            margin-bottom: 5px;
            font-weight: 600;
        }
        .variant-stats-container {
            margin-top: 10px;
        }
        .weapon-variant-label {
            font-size: 0.8rem;
        }
        .weapon-variant-stats {
            &.accuracy {
                .weapon-variant-value {
                    font-size: 1rem;
                    font-weight: 600;
                }

                .weapon-variant-label {
                    font-size: 0.8rem;
                }
            }
        }
        .weapon-mod-icon {
            width: 75px;

            .mod-tier {
                font-size: 0.85rem;
            }
        }
        .weapon-type {
            font-size: 1rem;
        }
        .weapon-variant {
            font-size: 1rem;
        }
        .weapon-stats-info {
            margin-top: 30px;
        }
        .weapon-mod-information {
            justify-content: flex-start;
            padding-right: 10px;
        }
        .weapon-mod-title {
            font-size: 1.1rem;
            font-weight: 600;
        }
        .weapon-mod-description {
            display: block;
            font-size: 0.85rem;
            line-height: 1.2;
        }
        .weapon-visual {
            .weapon-header {
                display: flex;
            }

            .content {
                &.assault-rifle {
                    //top: 5%;
                    //height: calc(100% - 10%);
                    //width: calc(100% - 40%);
                    //left: 20%;
                }

                &.rifle, &.automatic-sniper-rifle, &.bolt-action-rifle {
                    //top: 5%;
                    //height: calc(100% - 10%);
                    //width: calc(100% - 30%);
                    //left: 15%;
                }

                &.light-machinegun {
                    //top: 5%;
                    //height: calc(100% - 10%);
                    //width: calc(100% - 40%);
                    //left: 20%;
                }

                &.pistol {
                    //top: 10%;
                    //height: calc(100% - 20%);
                    //width: calc(100% - 70%);
                    //left: 35%;
                }

                &.double-gun {
                    //top: 5%;
                    //height: calc(100% - 10%);
                    //width: calc(100% - 40%);
                    //left: 20%;
                }

                &.revolver {
                    //top: 10%;
                    //height: calc(100% - 20%);
                    //width: calc(100% - 70%);
                    //left: 35%;
                }

                &.automatic-shotgun {
                    //top: 5%;
                    //height: calc(100% - 10%);
                    //width: calc(100% - 50%);
                    //left: 25%;
                }

                &.pump-action-shotgun {
                    //top: 5%;
                    //height: calc(100% - 10%);
                    //width: calc(100% - 50%);
                    //left: 25%;
                }

                &.submachine-gun {
                    //top: 10%;
                    //height: calc(100% - 10%);
                    //width: calc(100% - 60%);
                    //left: 30%;
                }
            }
        }
    }
}
