@media (min-width: 576px) {
    .header-bounds {
        height: 178px;
    }

    .header-inner-bounds {
        height: 28px;
    }
    .podcast-host {
        width: 20%;
    }
    .podcast-hosts {
        justify-content: center;
    }
    .segment-header-visual {
        @include aspect-ratio(16, 3);
    }

    .header-visual {
        height: 100px;
        background-image: url('../../images/header-visual.png');
    }

    .header-visual-bg {
        height: 150px;
    }

    .header-container {
        margin-top: -79px;
    }

    .corner {
        width: 34px;
        height: 100%;
    }

    .header-logo-container {
        width: 500px;
        height: 30px;
        margin-bottom: -1px;

        &.flipped {
            .corner {
                width: 34px;
            }
        }
    }

    .logo-container {
        bottom: -5px;
        width: 60%;
        text-align: initial;

        img {
            width: 100%;
        }

        .slogan {
            font-size: 0.7rem;
        }
    }

    .header-nav-bar {
        height: 50px;
    }

    .header-nav {
        width: 100%;
        left: initial;
        transform: translateX(0);
    }

    .segment-header {
        max-width: 520px;
    }
    .segment-content-container {
        max-width: 480px;
    }
    .armory-item {
        width: 33.3%;
    }
    .mod-item {
        width: 25%;
    }
    .explorer-stats-controls {
        width: 50px;
    }
    .nav-item {
        padding: 10px;
        width: initial;


        .headline {
            font-size: 0.9rem;
        }

        .label {
            font-size: 1.5rem;
        }
    }

    .segment-header {
        //max-width: 480px;
    }
    .build-creator-content {
        max-width: 520px;
    }

    .build-class-chooser {
        padding-top: 10px;
    }

    .armor-item, .weapon-item {
        width: 50%;
    }

    .class-chooser {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 15px !important;
    }

    .explorer-icons {
        height: 185%;
        bottom: 29px;
    }
    .explorer-figure {
        width: calc(100% - 45%);
        //height: 55vh;
        right: 55px;
    }
    .explorer-label-title {
        font-size: 1.8rem;
    }
    .explorer-label-headline {
        font-size: 0.85rem;
    }
    .explorer-label-default {
        font-size: 1.2rem;

        .text-label {
            &.sm {
                font-size: 0.95rem;

                &.bonus {
                    width: 42%;
                }
            }
        }
    }
    .explorer-navigator {
        position: absolute;
    }

    @media (min-height: 760px) {
        .explorer-navigator {
            position: relative;
        }
    }
}

