#mobile-detect {
    display: none;
    content: 'mq-mobile';
}

#breakpoint-detect {
    display: none;
    content: '320';
}

.mejs__container {
    width: 100% !important;
}

.explorer-menu-bounds {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 70px;
    z-index: 0;
}

.credits-pages {
    position: relative;
    display: flex;
    height: 370px;
    width: 100%;
    overflow: hidden;
}

.help-pages {
    position: relative;
    display: flex;
    height: 370px;
    width: 100%;
    overflow: hidden;
}

.credits-pages-slider {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    flex: 0 0 auto;
}

.help-pages-slider {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    flex: 0 0 auto;
}

.credits-page {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    flex-direction: column;

    .segment-back-btn.std-btn {
        margin-top: auto !important;
    }
}

.help-page {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    flex-direction: column;

    .segment-back-btn.std-btn {
        margin-top: auto !important;
    }
}

#credits-tooltip {
    display: flex;
    position: fixed;
    bottom: 45px;
    left: -310px;
    width: 300px;
    transition: left 0.3s ease-in-out;
    z-index: 1007;

    .twitch {
        color: #BE4BDB;
    }

    .youtube {
        color: #FF0402;
    }

    .twitter {
        color: #287CC9;
    }

    .credits-links {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .credits-social-description {
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        text-decoration: none;
        color: white;
    }

    .credits-social {
        position: relative;
        display: flex;
        width: 100%;
        padding: 5px;
        line-height: 1;
        align-items: center;

        a {
            color: rgba(white, 0.7);
            cursor: pointer;
            text-decoration: none;

            &:hover {
                color: white;
            }
        }

        &.creators {
            font-size: 1rem;
        }
    }

    .paypal-btn {
        position: relative;
        display: flex;
        padding: 5px;
        font-color: white;
        background: #025EA6;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        text-decoration: none;

        i {
            //color: #1E264F;
            font-size: 1rem;
        }

        margin-bottom: 10px;

        &:hover {
            background: lighten(#025EA6, 15%);
        }
    }

    .tooltip-messaging-body {
        max-width: 100%;
        width: 100%;
        padding: 10px 15px 10px 15px;

        .credits-subheader {
            font-size: 1rem;
            font-weight: 500;
            color: $outridersYellow;
            line-height: 1;
            margin-bottom: 10px;

            i {
                color: red;
            }
        }

        .credits-header {
            font-size: 1.2rem;
            font-weight: 600;
            text-transform: uppercase;
            color: white;
            margin-bottom: 0;
        }

        p {
            font-size: 0.9rem;
            line-height: 1.2;
        }
    }

    &.show {
        left: 10px;
    }
}

#help-tooltip {
    display: flex;
    position: fixed;
    bottom: 45px;
    right: -310px;
    width: 300px;
    transition: right 0.3s ease-in-out;
    z-index: 1007;

    .twitch {
        color: #BE4BDB;
    }

    .youtube {
        color: #FF0402;
    }

    .twitter {
        color: #287CC9;
    }

    .help-links {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .help-description {
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        text-decoration: none;
        color: white;
    }

    .tooltip-messaging-body {
        max-width: 100%;
        width: 100%;
        padding: 10px 15px 10px 15px;

        .help-subheader {
            font-size: 1rem;
            font-weight: 500;
            color: $outridersYellow;
            line-height: 1;
            margin-bottom: 10px;

            i {
                color: red;
            }
        }

        .help-header {
            font-size: 1.2rem;
            font-weight: 600;
            text-transform: uppercase;
            color: white;
            margin-bottom: 0;
        }

        p {
            font-size: 0.9rem;
            line-height: 1.2;

            strong, em {
                color: white;
                font-weight: 500 !important;
            }
        }
    }

    &.show {
        right: 10px;
    }
}

//.tpd-skin-dark {
//    .tpd-content-relative-padder {
//        background: black;
//        padding-right: 0 !important;
//    }
//
//    .tpd-content-relative {
//        background: black;
//    }
//}

#portrait-notice {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    display: none;
    pointer-events: none;
    color: white;
    line-height: 1;
    text-align: center;
    padding: 20%;
    font-size: 1.6rem;
    font-weight: 600;
    z-index: 2000;
    align-items: center;
    background: $outridersDark;
    justify-content: center;

    span {
        i {
            font-size: 3rem;
            margin-bottom: 10px;
        }
    }

    &.show {
        display: flex;
    }
}

body {
    overflow-x: hidden;
}

.hourglass {
    position: relative;
    display: inline-flex;
    margin-left: 1px;
    margin-right: 1px;
    vertical-align: middle;
    height: inherit;
    padding-bottom: 4px;

    &:before {
        content: "\23f3";
        color: white;
        font-size: 0.8em;
        vertical-align: middle;
        font-family: "Font Awesome 6 Pro";
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
}

#globalFilters {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
}

// tooltips
.tooltip-messaging-body {
    font-size: 0.8rem;
    color: rgba(white, 0.5);
    border: 1px solid $outline-color;
    padding: 10px 10px 0 10px;
    max-width: 250px;
    background: rgba(black, 1);

    img {
        margin-bottom: 10px;
    }
}

#screenshot-upload-tooltip {
    display: none;
}

#info-screenshot-icon {
    color: $outridersYellow;
    cursor: pointer;
}

// select modifications for attributes on gear builder
.form-select {
    padding: 0.175rem 2.25rem 0.175rem 0.75rem;
    width: 100%;
    height: 34px;

    option:not(:first-of-type) {
        color: white;
    }

    option {
        font-size: 1em;
        line-height: 40px !important;
    }

    &.changed {
        color: white;
    }
}

#screenshot-upload {
    display: none;
}

.screenshot-file-upload {
    border: 1px solid $outline-color;
    display: inline-block;
    padding: 6px 12px;
    color: rgba(white, 0.5);
    background: black;
    cursor: pointer;
    width: 100%;
    text-align: center;

    i {
        margin-right: 5px;
        color: $outridersYellow;
    }
}

.form-control {
    color: #212529;
    background-color: black;
    border: 1px solid $outline-color;
    border-radius: 0;
}

// tooltip override
.tpd-visible-frame-top {
    .tpd-frames {
        .tpd-frame.tpd-frame-top {
            .tpd-shift-stem {
                .tpd-stem.tpd-stem-reset {
                    .tpd-stem-spacer {
                        .tpd-stem-notransform {
                            .tpd-stem-triangle {
                                border-bottom-color: black !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.tpd-visible-frame-left {
    .tpd-frames {
        .tpd-frame.tpd-frame-left {
            .tpd-shift-stem {
                .tpd-stem.tpd-stem-reset {
                    .tpd-stem-spacer {
                        .tpd-stem-notransform {
                            .tpd-stem-triangle {
                                border-right-color: #111314 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ps__thumb-y {
    background-color: rgba(white, 0.5);
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 6px;
    /* there must be 'right' for ps__thumb-y */
    right: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 10px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: rgba($outline-color, 0.7);
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: white;
    width: 6px;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
    background-color: rgba($outline-color, 0.5);
    opacity: 1;
}

body {
    background-color: #13171b;
    font-family: $outriders;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    height: 100vh;
    height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
    height: 100vh;
    height: -webkit-fill-available;
    //overflow: hidden;
}

.header-bounds {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 121px;
    z-index: 0;
}

.header-inner-bounds {
    position: absolute;
    width: 100%;
    height: 0;
}

.footer-bounds {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 35px;
    z-index: 0;
}

.header-visual {
    position: relative;
    display: flex;
    width: 100%;
    height: 75px;
    background-image: url('../images/header-visual-mobile.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
}

.header-visual-bg {
    position: absolute;
    display: flex;
    width: 100%;
    height: 80px;
    background-image: url('../images/header-visual-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
}

.header-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    //height: 100px;
    //background-color: #13171b;
    align-items: center;
    justify-content: flex-end;
    padding-top: 50px;
    margin-top: -75px;
}

.header-logo-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 25px;
    margin-bottom: -2px;
    z-index: 2;

    &.flipped {
        margin-bottom: 0;
        margin-top: -1px;

        .corner {
            background: #14171e;
            width: 23px;
            //display: none;
        }
    }
}

.corner {
    position: relative;
    display: flex;
    width: 24px;
    flex: 0 0 auto;
    height: 24px;

    &.left {
        .inner-corner {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            border-left: 1px solid $outridersNormal;
            transform: skewX(-45deg) translateX(50%);
            background: $outridersDark;

            &.dark {
                background: $outridersDark;
                height: inherit;
                border-top: 1px solid $outridersNormal;
            }
        }
    }

    &.right {
        .inner-corner {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            border-right: 1px solid $outridersNormal;
            transform: skewX(45deg) translateX(-50%);
            background: $outridersDark;

            &.dark {
                background: $outridersDark;
                height: inherit;
                border-top: 1px solid $outridersNormal;
            }
        }
    }

    &.flipped {
        transform: scaleY(-1);
    }

    &.explorer {
        width: 30px;
        height: inherit;
    }
}

.inner-content {
    position: relative;
    //display: flex;
    //flex: 1 1 auto;
    width: 100%;
    background: $outridersDark;
    border-top: 1px solid $outridersNormal;
    z-index: 1;

    &.flipped {
        border-top: none;
        border-bottom: 1px solid $outridersNormal;
    }
}

.logo-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);

    img {
        width: 55%;
    }

    .slogan {
        position: relative;
        display: flex;
        width: 100%;
        font-size: 0.7rem;
        line-height: 1;
        color: rgba(white, 0.7);
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        justify-content: center;
        margin-top: 5px;
    }
}

.header-nav-bar {
    position: relative;
    display: flex;
    width: 100%;
    height: 25px;
    background: $outridersDark;
    border-top: 1px solid $outridersNormal;
    border-bottom: 1px solid $outridersNormal;
}

.header-nav {
    position: absolute;
    width: 95%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    bottom: 0;
    overflow: hidden;
}

.explorer-icons {
    position: absolute;
    width: 110%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    height: 140%;
    bottom: 0;
    overflow: hidden;
}

.explorer-icon {
    position: relative;
    display: flex;
    top: 100%;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    flex-direction: column;

    &:nth-child(1) {
        .explorer-icon-visual {
            justify-content: flex-start;
        }
    }

    &:nth-child(2) {
        .explorer-icon-visual {
            justify-content: flex-end;
        }
    }
}

.explorer-icon-visual {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    //background: red;
    bottom: 0;
    left: 0;
    bottom: 0;

    img {
        height: 100%;
        width: auto;
    }
}

.nav-item {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: 33.3%;
    flex-direction: column;
    padding: 5px;
    line-height: 1;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    //transition: all 0.3s ease-in-out;

    .headline {
        position: relative;
        display: flex;
        width: 100%;
        font-weight: 500;
        line-height: 1;
        justify-content: center;
        color: rgba(white, 0.6);
        text-transform: uppercase;
        font-size: 0.6rem;
        transition: all 0.3s ease-in-out;
    }

    .label {
        position: relative;
        line-height: 1;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 600;
        color: rgba(white, 0.85);
        text-transform: uppercase;
        font-size: 1rem;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        border-bottom: 2px solid rgba(white, 0.3);

        .headline {
            color: rgba(white, 0.85);
        }

        .label {
            color: white;
        }
    }

    &.active {
        .headline {
            color: $outridersYellow;
        }

        .label {
            color: white;
        }

        border-bottom: 2px solid $outridersYellow;
    }
}

.footer-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    top: initial;
    bottom: 0;

    &.show {
        top: 164px;
    }
}

.guides-tab-container {
    position: relative;
    display: flex;
    width: 160px;
    height: 35px;
    margin: 0 auto;
    margin-bottom: -1px;
    z-index: 2;
    //transition: all 0.3s ease-in-out;
    //position: relative;
    //display: flex;
    //width: 150px;
    //height: 35px;
    //margin: 0 auto;
    //margin-bottom: -1px;
    //transform: translateX(-5px);
    //z-index: 2;
    //transition: all 0.3s ease-in-out;

    .corner {
        width: 35px;
        height: 35px;
    }
}

.guides-tab {
    position: relative;
    display: flex;
    width: 100%;
    height: 35px;
}

.guides-btn {
    position: absolute;
    width: inherit;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 10px 0 10px 0;
    line-height: 1;
    border-top: 2px solid transparent;
    cursor: pointer;

    .headline {
        position: relative;
        display: flex;
        width: 100%;
        font-weight: 500;
        line-height: 1;
        justify-content: center;
        color: rgba(white, 0.6);
        text-transform: uppercase;
        font-size: 0.7rem;
        //transition: all 0.3s ease-in-out;
    }

    .label {
        position: relative;
        line-height: 1;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 600;
        color: rgba(white, 0.85);
        text-transform: uppercase;
        font-size: 1.1rem;
        //transition: all 0.3s ease-in-out;
    }

    &:hover {
        border-top: 2px solid rgba(white, 0.3);

        .headline {
            color: rgba(white, 0.85);
        }

        .label {
            color: white;
        }
    }

    &.active {
        .headline {
            color: $outridersYellow;
        }

        .label {
            color: white;
        }

        border-top: 2px solid $outridersYellow;
    }
}

.guides-content {
    position: relative;
    display: flex;
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: transparent;
    background-image: none;
    background-size: cover;
    background-position: center top;
    background-repeat: repeat-x;
    //background: red;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    //transition: border 0s ease-in-out 0.5s;

    &.show {
        background-color: $outridersDark;

        &.outlines {
            border-top: 1px solid $outridersNormal;
            border-bottom: 1px solid $outridersNormal;
        }
    }
}

.builderlibrary-tab-container {
    position: relative;
    display: flex;
    width: 200px;
    height: 24px;
    margin: 0 auto;
    margin-bottom: -1px;
    z-index: 2;
    transition: all 0.3s ease-in-out;
}

.builder-library {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    top: 99%;
    z-index: 999;

    &.show {
        height: calc(100% - 148px);
    }

    &.explorer {
        //top: calc(100% - 50px);
        //height: 40px;
        height: 50px;
        top: calc(100% - 60px);
        z-index: 1001;
    }
}

.builderlibrary-content {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 23px);
    overflow: hidden;
    background: $outridersDark;
    border-top: 1px solid $outridersNormal;
    border-bottom: 1px solid $outridersNormal;
    padding-top: 25px;

    .segment-content.inner {
        padding-bottom: 0;
    }

    &.show {
        //opacity: 1;
    }
}

.footer-nav-bar {
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0;
    height: 35px;
    background: $outridersDark;
    border-top: 1px solid $outridersNormal;
    border-bottom: 1px solid $outridersNormal;
    //transition: all 0.3s ease-in-out;
}

.builder-tab {
    position: relative;
    display: flex;
    width: 100%;
}


.builderlibrary-btn {
    position: absolute;
    width: inherit;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 10px;
    line-height: 1;
    border-top: 2px solid transparent;
    cursor: pointer;

    .headline {
        position: relative;
        display: flex;
        width: 100%;
        font-weight: 500;
        line-height: 1;
        justify-content: center;
        color: rgba(white, 0.6);
        text-transform: uppercase;
        font-size: 0.8rem;
        transition: all 0.3s ease-in-out;
    }

    .label {
        position: relative;
        line-height: 1;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 600;
        color: rgba(white, 0.85);
        text-transform: uppercase;
        font-size: 1.4rem;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        border-top: 2px solid rgba(white, 0.3);

        .headline {
            color: rgba(white, 0.85);
        }

        .label {
            color: white;
        }
    }

    &.active {
        .headline {
            color: $outridersYellow;
        }

        .label {
            color: white;
        }

        border-top: 2px solid $outridersYellow;
    }
}

.explorer-bg-container {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 0;
    background-color: $outridersDark;
    //background-image: url('https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/explorer-bg.jpg');
    background-image: url('https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/explorer-lights.svg');
    //background: radial-gradient(circle, rgba(93, 99, 103, 0.5382528011204482) 0%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0) 100%);
    background-size: cover;
    background-position: center top;
    background-repeat: repeat-x;
    transition: background-position 0.3s ease-out;

    &.active {
        display: flex;
    }

    //&.armorsets {
    //    background-position: 120px -80px;
    //}
    //
    //&.weapons {
    //    background-position: center top;
    //}
}


.credits-btn {
    position: fixed;
    display: flex;
    font-weight: 500;
    line-height: 1;
    color: rgba(white, 0.6);
    text-transform: uppercase;
    font-size: 0.8rem;
    transition: all 0.3s ease-in-out;
    align-self: flex-start;
    padding: 10px;
    bottom: 0;
    left: 10px;
    z-index: 1005;
    cursor: pointer;
}

.help-btn {
    position: fixed;
    display: flex;
    font-weight: 500;
    line-height: 1;
    color: rgba(white, 0.6);
    text-transform: uppercase;
    font-size: 0.8rem;
    transition: all 0.3s ease-in-out;
    align-self: flex-start;
    padding: 10px;
    bottom: 0;
    right: 10px;
    z-index: 1005;
    cursor: pointer;
}

.parallax-background {
    position: absolute;
    width: 100%;
    //height: calc(100vh - 216px);
    height: 100%;
    margin: 0 auto;
    overflow: hidden;

    .visual-container {
        position: relative;
        display: flex;
        width: 300%;
        height: 100%;
        background-image: url('../images/outpost-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left top;

        //.visual-block {
        //    position: relative;
        //    width: 100%;
        //    height: 100%;
        //
        //    &:nth-child(1) {
        //        background: red;
        //    }
        //
        //    &:nth-child(2) {
        //        background: orange;
        //    }
        //
        //    &:nth-child(1) {
        //        background: green;
        //    }
        //}
    }
}

.segments-container {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    //border-right: 1px solid grey;
    //border-left: 1px solid grey;
    width: 100%;
    //height: calc(100vh - 216px);
    height: 100%;
    //max-width: 960px;
    margin: 0 auto;
    overflow: hidden;
}

.segments {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
}

.segment {
    position: absolute;
    color: white;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex: 0 0 auto;
    overflow: hidden;
    overflow-y: auto;

    &:nth-child(2) {
        left: 100%;
    }

    &:nth-child(3) {
        left: 200%;
    }

    &.builds {
        overflow: hidden;
        overflow-y: hidden;
    }
}

.segment-content {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 0 0 auto;
    margin: 0 auto;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 35px;

    &.inner {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 0;
    }

    &.scroll {
        overflow: hidden;
        overflow-y: auto;
    }
}

.field-label {
    position: relative;
    display: flex;
    margin-bottom: 5px;
    width: 100%;

    &.results {
        width: 100%;
    }

    &.armory {
        margin-bottom: 0;
    }

    .connector {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        align-items: center;

        .line {
            position: relative;
            display: flex;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid rgba(white, 0.05);
            //background-color: rgba(white, 0.1);
            &.dashed {
                border-style: dashed;
            }
        }
    }

    .label-text {
        position: relative;
        display: flex;
        font-weight: 500;
        color: rgba(white, 0.2);
        font-size: 0.7rem;
        line-height: 1;
        align-items: center;
        text-transform: uppercase;
        padding: 5px 10px 5px 10px;

        &.highlight-yellow {
            color: $outridersYellow;
        }

        &.highlight {
            border-radius: 3px;
            color: black;
            margin: 0 10px 0 10px;

            &.unusual {
                background: lighten(#6F7D65, 10%);
                color: black;
                font-weight: bold;
            }

            &.rare {
                background: lighten(#677E93, 10%);
                color: black;
                font-weight: bold;
            }

            &.epic {
                background: lighten(#6E687E, 10%);
                color: black;
                font-weight: bold;
            }

            &.legendary {
                background: lighten(#7A623C, 10%);
                color: black;
                font-weight: bold;
            }

            &.tiertype {
                background: $outridersYellow;
                color: black;
                font-weight: bold;
            }
        }

        &.large {
            font-size: 1.2rem;
        }
    }

    &.segment-divider {
        .label-text {
            font-size: 0.7rem;
            color: white;

            &.large {
                font-size: 0.85rem;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        .connector {
            .line {
                border-bottom: 1px solid rgba(white, 0.1);

                &.dashed {
                    border-style: dashed;
                }
            }
        }
    }
}

.field-group {
    position: relative;
    display: flex;
    //flex: 1 1 auto;
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;

    &.fixed {
        flex: 0 0 auto;
        width: auto;
        margin: 0 auto;
        display: flex;
        align-items: center;
        text-align: center;
    }

    &.armory {
        margin-bottom: 0;
    }

    &.half {
        width: 50%;
    }

    &.flexed {
        flex: 1 1 auto;
        margin-bottom: 0;

        .field-input {
            height: 34px;
            width: 100%;
        }
    }
}

.field-group-row {
    position: relative;
    display: flex;
    width: 100%;
}

input[type="number"] {
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.field-input {
    position: relative;
    display: flex;
    align-items: center;
    height: 34px;
    width: 100%;
    line-height: 34px;
    background-color: black;
    border: 1px solid $outline-color;
    border-radius: 0;
    color: white;
    padding-left: 10px;
    padding-right: 10px;

    &.left-aligned {
        text-align: left;
    }

    &.centered {
        text-align: left;
    }

    &:focus {
        outline: lighten($outline-color, 10%);
        border-radius: 0;
    }
}

.textarea-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 42px;
    resize: none;
    line-height: 1.22;
    padding-top: 7px;
    background-color: black;
    border: 1px solid $outline-color;
    border-radius: 0;
    color: white;
    padding-left: 10px;
    padding-right: 10px;

    &.left-aligned {
        text-align: left;
    }

    &.centered {
        text-align: left;
    }

    &:focus {
        outline: lighten($outline-color, 10%);
        border-radius: 0;
    }
}

.common-god-rolls {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.estimates {
    position: relative;
    display: flex;
    width: 100%;
}

.estimate-col {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;

    .status {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        background: $outridersYellow;
        color: black;
        text-transform: uppercase;
        font-size: 0.65rem;
        padding: 5px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 10px;

        &.unusual {
            background: $unusualColor;
            color: white;
        }

        &.rare {
            background: $rareColor;
            //background: $blue;
            color: white;
        }

        &.epic {
            background: $epicColor;
            //background: $green;
            color: white;
        }

        &.legendary {
            background: $legendaryColor;
            //background: #d2a341;
            color: white;
        }
    }

    .init-value {
        position: relative;
        display: flex;
        justify-content: center;
        font-size: 1.2rem;
        margin-bottom: 5px;
        line-height: 1;
    }

    .max-value {
        position: relative;
        display: flex;
        color: rgba(white, 0.5);
        justify-content: center;
        font-size: 1rem;
        line-height: 1;
    }
}

.action-btn {
    position: relative;
    display: block;
    background: #d2a341;
    border: 1px solid #ffd479;
    padding: 10px 15px 10px 15px;
    font-size: 1.1rem;
    color: black;
    flex: 0 0 auto;
    line-height: 1;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &.full {
        width: 100%;
    }

    .inner-label {
        display: initial;
        margin-right: 5px;

        &.responsive {
            display: none;
        }
    }

    &.sm {
        display: flex;
        padding: 5px 12px 5px 12px;
        font-size: 0.85rem;
        height: 30px;
        align-items: center;
        justify-content: center;
    }

    &.xs {
        display: flex;
        padding: 5px 12px 5px 12px;
        font-size: 0.85rem;
        height: 30px;
        align-items: center;
        justify-content: center;
    }

    &.hide {
        display: none;
    }

    &:hover {
        background: lighten(saturate(#d2a341, 20%), 10%);
        color: black;
    }
}

.std-btn {
    position: relative;
    display: block;
    background: $outridersDark;
    border: 1px solid $outline-color;
    padding: 10px 15px 10px 15px;
    font-size: 0.85rem;
    color: lighten($outline-color, 25%);
    flex: 0 0 auto;
    line-height: 1;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.sm {
        display: flex;
        padding: 5px 12px 5px 12px;
        font-size: 1rem;
        height: 35px;
        align-items: center;
        justify-content: center;
    }

    &.xs {
        padding: 5px 8px 5px 8px;
        font-size: 0.7rem;
        height: 25px;
    }

    &.compact {
        padding: 5px 12px 5px 12px;
        height: 30px;
        font-size: 0.85rem;
    }

    &:hover {
        background: black;
        color: white;
    }
}

#weapon_recommended {
    display: none;

    &.show {
        display: flex;
    }
}

#armor_recommended {
    display: none;

    &.show {
        display: flex;
    }
}

.weapon_tier_marker {
    display: none;

    &.show {
        display: flex;
    }
}

.armor_tier_marker {
    display: none;

    &.show {
        display: flex;
    }
}

.stats-results {
    position: relative;
    display: none;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;

    .field-label {
        margin-bottom: 10px;
    }

    &.weapons {
        &.show {
            display: flex;
        }
    }

    &.armor {
        &.show {
            display: flex;
        }
    }
}

.result-stats {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}

.stats {
    position: relative;
    display: none;
    width: 50%;
    padding: 5px;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;

    &.show {
        display: flex;
    }
}

.result-stat {
    position: relative;
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    flex-direction: column;
}

.result-short {
    position: relative;
    display: block;
    text-align: center;
    line-height: 1;
    color: white;
    font-weight: 600;
    font-size: 2.8rem;
}

.result-full {
    position: relative;
    display: block;
    text-align: center;
    line-height: 1;
    color: rgba(white, 0.5);
    font-size: 1.2rem;
}

.top-controls {
    position: absolute;
    width: 100%;
    display: flex;
    height: 3px;
    padding-left: 20px;
    padding-right: 20px;
}

.segment-header-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;

    &.explorer {
        padding-top: 0;
        flex-direction: column;
    }

}

.segment-header {
    position: relative;
    width: 100%;
    flex-direction: column;
    line-height: 1;
    margin: 0 auto;
    max-width: 1140px;
    margin-bottom: 0;
}

.segment-header-visual {
    position: relative;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    @include aspect-ratio(16, 5);
    //border-bottom: 3px solid #38586D;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    .content {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 70%;
        }
    }

    &.voices {
        background-image: url('https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/voices-of-enoch.jpg');
    }
}

.segment-header-headline {
    position: relative;
    width: 100%;
    text-align: center;
    line-height: 1;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(white, 0.5);

    &.simple {
        color: white;
        padding: 25px 15px 10px 15px;
    }
}

.segment-header-title {
    position: relative;
    width: 100%;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 600;
    color: white;
}

.segment-header-description {
    position: relative;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    line-height: 1.25;
    font-size: 0.85rem;
    font-weight: normal;
    color: rgba(white, 0.7);
    margin-top: 10px;
    margin-bottom: 10px;
}

.segment-text {
    position: relative;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    line-height: 1.3;
    font-size: 0.78rem;
    font-weight: normal;
    color: rgba(white, 0.7);
    margin-top: 5px;
}

.social-connect-container {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 0;
}

.social-connect {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    width: 25%;
    color: white;
    border-left: 1px solid rgba(white, 0.1);
    border-top: 1px solid rgba(white, 0.1);
    border-bottom: 1px solid rgba(white, 0.1);
    border-right: 1px solid rgba(white, 0);
    background: rgba(black, 0.15);
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    @include aspect-ratio(1, 1);

    .content {
        display: flex;
        padding: 20px;
        font-size: 1.6rem;
        justify-content: center;
        align-items: center;
    }

    &:last-child {
        border-right: 1px solid rgba(white, 0.1);
    }

    &:hover {
        background: $outridersDark;
        border: 3px solid $outridersYellow;
        //$outridersYellow
        color: $outridersYellow;
        z-index: 5;
    }
}

.divider-symbol {
    position: relative;
    display: flex;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    color: black;
    background: $outridersYellow;
    margin: 0 auto;
}

.subsegment-container {
    position: relative;
    width: 100%;
    display: flex;
    height: 100%;
    max-width: initial;
    margin: 0 auto;
    overflow: hidden;
}

.subsegment-container-carousel {
    position: absolute;
    width: 100%;
    display: flex;
    height: 100%;
}

.segment-content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: flex-start;
    flex: 0 0 auto;
    padding: 0;
    margin: 0 auto;
    max-width: 576px;
    padding-bottom: 20px;
    //height: calc(100% - 58px);

    &.wide {
        max-width: 100%;
        //padding-left: 20px;
        //padding-right: 20px;
    }

    &.builder-scroller {
        //height: calc(100% - 68px);
        padding-top: 0;
        padding-bottom: 0;
    }
}

.segment-controls {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
}

.profile-container {
    position: relative;
    display: flex;
    width: auto;
    flex: 0 0 auto;
}

.profile-avatar {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 30px;
    border: 1px solid $outline-color;
    background-color: black;
    background-image: none;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    margin-right: 10px;
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
    @include aspect-ratio(1, 1);
}

.profile-nickname {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    flex: 1 1 auto;

    .label {
        position: relative;
        display: flex;
        width: 100%;
        color: rgba(white, 0.5);
        text-transform: uppercase;
        font-size: 0.7rem;
        margin-bottom: 3px;
    }

    .nickname {
        font-size: 0.9rem;
    }
}

.profile-logout {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    align-items: flex-end;
    padding-bottom: 5px;
    margin-left: 8px;
    font-size: 1rem;
    color: $outridersYellow;
    cursor: pointer;
}

.build-list {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 320px);
    padding-right: 15px;
    padding-left: 15px;
    overflow: hidden;
    overflow-y: auto;
}

.build-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-content: flex-start;
    flex: 0 0 auto;
    padding-bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;

    &.wide {
        max-width: 100%;
    }

    &.armory {
        padding-left: 10px;
        padding-right: 10px;
    }

}

.build-creator-content {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1140px;
    height: auto;
    flex-direction: column;
    padding-bottom: 100px;
    margin: 0 auto;
}

.build-library {
    position: relative;
    display: flex;
    width: 100%;
    align-content: flex-start;
    flex: 0 0 auto;
    flex-wrap: wrap;
    max-width: 1140px;
    margin: 0 auto;
    padding-bottom: 35px;
}

.build-item-content {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background: #13171B;
    align-items: center;
    padding: 15px 25px 15px 115px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        .build-item-visual {
            border: 4px solid $outridersYellow;

            img {
                filter: none;
                mix-blend-mode: normal;
                opacity: 1;
            }
        }
    }
}

.build-item-visual {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //box-shadow: inset 0 0 0 1px $outline-color;
    transition: all 0.3s ease-in-out;
    border: 1px solid $outline-color;

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 0;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        //filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='grayscale'%3E%3CfeColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/%3E%3C/filter%3E%3C/svg%3E%0A#grayscale");
        //mix-blend-mode: luminosity;
    }
}

.build-item-info {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 5;
}

.build-item-title {
    font-size: 1rem;
    line-height: 1.25;
    color: rgba(white, 1);
    margin-bottom: 3px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.build-item-desc {
    font-size: .7rem;
    line-height: 1.2;
    color: rgba(white, 0.5);
    transition: all 0.3s ease-in-out;
}

.build-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;
    width: 100%;
    height: 175px;
    padding: 5px;
}

.notice-group {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    line-height: 1.2;
    font-size: 3rem;

    .title {
        font-size: 2.6rem;
        text-transform: uppercase;
        color: rgba(white, 0.7);
        line-height: 1;
        text-align: center;
    }

    .description {
        font-size: 1rem;
        color: rgba(white, 0.5);
        margin-top: 5px;
        text-align: center;
    }
}

.class-chooser {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}

.character-module {
    flex: 0 0 auto;
    width: 100%;
    display: none;

    &.active {
        display: block;
    }

    .field-label {
        margin-top: 10px;
    }
}

.build-meta {
    flex: 0 0 auto;
    width: 100%;
}

.build-class-chooser {
    flex: 1 1 auto;
    width: 100%;
}

.character-image-column {
    position: relative;
    width: 100%;
    height: 380px;
}

.character-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: inherit;

    img {
        height: 100%;
    }

    &.active {
        display: flex;
    }

    .remove-character {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}

.class-icon {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 25%;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    svg {
        width: 100%;
        height: auto;

        .icon-gradient {
            transition: all 0.3s ease-in-out;
            opacity: 0;
        }

        .icon-symbol {
            transition: all 0.3s ease-in-out;
            fill: rgba(white, 0.5);
        }
    }

    &:hover {
        svg {
            .icon-symbol {
                transition: all 0.3s ease-in-out;
                fill: rgba(white, 1);
            }
        }
    }

    &.selected {
        svg {
            .icon-gradient {
                transition: all 0.3s ease-in-out;
                opacity: 1;
            }

            .icon-symbol {
                transition: all 0.3s ease-in-out;
                fill: rgba($outridersYellow, 1);
            }
        }
    }
}

.skills-container {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    overflow: hidden;
    max-width: 992px;
    margin: 0 auto;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5px;
    padding-right: 5px;
}

.skill-chooser {
    position: relative;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px 0 5px 0;
}

.skill-chooser-unavailable {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    line-height: 1.1;
    font-weight: normal;
    padding-bottom: 50px;
    color: rgba(white, 0.3);
}

.skills-carousel {
    position: relative;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
}

.skill-icon {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 25%;
    padding: 5px;
    flex-direction: column;
    cursor: pointer;

    .skill-visual {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        background-color: rgba(black, 0.3);
        filter: url("#grayscaleFilter");
        background-image: none;
        background-size: cover;
        background-position: top left;
        background-repeat: no-repeat;
        transition: all 0.3s ease-out;

        @include aspect-ratio(1, 1);

        .content {
            transition: all 0.3s ease-out;
            border: 1px solid $outline-color;
        }
    }

    .skill-name {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        color: rgba(white, 0.6);
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        font-size: 0.6rem;
        justify-content: center;
        text-align: center;
        margin-bottom: 5px;
        margin-top: 10px;
        transition: all 0.3s ease-out;
    }

    .skill-type {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        color: rgba(white, 0.4);
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        font-size: 0.6rem;
        justify-content: center;
        text-align: center;
    }

    &:hover {
        .skill-visual {
            .content {
                border: 1px solid rgba(white, 0.7);
            }
        }

        .skill-name {
            color: rgba(white, 0.8);
        }
    }

    &.selected {
        .skill-visual {
            filter: none;

            .content {
                border: 1px solid black;
                box-shadow: inset 0 0 0 3px $outridersYellow;
            }
        }

        .skill-name {
            color: $outridersYellow;
        }

        .skill-type {
            color: rgba(white, 0.8);
        }
    }
}

.class-trees {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5em;
    background-color: $outridersYellow;
    z-index: 1001;

    &.final {
        border-radius: 0;
    }
}

#class-tree-btn {
    height: 40px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;

    &.inflate {
        //border-radius: 5em;
        //width: 40px;
        span {
            opacity: 0;
        }
    }

    &.unavailable {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.classtree-container {
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    color: white;
}

.class-trees-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;

    &.active {
        visibility: visible;
    }
}

.classtree-close-btn {
    position: absolute;
    top: 5px;
    right: 15px;
    color: white;
    font-size: 2rem;
    color: $outridersYellow;
    cursor: pointer;
    z-index: 1003;

    &:hover {
        color: white;
    }
}

.gear-container {
    position: relative;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    flex-direction: row;
}

.gear-icon {
    position: relative;
    width: 100%;
    flex: 0 0 auto;
    align-self: flex-start;
    background: rgba(black, 0.7);
    box-shadow: inset 0 0 0 2px $outline-color;
    color: $outridersYellow;
    transition: all 0.3s ease-out;
    cursor: pointer;
    @include aspect-ratio(3, 2);

    .content {
        display: flex;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        top: 8px;
        left: 8px;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        transition: all 0.3s ease-in-out;
        background-image: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        pointer-events: none;
        filter: url(#dropshadow);

        &.sharpen {
            opacity: 0.25;
            filter: url(#Sharpen);
        }
    }

    &:hover {
        color: white;
        box-shadow: inset 0 0 0 4px $outridersYellow;
    }

    &.populated {
        background: transparent;

        .content > * {
            display: none;
            box-shadow: inset 0 0 0 4px white;
        }
    }
}

.gear-icon-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    &.common {
        background: $commonColor;
        background: linear-gradient(145deg,
            #6b6c6c 0%,
            #a5a5a6 50%,
            #6b6c6c 100%);
    }

    &.unusual {
        background: $unusualColor;
        background: linear-gradient(145deg,
            #415136 0%,
            #829670 50%,
            #415136 100%);
    }

    &.rare {
        background: $rareColor;
        background: linear-gradient(145deg,
            #3a4e5e 0%,
            #6d8fa7 50%,
            #3a4e5e 100%);
    }

    &.epic {
        background: $epicColor;
        background: linear-gradient(145deg,
            #515073 0%,
            #877699 50%,
            #515073 100%);
    }

    &.legendary {
        background: $legendaryColor;
        background: linear-gradient(145deg,
            #5a4117 0%,
            #9b7b3e 50%,
            #5a4117 100%);
    }
}

.gear-title {
    position: relative;
    width: 100%;
    flex: 0 0 auto;
    font-size: 1rem;
    font-weight: 500;
    //text-transform: uppercase;
    height: 50px;
    color: rgba(white, 0.7);
    line-height: 1.15;
    padding: 8px 0 5px 0;
    text-align: center;
    justify-content: center;
}

.gear-mods {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    flex-direction: row;
}

.gear-mod {
    position: relative;
    display: flex;
    width: 50%;
    height: 100%;
    flex: 0 0 auto;
    flex-direction: column;
    //
}

.gear-mod-icon {
    position: relative;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    padding: 5px;
    cursor: pointer;

    .visual {
        position: relative;
        display: flex;
        width: 100%;
        flex: 0 0 auto;
        background: rgba(black, 0.7);
        //border: 1px solid $outline-color;
        color: $outridersYellow;
        transition: all 0.3s ease-out;
        @include aspect-ratio(1, 1);

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3rem;
            transition: all 0.3s ease-in-out;
            background-image: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            box-shadow: inset 0 0 0 1px $outline-color;
        }

        .mod-tier {
            position: absolute;
            display: none;
            top: -12px;
            font-size: 1em;
            left: 50%;
            font-weight: 600;
            transform: translateX(-50%);
            color: white;
            text-shadow: 1px 1px 3px black;
            z-index: 5;
        }
    }

    &:hover {
        .visual {
            background: rgba(black, 1);
            color: white;

            .content {
                box-shadow: inset 0 0 0 3px $outridersYellow;
            }
        }
    }

    &.populated {
        background: transparent;

        .visual {
            .mod-tier {
                display: initial;
            }
        }

        .content {
            box-shadow: inset 0 0 0 2px $outline-color;
        }

        .content i {
            display: none;
        }
    }
}

.gear-mod-label {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.6rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    //
    text-transform: uppercase;
    padding: 5px 5px 5px 5px;
    //height: 45px;
}

.gear-mods-container {
    position: relative;
    display: flex;
    width: 50%;
    flex-direction: column;
}

.gear-attributes-chooser {
    position: relative;
    display: flex;
    flex-direction: column;

    .edd-root {
        margin-bottom: 3px;
    }
}

.gear-attributes {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 5px;
    margin-bottom: 5px;

    .select2 {
        margin-bottom: 5px;
    }

    .field-label {
        display: none;

        &.forceshow {
            display: flex;
        }
    }
}

.gear-setbonus {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.gear-setbonus-info {
    position: relative;
    display: flex;
    line-height: 1;
    font-weight: normal;
    font-size: 0.9rem;
    width: 100%;
    padding: 0 10px 0 10px;
}

.gear-rarity-checkboxes {
    position: relative;
    display: flex;
    width: 100%;
}

.gear-rarity {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.gear-rarity-group {
    width: 100%;
    height: auto;

    > * {
        border-radius: 0;
        //border-color: $outline-color;
        //border: none;
    }

    .btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
        box-shadow: none;
    }

    .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
        box-shadow: none;
    }

    .btn-outline-primary {
        &.gear-rarity-btn {
            &.common {
                &:hover {
                    //color: $unusualColor;
                    background-color: $commonColor;
                    border: none;
                    box-shadow: inset 0 0 0 0 lighten($commonColor, 10%);
                    z-index: 3;
                }
            }

            &.unusual {
                &:hover {
                    //color: $unusualColor;
                    background-color: $unusualColor;
                    border: none;
                    box-shadow: inset 0 0 0 0 lighten($unusualColor, 10%);
                    z-index: 3;
                }
            }

            &.rare {
                &:hover {
                    //color: $rareColor;
                    background-color: $rareColor;
                    border: none;
                    box-shadow: inset 0 0 0 0 lighten($rareColor, 10%);
                    z-index: 3;
                }
            }

            &.epic {
                &:hover {
                    //color: $epicColor;
                    background-color: $epicColor;
                    border: none;
                    box-shadow: inset 0 0 0 0 lighten($epicColor, 10%);
                    z-index: 3;
                }
            }

            &.legendary {
                &:hover {
                    //color: $legendaryColor;
                    background-color: $legendaryColor;
                    border: none;
                    box-shadow: inset 0 0 0 0 lighten($legendaryColor, 10%);
                    z-index: 3;
                }
            }
        }
    }

    .btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
        &.gear-rarity-btn {
            box-shadow: inset 0 0 0 2px rgba(white, 0.3);

            > * {
                visibility: visible;
            }

            &.common {
                //color: $unusualColor;
                background-color: $commonColor;
                border: none;
            }

            &.unusual {
                //color: $unusualColor;
                background-color: $unusualColor;
                border: none;
            }

            &.rare {
                //color: $rareColor;
                background-color: $rareColor;
                border: none;
            }

            &.epic {
                //color: transparent;
                background-color: $epicColor;
                border-color: $epicColor;
                border: none;
            }

            &.legendary {
                //color: $legendaryColor;
                background-color: $legendaryColor;
                border: none;
            }
        }
    }
}

.gear-rarity-btn {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    color: white;

    > * {
        visibility: hidden;
    }

    &.common {
        background: $commonColor;
    }

    &.unusual {
        background: $unusualColor;
    }

    &.rare {
        background: $rareColor;
    }

    &.epic {
        background: $epicColor;
    }

    &.legendary {
        background: $legendaryColor;
    }
}

.armor-item {
    position: relative;
    display: flex;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px;
    flex-direction: column;

    &:last-child {
        border-right: none;
    }
}

.weapon-item {
    position: relative;
    display: flex;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column;
    margin-bottom: 30px;
}

.builder-main-gear-label {
    width: 50%;
    padding-right: 0;
    padding-left: 0;

    .label-text {
        color: rgba(white, 1);
    }
}

.builder-attributes-label {
    width: 50%;
    padding-right: 0;
    padding-left: 0;
}

.skill-tree-container {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    align-items: initial;
    justify-content: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 10px;
    background-image: url('../images/tree-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
    @include aspect-ratio(145, 67);

    svg {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) translateX(0);
        height: 100%;
        padding: 60px 30px 30px 30px;
        width: auto;
        max-width: 2100px;
    }

    #classtree {
        width: 100% !important;

        defs#image3, defs#image0, defs#image1, defs#image2 {
            image-rendering: optimizeSpeed;
            image-rendering: -moz-crisp-edges;
            image-rendering: -o-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: pixelated;
            image-rendering: optimize-contrast;
            -ms-interpolation-mode: nearest-neighbor;
        }

        #backgrounds {
            .class-visual {
                filter: url("#grayscaleFilter");
                opacity: 0.5;
                //opacity: 0;
                //transition: all 0.3s ease-in-out;

                &.active {
                    opacity: 1;
                    filter: none;
                }
            }
        }

        #skill-tree {
            #connectors {
                > * {
                    stroke: rgba(white, 0.3);
                    stroke-width: 2;
                    opacity: 1;

                    &.available {
                        stroke: white;
                    }

                    &.active {
                        stroke: $outridersYellow;
                    }
                }
            }

            #nodes {
                > *:not(g) {
                    stroke: #5E6062;
                    stroke-width: 2;
                    filter: url("#grayscaleFilter");
                    fill-opacity: 0.5;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    opacity: 1;

                    &.available {
                        stroke: white;
                        stroke-width: 2;
                        filter: url("#grayscaleFilter");
                        fill-opacity: 1;
                    }

                    &.active {
                        stroke: $outridersYellow;
                        stroke-width: 3;
                        filter: none;
                        fill-opacity: 1;
                    }
                }

                > g:not(.active-root) {
                    cursor: pointer;

                    *[id*='icon'] {
                        fill: #5E6062;
                        stroke: none;
                        stroke-width: 0;
                        filter: none;
                        fill-opacity: 1;
                        transition: all 0.3s ease-out;
                        opacity: 1;

                        > * {
                            fill: #5E6062;
                            transition: all 0.3s ease-out;
                        }
                    }

                    *[id*='bg'] {
                        stroke: #5E6062;
                        stroke-width: 2;
                        fill-opacity: 1;
                        transition: all 0.3s ease-out;
                        opacity: 1;
                    }

                    &.available {
                        *[id*='icon'] {
                            fill: white;

                            > * {
                                fill: white;
                            }
                        }

                        *[id*='bg'] {
                            stroke: white;
                            stroke-width: 3;
                        }
                    }

                    &.active {
                        *[id*='icon'] {
                            fill: $outridersYellow;

                            > * {
                                fill: $outridersYellow;
                            }
                        }

                        *[id*='bg'] {
                            stroke: $outridersYellow;
                        }
                    }
                }

                .active-root {
                    stroke: none;
                    fill: none;
                    stroke-width: 0;
                    filter: none;
                    fill-opacity: 1;

                    &.active {
                        stroke: none;
                        fill: none;
                        stroke-width: 0;
                        filter: none;
                        fill-opacity: 1;
                    }
                }
            }
        }

        #class-labels {
            > * {
                fill: rgba(white, 0.5);
                opacity: 1;
                transition: all 0.3s ease-in-out;

                &.active {
                    fill: white;
                }
            }
        }
    }

    .svg-class-title {
        font-family: $outriders;
        font-size: 2rem;
        text-align: right;
        font-weight: normal;
        fill: white;
    }
}

#resetTreeBtn {
    position: relative;
    margin-bottom: 0;
    margin-left: 5px;
}

.skill-tree-points-container {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 5px;
    left: 0;
    width: 100%;
    z-index: 1002;
    padding: 5px 10px 5px 10px;
}

.skilltree-label {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1;
    flex: 0 0 auto;
    text-transform: uppercase;
    color: rgba(white, 0.5);
    margin-left: 5px;
    margin-right: 60px;
}

.skilltree-points {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    flex: 0 0 auto;
    width: auto;

    span {
        font-family: $outriders;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1;
        color: $outridersYellow;
    }

    img {
        height: 1.6rem;
        margin-right: 5px;
    }
}

.node-tooltip-title {
    position: relative;
    display: flex;
    width: 100%;
    padding: 5px 10px 5px 10px;
    color: white;
    font-size: 1.2rem;
    background: black;
    line-height: 1;
    font-weight: 600;

    &.active {
        color: $outridersYellow;
    }

    &.root-class {
        color: $outridersYellow !important;
    }
}

.node-tooltip-subtitle {
    position: relative;
    display: flex;
    width: 100%;
    padding: 6px 10px 6px 10px;
    color: white;
    font-size: 0.8rem;
    text-transform: uppercase;
    background: #555A5D;
    overflow: hidden;
    line-height: 1;
    font-weight: bold;

    &.root-class {
        font-weight: 500;
        font-size: 1rem;
        color: rgba(white, 0.5);
        text-transform: initial;
        background: #393d41;
    }
}

.node-tooltip-visual {
    position: relative;
    display: flex;
    width: 100%;
    @include aspect-ratio(16, 9);
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
}

.node-tooltip-skillgroup {
    position: relative;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
}

.node-tooltip-dark-headline {
    position: relative;
    width: 100%;
    color: white;
    font-size: 0.85rem;
    line-height: 1.25;
    font-weight: 600;
}

.node-tooltip-dark-text {
    position: relative;
    width: 100%;
    color: white;
    font-size: 0.85rem;
    line-height: 1.25;
    font-weight: 500;
}

.node-tooltip-text-dark {
    position: relative;
    display: block;
    width: 100%;
    padding: 15px 10px 15px 10px;
    color: black;
    font-size: 1rem;
    background: rgb(57, 61, 65);
    background: radial-gradient(circle, rgba(57, 61, 65, 1) 0%, rgba(17, 19, 20, 1) 100%);
    line-height: 1.25;
    font-weight: 500;

    ul {
        padding: 0 15px 0 0;
        list-style: none;
        margin: 0;

        li {
            text-indent: -12px;
            margin-left: 15px;
            margin-bottom: 5px;
        }

        li:before {
            content: "\2022";
            font-family: $systemFont;
            font-size: 1rem;
            font-weight: bold;
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
}

.node-tooltip-text {
    position: relative;
    display: block;
    width: 100%;
    padding: 15px 10px 15px 10px;
    color: black;
    font-size: 1rem;
    background: white;
    line-height: 1.25;
    font-weight: 500;

    ul {
        padding: 0 15px 0 0;
        list-style: none;
        margin: 0;

        li {
            text-indent: -12px;
            margin-left: 15px;
            margin-bottom: 5px;
        }

        li:before {
            content: "\2022";
            font-family: $systemFont;
            font-size: 1rem;
            font-weight: bold;
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }

    &.root-class {
        font-size: 0.8rem;
        line-height: 1.1;
    }
}

.node-tooltip {
    position: relative;
    display: none;
    width: 100%;
    padding: 0;
    max-width: 300px;
}

.node-tooltip-skills {
    position: relative;
    display: block;
    width: 100%;
    background: lighten(#555A5D, 50%);
    color: white;
}

.node-tooltip-skills-title {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 10px 6px 10px;
    color: black;
    font-size: 0.8rem;
    text-transform: uppercase;
    overflow: hidden;
    line-height: 1;
    font-weight: 500;
}

.node-tooltip-skills-icons {
    position: relative;
    display: inline-block;
    width: 100%;
    flex: 0 0 auto;
    padding: 0 10px 6px 10px;
}

.node-tooltip-skill-icon {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 3px;
    border: 2px solid white;
    background-image: none;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

.armory-content {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 75px;
    //padding-top: 10px;
}

.armory-header {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    //padding-top: 5px;

    &:first-child {
        padding-top: 0;
    }
}

.armory-header-title {
    position: relative;
    display: flex;
    width: auto;
    flex: 0 0 auto;
    font-size: 1.3rem;
    font-weight: normal;
    color: white;
    align-items: center;
    padding: 0 5px 0 5px;
}

.armor-header-description {
    position: relative;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    font-size: 0.75rem;
    line-height: 1.22;
    font-weight: normal;
    color: rgba(white, 0.5);
    padding: 0 5px 0 5px;
}

.slanted-lines {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    height: inherit;
    margin-left: 10px;
    margin-right: 5px;
    padding-top: 1px;
    justify-content: flex-start;
    padding-left: 5px;
    padding-bottom: 1px;
    overflow: hidden;

    .slanted-line {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        background: white;
        width: 5px;
        margin-right: 5px;
        height: 100%;
        transform: skewX(-20deg);
    }
}

.armory-item {
    position: relative;
    display: flex;
    width: 50%;
    margin-bottom: 10px;
    flex: 0 0 auto;
    padding: 5px;
    flex-direction: column;

    &.explorer-armor {
        width: 30px;
        flex: 0 0 auto;
        padding: 0;


        .item-visual {
            @include aspect-ratio(3, 3);
            cursor: default;

            .content {
                border: none;
                box-shadow: none;
                padding: 0;

                .visual {
                    width: 100%;
                    height: 100%;
                }
            }

            .item-visual-bg {
                background: transparent;
            }

            &:hover {
                .content {
                    box-shadow: none;

                    .visual {
                        filter: none;
                    }
                }

                .item-visual-bg {
                    &.hovershadow {
                        display: none;
                    }
                }
            }
        }

        &.upper {
            width: 35px;

            .item-visual {
                @include aspect-ratio(4, 3);
            }
        }

        &.footgear {
            width: 35px;

            .item-visual {
                @include aspect-ratio(4, 3);
            }
        }
    }

    .item-visual {
        position: relative;
        width: 100%;
        cursor: pointer;
        @include aspect-ratio(3, 2);

        .item-visual-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: all 0.3s ease-out;

            &.common {
                background: $commonColor;
                background: linear-gradient(145deg,
                    #6b6c6c 0%,
                    #a5a5a6 50%,
                    #6b6c6c 100%);
            }

            &.unusual {
                background: $unusualColor;
                background: linear-gradient(145deg,
                    #415136 0%,
                    #829670 50%,
                    #415136 100%);
            }

            &.rare {
                background: $rareColor;
                background: linear-gradient(145deg,
                    #3a4e5e 0%,
                    #6d8fa7 50%,
                    #3a4e5e 100%);
            }

            &.epic {
                background: $epicColor;
                background: linear-gradient(145deg,
                    #515073 0%,
                    #877699 50%,
                    #515073 100%);
            }

            &.legendary {
                background: $legendaryColor;
                background: linear-gradient(145deg,
                    #5a4117 0%,
                    #9b7b3e 50%,
                    #5a4117 100%);
            }

            &.hovershadow {
                opacity: 0;
                background: rgba(0, 0, 0, 0.5);
                background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
            }
        }

        .content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            padding: 10px;
            z-index: 3;
            transition: all 0.3s ease-out;
            box-shadow: inset 0 0 0 2px rgba(white, 0.5);

            .visual {
                position: absolute;
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                background-image: none;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;

                &.sharpen {
                    opacity: 0.25;
                    filter: url(#Sharpen);
                }

                &.breathe {
                    width: calc(100% - 45px);
                    height: calc(100% - 45px);
                }
            }
        }

        &:hover {
            .content {
                box-shadow: inset 0 0 0 4px white;

                .visual {
                    filter: url(#dropshadow);

                    &.sharpen {
                        opacity: 0.25;
                        filter: url(#Sharpen);
                    }
                }
            }

            .item-visual-bg {
                &.hovershadow {
                    opacity: 1;
                    background: rgba(0, 0, 0, 0.5);
                    background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                }
            }
        }
    }

    .item-title {
        position: relative;
        width: 100%;
        color: white;
        line-height: 1;
        text-align: center;
        font-size: 0.8rem;
        margin-top: 5px;
    }
}

.mod-item {
    position: relative;
    display: flex;
    width: 33.3%;
    flex: 0 0 auto;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: column;

    .item-visual {
        position: relative;
        width: 100%;
        cursor: pointer;
        @include aspect-ratio(1, 1);
        //border: 2px solid rgba(white, 0.5);

        .content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            background-image: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;

            .tier-icon {
                position: absolute;
                //display: none;
                width: 100%;
                text-align: center;
                top: -13px;
                font-size: 1.1rem;
                font-weight: 600;
                color: white;
                text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
                //position: absolute;
                //width: 100%;
                //text-align: center;
                //top: -10px;
                //font-size: 1rem;
                //font-weight: 600;
                //color: white;
                //text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
            }

            .highlight {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s ease-out;
                box-shadow: inset 0 0 0 1px rgba($outline-color, 1);
                //background: linear-gradient(25deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%);
            }

            .filter {
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                flex: 0 0 auto;
                top: 0;
                left: 0;
                opacity: 0.25;
                background-image: none;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                filter: url(#Sharpen);
            }
        }

        &:hover {
            //border: 4px solid rgba(white, 1);

            .content {
                .highlight {
                    box-shadow: inset 0 0 0 3px rgba(lighten($outline-color, 15%), 1);
                }

                .filter {
                    opacity: 0.25;
                    filter: url(#Sharpen);
                }
            }
        }
    }

    .item-title {
        position: relative;
        width: 100%;
        color: white;
        line-height: 1.1;
        text-align: center;
        font-size: 0.85rem;
        margin-top: 5px;
    }
}

.armory-search-input {
    width: 100%;
    padding-right: 34px;
}

.armory-inputsearch-container {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    flex-direction: row;
}

.armory-filter-mods-container {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 85px;
    height: 100%;
    margin-left: 5px;

    &.armor {
        width: 125px;
    }

    select {
        height: 34px;
    }
}

.armory-filter-modtypes-container {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 100px;
    height: 100%;
    margin-left: 5px;

    select {
        height: 34px;
    }
}

.search-clear-btn {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    height: 34px;
    width: 34px;
    font-size: 1rem;
    color: white;
    cursor: pointer;

    &.show {
        display: flex;
    }

    &.explorer {
        height: 30px;
    }
}


.mod-item-tooltip {
    position: relative;
    display: none;
    width: 100%;
    padding: 0;
    max-width: 340px;
}

.mod-item-tooltip-header {
    position: relative;
    width: 100%;
    background: black;
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    padding: 10px;
}

.mod-item-tooltip-body {
    position: relative;
    width: 100%;
    background: white;
    color: black;
    font-weight: normal;
    font-size: 1rem;
    padding: 10px;

    .cooldown {
        font-size: 0.8em;
        font-family: "Font Awesome 6 Pro";
    }
}

#builds-login-section {
    display: flex;
    //visibility: initial;

    &.inactive {
        //visibility: hidden;
        display: none;
    }
}

#builds-library-section {
    display: flex;
    //visibility: initial;

    &.inactive {
        //visibility: hidden;
        display: none;
    }
}

#saveBuildBtn {
    //margin-left: auto;
}

.builder-right-controls {
    position: relative;
    display: flex;
    margin-left: auto;
}

#viewbuild-btn {
    display: flex;
    //margin-left: auto !important;

    &.inactive {
        display: none;
    }
}

// RENDER THE BUILD CSS
.build-render-container {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 2560px;
    height: 1440px;
    background: black;
    overflow: hidden;
    align-self: center;
    background-image: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;

    strong {
        color: white;
    }

    em {
        color: white;
    }
}

.character-render {
    position: absolute;
    top: 20px;
    left: 490px;
    width: 420px;
    height: 850px;
    background-image: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    z-index: 100;
}

.class-trees.render {
    z-index: 99;
    position: absolute;
    top: 10px;
    left: initial;
    right: 15px;
    width: 1790px;
    height: 845px;
}

.class-icon-render {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 150px;
    height: 150px;
    background-image: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
}

.render-build-info {
    position: absolute;
    left: 200px;
    top: 40px;
    width: 340px;
    color: white;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
}

.render-build-title {
    width: 100%;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.01em;
    color: $outridersYellow;
    margin-bottom: 10px;
}

.render-build-description {
    width: 100%;
    font-size: 1.1rem;
    padding-right: 10px;
    line-height: 1.22;
    font-weight: 500;
    color: white;
}

.skills-render {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 40px;
    top: 235px;
    width: 660px;
    min-height: 300px;
    //background: red;
}

.skill-item-rendered {
    position: relative;
    display: flex;
    width: 100%;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid rgba(white, 0.3);

    &:last-child {
        border-bottom: none;
    }
}

.skill-image-render {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 110px;
    height: 110px;
    border: 1px solid rgba(white, 0.3);
    padding: 5px;
    background: black;

    .visual-render {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        background-image: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
    }
}

.skill-info-render {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 360px;
    //background: blue;
    padding: 0 0 0 15px;
}

.skill-info-title-render {
    position: relative;
    width: 100%;
    font-weight: 500;
    line-height: 1;
    color: white;
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.skill-info-description-render {
    position: relative;
    width: 100%;
    //color: rgba(white, 0.7);
    color: $lightText;
    line-height: 1.25;
    font-size: 0.9rem;
}

.social-render {
    position: absolute;
    display: inline;
    left: 0;
    padding-left: 40px;
    top: 725px;
    width: 640px;
    min-height: 20px;
    background: rgba(black, 0.2);
    border-bottom: 1px solid rgba(white, 0.3);
    border-top: 1px solid rgba(white, 0.3);
}

.social-item-render {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    color: white;
    font-size: 1.3rem;
    padding-top: 15px;
    padding-bottom: 15px;

    &.twitch {
        i {
            color: #9147FF;
        }
    }

    &.youtube {
        i {
            color: #FF0000;
        }
    }

    &.discord {
        i {
            color: #5865F2;
        }
    }
}

.gear-render {
    position: absolute;
    width: 100%;
    display: flex;
    top: 913px;
    height: 527px;
    background: black;

    &.pyromancer {
        background: rgb(14, 1, 1);
        background: linear-gradient(180deg, rgb(22, 2, 2) 0%, rgb(43, 8, 8) 75%, rgb(22, 2, 2) 25%);
    }

    &.technomancer {
        background: rgb(3, 14, 1);
        background: linear-gradient(180deg, rgb(4, 14, 1) 0%, rgb(10, 33, 6) 75%, rgb(4, 14, 1) 25%);
    }

    &.devastator {
        background: rgb(14, 1, 1);
        background: linear-gradient(180deg, rgb(14, 1, 1) 0%, rgb(57, 37, 12) 75%, rgb(14, 1, 1) 100%);
    }

    &.trickster {
        background: rgb(1, 11, 14);
        background: linear-gradient(180deg, rgb(1, 9, 14) 0%, rgb(12, 43, 57) 75%, rgb(1, 9, 14) 100%);
    }
}

.gear-render-group {
    position: absolute;
    width: 100%;
    display: flex;
    top: -40px;
    left: 0;
    //height: calc(100% + 10px);
    height: 100%;
}

.gear-item-render {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 12.5%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgba(black, 0.7);
    margin-left: 5px;
    border-top: 3px solid transparent;

    &:last-child {
        margin-right: 5px;
    }

    .gear-item-render-title {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex: 0 0 auto;
        width: 100%;
        padding: 10px 15px 10px 15px;
        font-size: 1.6rem;
        line-height: 1;
        height: 70px;
        color: white;
        font-weight: 600;
        background: black;
    }

    .gear-item-render-type {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px 15px 5px 15px;
        font-size: 0.85rem;
        text-transform: uppercase;
        line-height: 1;
        height: auto;
        color: rgba(white, 0.7);
        font-weight: normal;
        background: #172024;
    }

    .gear-item-render-attribute {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px 15px 5px 15px;
        font-size: 1.1rem;
        line-height: 1;
        height: auto;
        color: rgba(black, 1);
        font-weight: 600;
        background: rgba(white, 0.7);
        border-bottom: 1px solid rgba(black, 0.1);
    }

    .gear-item-render-visual {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        height: 145px;
        background: black;
    }

    .gear-item-render-visual-inside {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        top: 6px;
        left: 6px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        background-image: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        //&.sidearm {
        //    top: 16px;
        //    left: 16px;
        //    width: calc(100% - 32px);
        //    height: calc(100% - 32px);
        //}
    }

    .gear-mods-render {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 205px;
        flex: 0 0 auto;
        background: rgb(196, 196, 196);
        background: radial-gradient(circle, rgba(196, 196, 196, 1) 0%, rgba(146, 146, 146, 1) 100%);
        padding: 10px 5px 10px 15px;

        strong, em {
            color: black;
        }
    }

    .gear-mod-render {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        padding-top: 5px;
        height: 50%;

        &:first-child {
            //margin-bottom: 20px;
        }

        //background: white;
    }

    .gear-mod-icon-render {
        position: relative;
        display: flex;
        width: 65px;
        height: 65px;
        flex: 0 0 auto;
        background-color: rgba(black, 0.5);
        border: 2px solid white;
    }

    .gear-mod-visual-render {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
    }

    .gear-mod-tier-render {
        position: absolute;
        width: 100%;
        color: white;
        font-weight: bold;
        top: -11px;
        text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
        text-align: center;
        z-index: 3;
    }

    .gear-mod-info-render {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        .cooldown {
            font-size: 0.8em;
            font-family: "Font Awesome 6 Pro";
        }
    }

    .gear-mod-title-render {
        color: black;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1;
        margin-bottom: 2px;
    }

    .gear-mod-description-render {
        color: black;
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 1.15;
    }

    &.common {
        border-top: 3px solid lighten($commonColor, 10%);

        .gear-item-render-visual {
            background: $commonColor;
            background: linear-gradient(145deg,
                #6b6c6c 0%,
                #a5a5a6 50%,
                #6b6c6c 100%);
        }
    }

    &.unusual {
        border-top: 3px solid lighten($unusualColor, 10%);

        .gear-item-render-visual {
            background: $unusualColor;
            background: linear-gradient(145deg,
                #415136 0%,
                #829670 50%,
                #415136 100%);
        }
    }

    &.rare {
        border-top: 3px solid lighten($rareColor, 10%);

        .gear-item-render-visual {
            background: $rareColor;
            background: linear-gradient(145deg,
                #3a4e5e 0%,
                #6d8fa7 50%,
                #3a4e5e 100%);
        }
    }

    &.epic {
        border-top: 3px solid lighten($epicColor, 10%);

        .gear-item-render-visual {
            background: $epicColor;
            background: linear-gradient(145deg,
                #515073 0%,
                #877699 50%,
                #515073 100%);
        }
    }

    &.legendary {
        border-top: 3px solid lighten($legendaryColor, 10%);

        .gear-item-render-visual {
            background: $legendaryColor;
            background: linear-gradient(145deg,
                #5a4117 0%,
                #9b7b3e 50%,
                #5a4117 100%);
        }
    }

}

.footer-render {
    position: absolute;
    width: 100%;
    min-height: 10px;
    //font-family: $systemFont;
    bottom: 5px;
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 5px 15px 5px 15px;
    background: black;
    color: rgba(white, 0.5);
    text-align:center;
}

.build-controls {
    position: relative;
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    padding: 5px;
    background: #13171B;
    border: 1px solid $outline-color;
    z-index: 5;
    margin-top: -1px;
}

.build-control-delete {
    position: relative;
    display: flex;
    margin-right: 5px;
    font-size: 1.2rem;
    padding: 5px;
    margin-left: auto;
    color: darken(red, 10%);
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
        color: red;
    }
}

.build-control-duplicate {
    position: relative;
    display: flex;
    margin-right: 5px;
    font-size: 1.2rem;
    padding: 5px;
    transition: all 0.3s ease-out;
    color: $outridersYellow;
    cursor: pointer;

    &:hover {
        color: white;
    }
}

.explorer-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 1px;
    //background: red;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    //display: none;

    &.active {
        height: 100%;
        opacity: 1;
    }
}

.explorer-content {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1140px;
    height: 100%;
    margin: 0 auto;
    flex-direction: column;
    overflow: hidden;
    //background: black;
}

.explorer-right-controls {
    position: relative;
    display: flex;
    margin-left: auto;
}

.explorer-controls {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
    padding-left: 0;
    padding-right: 0;
}

.explorer-headline {
    position: absolute;
    width: calc(100% - 10px);
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex: 1 1 auto;
    margin-right: 5px;
    margin-left: 5px;

    &.relative {
        display: flex;
        width: 100%;
        position: relative;
        height: auto;
        flex: 0 0 auto;
    }
}

.explorer-carousel {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    height: 100%;
    //overflow-x: hidden;
    //background: green;
}

.explorer-sectionals {
    position: relative;
    display: none;
    width: 100%;
    flex: 1 1 auto;
    height: 100%;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;

    &.active {
        display: flex;
    }

    .os-host {
        width: 100%;
        height: 100%;
    }
}

.explorer-navigator {
    position: absolute;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 130px;
    bottom: 0;
    left: 0;
    //padding-bottom: 10px;
    margin: 0 auto;
}

//
//.explorer-menu-tab {
//    position: relative;
//    display: flex;
//    width: 150px;
//    flex: 0 0 auto;
//}
.explorer-menu-tab {
    position: relative;
    display: flex;
    width: 200px;
    height: inherit;
    margin: 0 auto;
    flex: 0 0 auto;
    cursor: pointer;
    background: linear-gradient(0deg, rgba($outridersDark, 1) 26%, rgba($outridersDark, 0) 100%);
    z-index: 3;
}

.explorer-menu-tab-info {
    position: absolute;
    width: inherit;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 1;
    text-align: center;

    .headline {
        position: relative;
        display: flex;
        width: 100%;
        font-weight: 500;
        line-height: 1;
        justify-content: center;
        color: rgba(white, 0.6);
        text-transform: uppercase;
        font-size: 0.8rem;
        transition: all 0.3s ease-in-out;
    }

    .label {
        position: relative;
        line-height: 1;
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: 600;
        color: rgba(white, 0.65);
        text-transform: uppercase;
        font-size: 0.8rem;
        transition: all 0.3s ease-in-out;
    }
}

.inner-content-explorer-tab {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid $outridersNormal;
    width: 100%;
    margin-top: -10px;
    padding-bottom: 6px;
    z-index: 1;
    //cursor: pointer;

    &:hover {
        .explorer-menu-tab-info {
            .label {
                color: white;
            }
        }
    }
}

.explorer-menu-header {
    position: relative;
    display: flex;
    width: 100%;
    height: 30px;
    cursor: pointer;
}

.explorer-menu-header-left {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    height: inherit;
    justify-content: flex-end;
    background: $outridersDark;
    width: calc(50% - 150px);
    border-top: 1px solid $outridersNormal;
}

.explorer-menu-header-right {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    height: inherit;
    justify-content: flex-start;
    background: $outridersDark;
    width: calc(50% - 150px);
    border-top: 1px solid $outridersNormal;
}

.explorer-menu-content {
    position: relative;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    height: auto;
    padding-bottom: 10px;
    background: $outridersDark;
}

.bottom-explorer-btn {
    position: relative;
    display: flex;
    color: $outridersYellow;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 35px;
    height: 35px;
    padding-top: 5px;

    &.left {
        margin-left: auto;
        margin-right: 10px;
    }

    &.right {
        margin-right: auto;
        margin-left: 10px;
    }
}

.explore-menu-carousel {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding-top: 5px;
    //background: red;
    max-width: 1140px;
}

.set-container-menu {
    position: relative;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 5px;
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
    color: white;

    .explorer-sectional-item {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        background: black;
        margin-bottom: 5px;
        flex: 0 0 auto;

        .inside-visual {
            position: relative;
            display: flex;
            width: 100%;
            @include aspect-ratio(16, 9);


            .content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s ease-in-out;
                background-image: none;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top center;
                box-shadow: inset 0 0 0 2px lighten($outline-color, 10%);

                &.sharpen {
                    opacity: 0.5;
                    filter: url(#Sharpen);
                }
            }
        }
    }

    .explorer-item-label {
        text-align: center;
        font-size: 0.65rem;
        color: rgba(white, 0.7);
        text-transform: uppercase;
        font-weight: 500;
        line-height: 1;
        transition: all 0.3s ease-out;
    }

    &:hover {
        .explorer-item-label {
            color: white;
        }

        .explorer-sectional-item {
            .inside-visual {
                position: relative;
                display: flex;
                flex: 1 1 auto;

                .content {
                    box-shadow: inset 0 0 0 2px white;
                }
            }
        }
    }

    &.selected {
        .explorer-sectional-item {
            .explorer-item-label {
                color: $outridersYellow;
            }

            .inside-visual {
                .content {
                    box-shadow: inset 0 0 0 3px $outridersYellow;
                }
            }
        }
    }
}

.slick-slider {
    width: 100%;
    height: 100%;

}

.slick-track .slick-slide {
    display: flex;
    height: auto;
}

.slick-list,
.slick-track {
    height: 100%;
}

.slick-slide {
    > div:first-child {
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
}

.explorer-sections {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.explorer-section-carousel {
    position: relative;
    display: flex;
    width: auto;
    height: 100%;
}

.explorer-section {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.explorer-label-headline {
    position: relative;
    display: flex;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(white, 0.3);
    flex: 0 0 auto;
    margin-bottom: 5px;

    .text-label {
        position: relative;
        display: flex;
        align-self: flex-start;
        width: auto;
        flex: 0 0 auto;
        line-height: 1;
    }
}

.explorer-label-title {
    position: relative;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(white, 1);
    flex: 0 0 auto;
    max-width: 50%;

    .text-label {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        line-height: 1;
    }
}

.armory-pieces {
    position: relative;
    display: flex;
    width: 45%;
    flex: 0 0 auto;
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 5px;
}

.explorer-label-default {
    position: relative;
    display: flex;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: capitalize;
    color: rgba(white, 1);
    flex: 0 0 auto;
    text-transform: capitalize;
    max-width: 100%;

    .text-label {
        position: relative;
        //display: flex;
        //flex: 0 0 auto;
        width: 100%;
        line-height: 1;

        strong {
            color: white;
        }

        em {
            color: white;
        }

        &.sm {
            font-size: 0.7rem;
            text-transform: none;
            font-weight: 500;
            margin-top: 5px;
            line-height: 1.2;

            &.bonus {
                width: 48%;
            }
        }

        &.xs {
            display: block;
            font-size: 0.65rem;
            width: 100%;
            max-width: 40%;
            text-transform: none;
            font-weight: 500;
            flex-wrap: wrap;
            margin-top: 5px;
            color: rgba(white, 0.5);
            line-height: 1.2;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                padding: 0;
                margin: 0;
            }
        }

        &.unusual {
            color: $unusualColor;
        }

        &.rare {
            color: $rareColor;
        }

        &.epic {
            color: $epicColor;
        }

        &.legendary {

            color: $legendaryColor;
        }
    }
}

.connector-line {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    height: inherit;
    align-items: center;
    padding-left: 10px;

    .line {
        position: relative;
        display: flex;
        width: 100%;
        height: 1px;
        //border-bottom: 1px solid rgba(white, 0.1);
        border-bottom: 1px solid $explorerLines;
        //background-color: rgba(white, 0.1);
        &.dashed {
            border-style: dashed;
        }
    }
}

.weapon-lines {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    height: auto;
    align-items: center;

    .line {
        position: relative;
        display: flex;
        width: 100%;
        height: 1px;
        //border-bottom: 1px solid rgba(white, 0.1);
        border-bottom: 1px solid $explorerLines;
        //background-color: rgba(white, 0.1);
        &.dashed {
            border-style: dashed;
        }
    }
}

.explorer-sets-header {
    position: relative;
    display: flex;
    color: $outline-color;
    width: 50%;
    align-items: center;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 0.8;
    flex: 0 0 auto;
    letter-spacing: -0.05rem;
}

.explorer-sets-pattern {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: auto;
    height: inherit;
    justify-content: flex-start;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    overflow: hidden;

    .slant {
        position: relative;
        margin-right: 10px;
        display: flex;
        flex: 0 0 auto;
        width: 10px;
        height: 100%;
        transform: skewX(-20deg);
        background: rgba(white, 0.1);
    }

    &.left {
        justify-content: flex-end;
    }
}

.os-content {
    position: relative;
}

.set-container {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: white;

    &.full {
        height: 100%;
        align-items: center;
    }

    &.scroll-section {
        //position: absolute;
        //left: 0;
        //top: 0;
        display: flex;
        width: 100%;
        flex: 0 0 auto;
        flex-direction: row;
        padding-top: 10px;
        height: 100%;

        .os-host {
            width: 100%;
            height: 100%;
        }
    }

    &.weapons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
    }
}

.gradient-background {
    position: absolute;
    right: 0;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    background: radial-gradient(circle, rgba($rareColor, 0.2) 0%, rgba($rareColor, 0) 55%);
    background-position: center center;
    //background-size: contain;
    background-repeat: no-repeat;
}

.explorer-stats {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    min-height: 100%;

    .spacer {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        height: 10px;
    }
}

.explorer-stats-controls {
    position: relative;
    display: flex;
    width: 30px;
    flex: 0 0 auto;
    align-self: flex-start;
    align-items: flex-start;
    height: 100%;
    margin-top: 5px;
}

.explorer-stats-controls-side {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 0 0 auto;
    justify-content: center;

    .stat-action {
        position: relative;
        display: flex;
        width: 100%;
        flex: 0 0 auto;
        @include aspect-ratio(1, 1);
        border-top: 1px solid $explorerLines;
        border-right: 1px solid $explorerLines;
        border-left: 1px solid transparent;
        border-bottom: 1px solid transparent;
        transition: all 0.3s ease-out;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        //background-image: none;
        //background-size: contain;
        //background-repeat: no-repeat;
        //background-position: center center;
        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            display: flex;

            img {
                opacity: 0.5;
                height: 70%;
                width: auto;
                transition: opacity 0.3s ease-out;
            }
        }

        &:last-child {
            border-bottom: 1px solid rgba(white, 0.1);
        }

        &:hover {
            background: black;
            border: 1px solid rgba(white, 0.2);

            .content {
                img {
                    opacity: 1;
                }
            }
        }
    }
}

.explorer-stats-details {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-self: flex-start;
    padding-left: 10px;
    flex: 1 1 auto;
    overflow-y: hidden;
}

.explorer-details-header {
    display: none;
}

.explorer-figure {
    position: absolute;
    display: flex;
    right: 35px;
    flex: 0 0 auto;
    border-right: 1px solid $explorerLines;
    width: calc(100% - 50%);
    top: 0%;
    height: calc(100% - 5px);
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    max-height: 800px;

    &.expanded {
        height: 100%;
    }
}

.character-visual {
    position: relative;
    display: flex;
    height: 100%;
    flex: 0 0 auto;
    width: 100%;
    max-height: 730px;
    margin-left: 0;

    .content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: none;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        &.sharpen {
            opacity: 0.3;
            filter: url(#Sharpen);
        }
    }
}

.explorer-rarity-container {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    width: 111px;
    height: 100%;
    margin-left: 5px;
    margin-right: 35px;

    select {
        height: 30px;
    }

    .edd-root {
        height: 30px;

        .edd-head {
            height: 30px;

            .edd-value {
                line-height: 24px;
            }
        }
    }

    &.weapons {
        width: 30%;
    }
}

.explorer-search-container {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    height: 30px;
    align-items: center;
    flex-direction: row;
    margin-left: 35px;
}

.explorer-search-input {
    width: 100%;
    height: 30px;
    padding-right: 34px;
}

.weapon-stats {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 720px;
    height: 100%;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
}

.weapon-firepower {
    position: absolute;
    left: 10px;
    top: -25px;
    width: auto;
    border-radius: 2px;
    font-weight: 600;
    padding: 3px;
    background: $outridersYellow;
    color: black;
    font-size: 0.85rem;
}

.weapon-visual {
    position: relative;
    display: flex;
    height: inherit;
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 0 20px 0 20px;
    border-right: 1px solid $explorerLines;
    border-left: 1px solid $explorerLines;

    .content {
        position: absolute;
        //left: 6%;
        left: 0;
        top: 0;
        //width: calc(100% - 12%);
        width: 100%;
        height: 100%;
        background-image: none;
        background-size: 80%;
        background-position: center center;
        background-repeat: no-repeat;

        &.assault-rifle {
            //top: 0;
            //width: calc(100% - 80px);
            //left: 40px;
        }

        &.rifle, &.automatic-sniper-rifle, &.bolt-action-rifle {
            //top: 0;
            //width: calc(100% - 80px);
            //left: 40px;
        }

        &.light-machinegun {
            //top: -5px;
            //height: calc(100% + 10px);
        }

        &.sharpen {
            opacity: 0.8;
            filter: url(#Sharpen);
        }

        &.pistol {
            //top: 5px;
            //height: calc(100% - 10px);
        }

        &.double-gun {
            //top: 0;
            //height: calc(100%);
        }

        &.revolver {
            //top: 5px;
            //height: calc(100% - 10px);
        }

        &.automatic-shotgun {
            //top: -10px;
            //height: calc(100% + 20px);
        }

        &.pump-action-shotgun {
            //top: 0;
            //height: calc(100%);
        }

        &.submachine-gun {
            //top: -5px;
            //height: calc(100% + 10px);
        }
    }
}

.weapon-visual-with-stats {
    position: relative;
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
}

.weapon-info {
    position: relative;
    display: flex;
    height: 100%;
    flex: 1 1 auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    width: 100%;
    flex-direction: column;
}

.weapon-details {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
}

.weapon-basic {
    position: relative;
    display: flex;
    width: 55%;
    flex: 0 0 auto;
    padding-right: 5px;
    padding-left: 10px;
    flex-direction: column;
}

.weapon-attributes {
    position: relative;
    display: flex;
    width: 45%;
    flex: 0 0 auto;
    text-transform: uppercase;
    padding-right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    margin-top: 3px;

    ul {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        width: 100%;
        list-style: none;
        margin-left: 0;
        padding-left: 1em;

        li {
            position: relative;
            display: flex;
            flex: 0 0 auto;
            line-height: 1.4;
            width: 100%;
            padding-top: 0;
            padding-bottom: 0;
            text-align: left;
            //justify-content: center;
            color: white;
            //border-bottom: 1px solid $explorerLines;
            border-bottom: none;

            &:before {
                display: inline-block;
                content: "\e000";
                color: white;
                font-size: 0.9em;
                vertical-align: middle;
                font-family: 'Font Awesome Kit';
                width: 1em;
                margin-right: 0.3em;
                margin-left: -1em;
            }
        }
    }
}

.variant-stats-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 0 0 auto;

    &.horizontal {
        display: none;
    }
}

.weapon-variant-stat {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: auto;
    flex-direction: column;
    padding: 5px;
    background: rgba(black, 0.3);
    border-right: 1px solid $explorerLines;

    &:last-child {
        border-right: none;
    }
}

.weapon-variant-value {
    position: relative;
    display: block;
    font-size: 1rem;
    color: white;
    line-height: 1;
}

.weapon-variant-label {
    position: relative;
    display: block;
    font-size: 0.65rem;
    line-height: 1;
    text-transform: uppercase;
    color: rgba(white, 0.5);
}

.weapon-variant-stats {
    position: relative;
    display: flex;
    width: 100%;
    //padding: 5px;
    text-align: center;
    //background: rgba(black, 0.5);
    justify-content: center;
    border: 1px solid $explorerLines;
    margin-top: 10px;

    &.accuracy {
        margin-top: -1px;

        .weapon-variant-value {
            font-size: 0.8rem;
            text-transform: uppercase;
        }

        .weapon-variant-label {
            font-size: 0.6rem;
        }
    }
}

.weapon-title {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
    color: white;
}

.weapon-type {
    width: 100%;
    text-align: left;
    font-size: 0.7rem;
    text-transform: uppercase;
    line-height: 1;
    color: rgba(white, 0.7);
}

.weapon-variant {
    width: 100%;
    text-align: left;
    font-size: 0.7rem;
    text-transform: uppercase;
    line-height: 1;
    color: rgba(white, 0.7);
}

.weapon-stats-info {
    position: relative;
    display: flex;
    width: 100%;
    font-size: 0.8rem;
    line-height: 1;
    margin-top: 15px;
    padding-left: 10px;
    flex-direction: column;

    .weapon-mods {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        flex-direction: row;
    }
}

.weapon-mod-item {
    position: relative;
    display: flex;
    width: 50%;
    padding-right: 5px;

    &:first-child {
        margin-bottom: 0;
    }
}

.weapon-mod-icon {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    height: auto;
    align-self: flex-start;
    width: 45px;
    @include aspect-ratio(1, 1);

    .content {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;

        .mod-tier {
            position: absolute;
            display: inline-block;
            top: 0;
            width: 100%;
            transform: translateY(-50%);
            text-align: center;
            color: white;
            font-size: 0.75rem;
            font-weight: bold;
            z-index: 3;
            text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black;
        }

        .visual {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex: 0 0 auto;
            width: 100%;
            height: 100%;
            background: black;
            box-shadow: inset 0 0 0 1px $outline-color;
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;

            &.sharpen {
                filter: url('#Sharpen');
                opacity: 0.7;
            }
        }
    }
}

.weapon-mod-information {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    padding-left: 10px;
    width: auto;
    justify-content: center;
    flex-direction: column;
}

.weapon-mod-title {
    position: relative;
    //display: flex;
    width: 100%;
    line-height: 1.1;
    font-size: 0.85rem;
    font-weight: 600;
    color: white;
    margin-bottom: 3px;
    margin-top: -1px;
}

.weapon-mod-description {
    position: relative;
    display: none;
    line-height: 1.1;
    width: 100%;
    font-size: 0.65rem;
    font-weight: 600;
    color: rgba(white, 0.7);

    em, strong {
        color: white;
    }

    .cooldown {
        font-size: 0.9em;
        font-family: "Font Awesome 6 Pro";
    }
}

.weapon-header {
    position: relative;
    display: none;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 15px;
}

.weapon-info-headline {
    position: relative;
    display: flex;
    width: 100%;
}

.weapon-header-section-title {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    font-size: 1.3rem;
    font-weight: bold;
    color: $outline-color;
    text-transform: uppercase;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
    line-height: 0.8;
    letter-spacing: -0.05rem;
}

.podcast-headline {
    position: relative;
    display: flex;
}

.podcast-section-title {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    font-size: 2.3rem;
    font-weight: bold;
    color: rgba(white, 0.1);
    text-transform: uppercase;
    margin-right: 10px;
    margin-left: -3px;
    align-items: center;
    line-height: 0.8;
    letter-spacing: -0.05rem;
}

.podcast-hosts {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 10px;
}

.podcast-episodes-description {
    position: relative;
    display: block;
    width: 100%;
    font-size: 0.85rem;
    padding: 10px 20px 0 0;
    color: rgba(white, 0.5);
    flex-wrap: wrap;
}

.podcast-host {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    padding: 8px;
    flex-direction: column;
    width: 33%;
}

.podcast-host-visual {
    position: relative;
    display: flex;
    width: 100%;
    background: black;
    box-shadow: inset 0 0 0 0 white;
    border-radius: 10px;
    flex: 0 0 auto;
    @include aspect-ratio(1, 1);
    transition: all 0.3s ease-out;
    cursor: pointer;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
        box-shadow: inset 0 0 0 4px white;
    }
}

.podcast-host-text {
    position: relative;
    display: block;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
}

.podcast-host-description {
    position: relative;
    display: none;
    font-size: 0.75rem;
    text-transform: initial;
    font-weight: 500;
    text-align: center;

    i {
        &.twitch {
            color: #BE4BDB;
        }

        &.youtube {
            color: #FF0402;
        }

        &.twitter {
            color: #287CC9;
        }
    }

    &.twitch {
        color: rgba(white, 0.7);

        a {
            text-decoration: none;
            color: inherit;

            &:hover {
                color: white;
            }
        }
    }

    &.youtube {
        color: rgba(white, 0.7);

        a {
            text-decoration: none;
            color: inherit;

            &:hover {
                color: white;
            }
        }
    }

    &.twitter {
        color: rgba(white, 0.7);

        a {
            text-decoration: none;
            color: inherit;

            &:hover {
                color: white;
            }
        }
    }
}

.explorer-scrollable {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 20px;
    font-size: 1rem;
    line-height: 1.25;
    color: white;
    font-weight: 500;

    p {
        font-size: 0.85rem;
        font-weight: normal;
    }

    .explorer-sets-pattern {
        height: 1.8rem;
    }
}

.podcast-episode {
    position: relative;
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    flex-direction: column;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(white, 0.1);
    padding-top: 15px;
    padding-bottom: 15px;

    &:first-child {
        padding-top: 0;
    }
}

.podcast-episode-title {
    position: relative;
    display: block;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $outridersYellow;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
}

.podcast-episode-description {
    position: relative;
    display: block;
    font-size: 0.8rem;
    font-weight: 600;
    color: rgba(white, 0.5);
    line-height: 1.2;
    margin-bottom: 10px;
}

.podcast-audio {
    position: relative;
    display: block;
    width: 100%;

    audio {
        width: 100%;
    }
}

@media (min-height: 600px) {
    .explorer-figure {
        //top: 50%;
        //transform: translateY(-50%);
        top: 0;
        transform: translateY(0);
        //height: 60vh;
    }
    .explorer-label-default {
        .text-label {
            &.sm {
                &.bonus {
                    width: 40%;
                }
            }
        }
    }
    .armory-item {
        &.explorer-armor {
            width: 35px;

            &.upper {
                width: 50px;
            }

            &.footgear {
                width: 40px;
            }
        }
    }
    .weapon-mod-icon {
        width: 55px;
    }
    .weapon-visual {
        height: 100%;
    }
    .weapon-title {
        font-size: 1.6rem;
    }
}

@media (min-height: 730px) {
    .explorer-stats {
        padding-bottom: 0;
        align-items: flex-start;
    }
    .explorer-stats-details {
        align-self: flex-start;
    }
    .explorer-label-title {
        font-size: 1.2rem;
    }
    .armory-item {
        &.explorer-armor {
            width: 40px;

            &.upper {
                width: 55px;
            }

            &.footgear {
                width: 40px;
            }
        }
    }
    //.explorer-navigator {
    //    position: relative;
    //}
    .explorer-label-default {
        .text-label {
            &.sm {
                &.bonus {
                    width: 39%;
                }
            }
        }
    }
    .explorer-figure {
        //height: 54vh;
    }
    .character-visual {
        .content {
            height: 100%;
        }
    }
    .armory-pieces {
        width: 40%;
    }
    .weapon-mod-information {
        justify-content: flex-start;
    }
    .weapon-mod-description {
        display: block;
    }
}

@media (min-height: 845px) {
    .explorer-navigator {
        position: relative;
    }
    //.explorer-figure {
    //    height: 55vh;
    //}
}

//@media screen and (orientation: landscape) {
//    .portrait-notice {
//        display: flex;
//    }
//}
