$systemFont: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
"Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
"Segoe UI Emoji", "Segoe UI Symbol";

$outriders: 'Outriders Custom', $systemFont;
$outline-color: #313941;
$commonColor: #848485;
$unusualColor: #6F7D65;
$rareColor: #677E93;
$epicColor: #6E687E;
$legendaryColor: #7A623C;
$outridersYellow: #dbaa43;
$outridersDark: #0F1216;
$outridersNormal: #272A2E;
$outridersTreeLines: #4d6169;
$lightText: #b3b3b3;
$explorerLines: rgba(white, 0.05);

$grid-breakpoints: (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$input-bg: black;

$form-select-font-family: $outriders;
$form-select-font-size: 1rem;
$form-select-font-weight: 500;
$form-select-line-height: 1.7;
$form-select-color: rgba(white, 0.5);
$form-select-bg: $input-bg;
$form-select-indicator-color: lighten($outline-color, 20%);
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
//$form-select-indicator: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 21.7' style='enable-background:new 0 0 16 21.7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23313941;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D%0A%3C/style%3E%3Cpath class='st0' d='M2,14l6,6l6-6'/%3E%3Cpath class='st0' d='M14,7.8l-6-6l-6,6'/%3E%3C/svg%3E%0A");
$form-select-border-width: 1px;
$form-select-border-color: $outline-color;
$form-select-border-radius: 0;
//$form-select-box-shadow: $box-shadow-inset;

$form-select-focus-border-color: $outline-color;
$form-select-focus-width: 1px;
//$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color;

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
