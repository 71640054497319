@media (min-width: 720px) {
    .segment-header {
        max-width: 685px;
    }
    .segment-content-container {
        max-width: 576px;
    }
    .explorer-sectionals {
        max-width: 685px;
        margin: 0 auto;
    }

    .build-creator-content {
        max-width: 1140px;
    }

    .podcast-section-title {
        font-size: 2.8rem;
    }
    .explorer-scrollable .explorer-sets-pattern {
        height: 2rem;
    }
    .explorer-scrollable {
        p {
            font-size: 1rem;
        }
    }
    .armory-item {
        &.explorer-armor {
            width: 55px;
            margin-bottom: 5px;
            margin-right: 5px;

            &.upper {
                width: 80px;
                margin-top: -5px;
            }

            &.footgear {
                width: 70px;
            }
        }
    }
    .explorer-menu-tab {
        width: 240px;
    }
    .explorer-figure {
        //height: 57vh;
    }
    .explorer-navigator {
        height: 180px;
    }

    .explorer-label-default {
        .text-label {
            &.sm {
                &.bonus {
                    width: 40%;
                }
            }
        }
    }
    .explorer-details-header {
        display: flex;
        margin-bottom: 20px !important;
    }
    .explorer-sets-header {
        font-size: 2.4rem;
    }
    .build-item {
        width: 33.3%;
        height: 200px;
    }
    .set-container-menu {
        .explorer-item-label {
            font-size: 0.75rem;
        }
    }

    .character-module {
        width: 30%;

        .field-label {
            margin-top: initial;
        }
    }

    .character-image-column {
        height: 380px;
    }


    .build-meta {
        width: 70%;
        flex: 1 1 auto;
        margin-bottom: 10px;
    }

    .build-class-chooser {
        width: 100%;
    }

    .class-icon {
        width: 15%;
        margin-right: 20px;
    }
    .class-chooser {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 15px !important;
    }
    .skill-icon {
        width: 12.5%;
        padding: 10px;

        .skill-name {
            font-size: 0.7rem;
        }

        .skill-type {
            font-size: 0.65rem;
        }
    }
    @media (min-height: 1000px) {
        .explorer-stats-details {
            margin-top: -10%;
            align-self: center;
        }
        .explorer-stats-controls {
            align-self: flex-start;
            margin-top: 10%;
        }

        .explorer-figure {
            //height: 55vh;
            //top: 50%;
            //transform: translateY(-55%);
        }
        .weapon-title {
            font-size: 2.2rem;
        }
        .weapon-attributes {
            font-size: 1rem;
        }
        .weapon-variant-stat {
            padding: 10px;
        }
        .weapon-variant-value {
            font-size: 1.2rem;
            margin-bottom: 5px;
            font-weight: 600;
        }
        .variant-stats-container {
            margin-top: 10px;
        }
        .weapon-variant-label {
            font-size: 0.8rem;
        }
        .weapon-variant-stats {
            &.accuracy {
                .weapon-variant-value {
                    font-size: 1rem;
                    font-weight: 600;
                }

                .weapon-variant-label {
                    font-size: 0.8rem;
                }
            }
        }
        .weapon-mod-icon {
            width: 75px;

            .mod-tier {
                font-size: 0.85rem;
            }
        }
        .weapon-type {
            font-size: 1rem;
        }
        .weapon-variant {
            font-size: 1rem;
        }
        .weapon-stats-info {
            margin-top: 30px;
        }
        .weapon-mod-information {
            justify-content: flex-start;
            padding-right: 10px;
        }
        .weapon-mod-title {
            font-size: 1.1rem;
            font-weight: 600;
        }
        .weapon-mod-description {
            display: block;
            font-size: 0.85rem;
            line-height: 1.2;
        }
        .weapon-visual {
            .content {
                //left: 20px;
                //width: calc(100% - 40px);

                &.assault-rifle {
                    //height: calc(100% - 10px);
                    //width: calc(100% - 30%);
                    //left: 15%;
                }

                &.rifle, &.automatic-sniper-rifle, &.bolt-action-rifle {
                    //height: calc(100% - 10px);
                    //width: calc(100% - 30%);
                    //left: 15%;
                }

                &.light-machinegun {
                    //height: calc(100% - 10px);
                    //width: calc(100% - 30%);
                    //left: 15%;
                }

                &.pistol {
                    //top: 10%;
                    //height: calc(100% - 20%);
                    //width: calc(100% - 20%);
                    //left: 10%;
                }

                &.double-gun {
                    //height: calc(100% - 10px);
                    //width: calc(100% - 30%);
                    //left: 15%;
                }

                &.revolver {
                    //top: 10%;
                    //height: calc(100% - 20%);
                    //width: calc(100% - 20%);
                    //left: 10%;
                }

                &.automatic-shotgun {
                    //height: calc(100% - 10px);
                    //width: calc(100% - 30%);
                    //left: 15%;
                }

                &.pump-action-shotgun {
                    //top: 5%;
                    //height: calc(100% - 10%);
                    //width: calc(100% - 40%);
                    //left: 20%;
                }

                &.submachine-gun {
                    //height: calc(100% - 10px);
                    //width: calc(100% - 30%);
                    //left: 15%;
                }
            }
        }
    }
}
