@media (min-width: 768px) {
    .segment-header {
        max-width: 720px;
    }

    .podcast-hosts {
        justify-content: flex-start;
    }
    .podcast-host {
        width: 33.3%;
    }
    .explorer-scrollable {
        padding-top: 30px;
    }
    .segment-content-container {
        max-width: 576px;
    }
    .explorer-sectionals {
        max-width: 720px;
        margin: 0 auto;
    }
    .build-creator-content {
        max-width: 720px;
    }
    .armory-item {
        &.explorer-armor {
            width: 55px;
            margin-bottom: 5px;
            margin-right: 5px;

            &.upper {
                width: 80px;
                margin-top: -5px;
            }

            &.footgear {
                width: 70px;
            }
        }
    }
    .explorer-figure {
        height: 57vh;
    }
    .explorer-label-default {
        .text-label {
            &.sm {
                &.bonus {
                    width: 40%;
                }
            }
        }
    }
    .explorer-details-header {
        display: flex;
        margin-bottom: 20px !important;
    }
    .explorer-sets-header {
        position: relative;
        display: flex;
        color: #313941;
        width: 50%;
        align-items: center;
        text-transform: uppercase;
        font-size: 2.4rem;
        line-height: 0.8;
        font-weight: bold;
        flex: 0 0 auto;
        letter-spacing: -0.05rem;
    }
    .logo-container {
        bottom: 12px;
        width: 50%;
    }
    .header-nav-bar {
        height: 30px;
    }
    .header-visual-bg {
        height: 130px;
    }
    .header-bounds {
        height: 158px;
    }
    .explorer-icons {
        height: 150%;
        width: 100%;
    }

    .explorer-label-title {
        max-width: 45%;
    }
}
