@media (min-width: 480px) {
    .header-bounds {
        height: 177px;
    }

    .header-inner-bounds {
        height: 28px;
    }

    .header-visual {
        height: 100px;
        background-image: url('../../images/header-visual.png');
    }

    .header-visual-bg {
        height: 150px;
    }

    .header-container {
        margin-top: -79px;
    }

    .corner {
        width: 34px;
        height: 100%;
    }

    .header-logo-container {
        width: 100%;
        height: 30px;
        margin-bottom: -1px;

        &.flipped {
            .corner {
                width: 34px;
            }
        }
    }

    .logo-container {
        bottom: 11px;
        width: 50%;
        text-align: initial;

        img {
            width: 100%;
        }

        .slogan {
            font-size: 0.75rem;
        }
    }

    .header-nav-bar {
        height: 20px;
    }

    .header-nav {
        width: 100%;
        left: initial;
        transform: translateX(0);
    }

    .nav-item {
        .headline {
            font-size: 0.8rem;
        }

        .label {
            font-size: 1.4rem;
        }
    }

    //.logo-container {
    //    img {
    //        width: 45%;
    //    }
    //}

    .guides-tab-container {
        width: 200px;
    }

    .guides-btn {
        .headline {
            font-size: 0.8rem;
        }

        .label {
            font-size: 1.4rem;
        }
    }
    .segment-header-container {
        &.explorer {
            padding-top: 5px;
        }
    }

    .explorer-icons {
        height: 210%;
    }
    .action-btn {
        //background: orange;
    }
    .explorer-figure {
        width: calc(100% - 45%);
        //height: 58vh;
    }
    .explorer-label-title {
        font-size: 1.5rem;
    }
    .explorer-label-headline {
        font-size: 0.75rem;
    }
    .explorer-label-default {
        font-size: 1rem;
    }
}
