@media (min-width: 375px) {
    #mobile-detect {
        content: 'mq-mobile';
    }

    #breakpoint-detect {
        content: '375';
    }

    .podcast-host {
        width: 25%;
    }
    .segment-header-visual {
        @include aspect-ratio(16, 3);
    }
    .explorer-scrollable {
        padding-left: 20px;
    }

    .explorer-menu-bounds {
        height: 100px;
    }

    .nav-item {
        .headline {
            font-size: 0.7rem;
        }

        .label {
            font-size: 1.2rem;
        }
    }
    .explorer-stats-details {
        align-self: flex-start;
    }
    .armory-pieces {
        width: 39%;
    }
    .explorer-label-title {
        font-size: 1.4rem;
    }
    .armory-item {
        width: 33.3%;

        &.explorer-armor {
            width: 35px;
            margin-bottom: 5px;

            &.upper {
                width: 45px;
            }

            &.footgear {
                width: 40px;
            }
        }
    }

    .mod-item {
        width: 25%;
    }

    .explorer-label-default {
        .text-label {
            &.sm {
                &.bonus {
                    width: 40%;
                }
            }
        }
    }
    .explorer-stats {
        padding-left: 15px;
        padding-top: 0;

        .spacer {
            height: 7px;
        }
    }
    .explorer-stats-controls {
        width: 35px;
    }
    .explorer-figure {
        //height: 58vh;
        right: 40px;
        top: 0;
        transform: translateX(0);
    }
    .explore-menu-carousel {
        margin-bottom: 10px;
    }

    .explorer-navigator {
        height: 145px;
    }
    @media (min-height: 667px) {
        .weapon-mod-information {
            justify-content: flex-start;
        }
        .weapon-mod-description {
            display: block;
        }
    }
    @media (min-height: 710px) {
        .explorer-figure {
            //height: 53vh;
        }
    }
    @media (min-height: 800px) {
        .armory-item {
            &.explorer-armor {
                width: 45px;

                &.upper {
                    width: 60px;
                }

                &.footgear {
                    width: 45px;
                }
            }
        }
        .explorer-stats-details {
            padding-top: 15px;
        }
        .explorer-navigator {
            position: relative;
            //    display: flex;
            //    width: 100%;
            //    flex-direction: column;
            //    bottom: 0;
            //    left: 0;
            //    margin: 0 auto;
        }
        .explorer-figure {
            //height: 55vh;
        }
        .character-visual {
            .content {
                height: 100%;
            }
        }
        .armory-pieces {
            width: 35%;
        }
        .explorer-label-default {
            .text-label {
                &.sm {
                    &.bonus {
                        width: 38%;
                    }
                }
            }
        }
    }
    .weapon-stats {
        flex-direction: column;
        justify-content: center;
    }

    .weapon-attributes {
        font-size: 0.7rem;

        ul {
            li {
                &:before {
                }
            }
        }
    }
    .weapon-variant-value {
        font-size: 1rem;
    }

    .weapon-variant-label {
        font-size: 0.65rem;
    }

    .weapon-variant-stats {
        &.accuracy {
            .weapon-variant-value {
                font-size: 0.8rem;
            }

            .weapon-variant-label {
                font-size: 0.6rem;
            }
        }
    }

    .weapon-title {
        font-size: 1.4rem;
        line-height: 1;
    }

    .weapon-type {
        font-size: 0.7rem;
    }

    .weapon-variant {
        font-size: 0.7rem;
    }
    .weapon-mod-icon {
        width: 45px;

        .content {
            .mod-tier {
                font-size: 0.75rem;
            }

            .visual {
            }
        }
    }
    .weapon-mod-title {
        font-size: 0.85rem;
    }

    .weapon-mod-description {
        font-size: 0.65rem;
    }
    .weapon-info {
        height: auto;
    }
    .weapon-stats-info {
        font-size: 0.8rem;
    }
    .weapon-visual {
        .content {
            &.assault-rifle {
                //height: calc(100% - 10px);
                //width: calc(100% - 20%);
                //left: 10%;
            }

            &.rifle, &.automatic-sniper-rifle, &.bolt-action-rifle {
                //top: 5%;
                //height: calc(100% - 10px);
                //width: calc(100%);
                //left: 0;
            }

            &.light-machinegun {
                //height: calc(100% - 10px);
                //width: calc(100% - 30%);
                //left: 15%;
            }

            &.pistol {
                //top: 10%;
                //height: calc(100% - 20%);
                //width: calc(100% - 20%);
                //left: 10%;
            }

            &.double-gun {
                //height: calc(100% - 10px);
                //width: calc(100% - 30%);
                //left: 15%;
            }

            &.revolver {
                //top: 10%;
                //height: calc(100% - 20%);
                //width: calc(100% - 20%);
                //left: 10%;
            }

            &.automatic-shotgun {
                //height: calc(100% - 10px);
                //width: calc(100% - 30%);
                //left: 15%;
            }

            &.pump-action-shotgun {
                //top: 5%;
                //height: calc(100% - 10%);
                //width: calc(100% - 20%);
                //left: 10%;
            }

            &.submachine-gun {
                //top: 5%;
                //height: calc(100% - 10%);
                //width: calc(100% - 30%);
                //left: 15%;
            }
        }
    }
}
