@media (min-width: 1200px) {
    .explorer-stats-details {
        align-self: flex-start;
    }
    .explorer-menu-bounds {
        height: 128px;
    }
    .explorer-navigator {
        position: absolute;
    }

    @media (min-height: 900px) {

        .explorer-navigator {
            position: relative;
        }
        .explorer-menu-bounds {
            height: 128px;
        }
        .explorer-figure {
            //height: 65vh;
        }
        .explorer-stats-controls {
            align-self: flex-start;
            align-items: flex-start;
        }
        .explorer-stats-details {
            align-self: flex-start;
        }
        .armory-item {
            &.explorer-armor {
                width: 60px;

                &.upper {
                    width: 75px;
                }

                &.footgear {
                    width: 70px;
                }
            }
        }
    }
    @media (min-height: 1024px) {
        .armory-pieces {
            width: 50%;
        }
        .explorer-navigator {
            position: relative;
        }
        .explorer-stats-details {
            margin-top: 0;
        }
        .explorer-figure {
            height: 60vh;
        }
        .explorer-stats-controls {
            align-self: center;
            align-items: center;
        }
        .explorer-stats-details {
            align-self: center;
            margin-top: -10%;
        }
        .armory-item {
            &.explorer-armor {
                width: 60px;

                &.upper {
                    width: 90px;
                }

                &.footgear {
                    width: 70px;
                }
            }
        }
    }
}
